'use strict';

window.Components = function (Components) {
    class VoiceRecorderExt extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        /**
         * Sends a play command to the given or to the current MediaServer
         * @param settings
         * @param zonesToPlay
         * @param [ip] --> Default: current MediaServer
         * @returns promise
         */
        playRecordedFile(settings, zonesToPlay, ip) {
            var command, hostWithProtocol, returnPromise;

            if (ip) {
                hostWithProtocol = "http://" + ip;
                command = Commands.format(Commands.AudioZone.VOICE_RECORDER.PLAY_FILE, settings.filename, zonesToPlay.toString());
                returnPromise = Q.defer();
                Debug.Communication && CommTracker.track(returnPromise.promise, CommTracker.Transport.AUDIO_HTTP, hostWithProtocol + '/' + command);
                $.ajax({
                    url: hostWithProtocol + '/' + command,
                    context: this,
                    success: function () {
                        returnPromise.resolve();
                    },
                    error: function (err) {
                        console.error(err);
                        returnPromise.reject();
                    },
                    crossDomain: true
                });
            } else {
                returnPromise = MediaServerComp.sendMediaServerCommand(Commands.format(Commands.AudioZone.VOICE_RECORDER.PLAY_FILE, settings.filename, zonesToPlay.toString()));
            }

            return returnPromise;
        }

        /**
         * uploads a file to the given host or the current Media Server
         * @param settings
         * @param [host] --> Default: current MediaServer
         * @returns {*}
         */
        uploadAudioFile(settings, host) {
            var hostWithProtocol,
                url,
                weakThis = this,
                xhr = new XMLHttpRequest(),
                errorText = _('error.occured') + ' ' + _('please-try-again'),
                def = Q.defer();

            if (host) {
                hostWithProtocol = "http://" + host; // Don't use HTTPS, the MusicServer only supports HTTP when uploading files!
            } else {
                hostWithProtocol = "http://" + MediaServerComp.getActiveMediaServer().host; // Don't use HTTPS, the MusicServer only supports HTTP when uploading files!
            }

            url = Commands.format(Commands.AudioZone.VOICE_RECORDER.UPLOAD_FILE, hostWithProtocol, settings.filename);
            window.resolveLocalFileSystemURL(settings.path, function (dir) {
                xhr.open("GET", dir.nativeURL, true);

                xhr.onreadystatechange = function () {
                    if (this.readyState === 4) {
                        if (this.status !== 404) {
                            Debug.Communication && CommTracker.track(def.promise, CommTracker.Transport.AUDIO_HTTP, url, this.response);
                            $.ajax({
                                url: url,
                                type: 'PUT',
                                data: this.response,
                                processData: false,
                                contentType: false,
                                success: function (response) {
                                    def.resolve();
                                },
                                fail: function (e) {
                                    weakThis._showErrorPopup(_('error'), errorText);

                                    def.reject(e);
                                }
                            });
                        } else if (this.responseText !== "") {
                            console.log(this.responseText);
                        }
                    } else if (this.readyState === 2) {
                        if (this.status !== 404) {
                            this.responseType = "blob";
                        } else {
                            this.responseType = "text";
                        }
                    }
                };

                xhr.send();
            }, function (e) {
                weakThis._showErrorPopup(_('error'), errorText);

                def.reject(e);
            });
            return def.promise;
        }

        /**
         * Deletes a file from the local file storage
         * @param settings
         */
        deleteFile(settings) {
            var def = Q.defer();
            window.resolveLocalFileSystemURL(settings.pathWithoutName, function (dir) {
                dir.getFile(settings.filename, {
                    create: false
                }, function (fileEntry) {
                    fileEntry.remove(function (res) {
                        def.resolve();
                        console.log("Removed file");
                    }, function (error) {
                        def.reject(error);
                        console.log("Error: file was not removed " + error);
                    });
                });
            }.bind(this));
            return def.promise;
        }

        _showErrorPopup(title, message) {
            return NavigationComp.showPopup({
                title: title,
                message: message,
                buttonOk: true,
                buttonCancel: false,
                icon: Icon.WARNING,
                color: window.Styles.colors.orange
            }, PopupType.GENERAL);
        }

    }

    Components.MediaServer.extensions.VoiceRecorderExt = VoiceRecorderExt;
    return Components;
}(window.Components || {});
