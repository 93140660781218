'use strict';

window.Components = function (Components) {
    /**
     * Used to download both a listing of all playists on a music server (like the OldPlaylistLoader does), but this one
     * is also used to download the content of a playlist. Plus, using this request the response will be both browsable
     * and editable playlists.
     *
     * ID 0 === Return a list of playlists.
     * ID x === Return the content of the playlist x.
     */
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var ROOT_ID = "0"; // root level container (e.g. list of all plalyists)

        class PlaylistLoader extends Components.MediaServer.extensions.UniversalLoaderBase {
            constructor(component, extensionChannel) {
                super(...arguments);
            }

            /**
             * The command to acquire the data. Only the command, no arguments or anything else, which will be appended
             * by the MediaLoader-Baseclass.
             * @returns {string}
             */
            getAcquireCommand() {
                return MediaEnum.Commands.PLAYLIST.GET;
            }

            prefetchContent(id, nItems) {// do nothing.
            }

            /**
             * Check if its a playlistChanged event and if it affects the current target (service + user)
             * @param event
             * @returns {boolean}
             * @private
             */
            _doesHandleEvent(event) {
                var doHandle = false,
                    evData,
                    source;

                if (event.hasOwnProperty(MediaEnum.EventIdentifier.PLAYLIST_CHANGED)) {
                    evData = event[MediaEnum.EventIdentifier.PLAYLIST_CHANGED][0];
                    source = evData[MediaEnum.Attr.SERVICE.CMD] + "/" + evData[MediaEnum.Attr.SERVICE.USER];
                    doHandle = source === this._getTargetUID();
                }

                return doHandle;
            }

            /**
             * It affects the current service and user, so detect what was changed and how - then respond.
             * @param event
             * @private
             */
            _processEvent(event) {
                var evData, playlistID, action;

                if (event.hasOwnProperty(MediaEnum.EventIdentifier.PLAYLIST_CHANGED)) {
                    evData = event[MediaEnum.EventIdentifier.PLAYLIST_CHANGED][0];
                    playlistID = evData[MediaEnum.Attr.Playlist.ID];
                    action = evData[MediaEnum.Attr.Container.ACTION];

                    switch (action) {
                        case MediaEnum.Attr.Container.ActionType.START:
                            if (this._isBeingEdited(playlistID)) {
                                console.error("EDIT MODE ACTIVE CONFLICT - SOMEONE ELSE STARTS EDITING");
                            }

                            break;

                        case MediaEnum.Attr.Container.ActionType.UPDATE:
                            // invalidate cache for single playlist
                            if (!this._isBeingEdited(playlistID)) {
                                this.invalidateContentCachesOf(playlistID, MediaEnum.ReloadCause.CHANGED, this.mediaTypeDetails);
                            } // don't update if it is being edited, the edit extension takes care of it.


                            break;

                        case MediaEnum.Attr.Container.ActionType.RENAME:
                            this._containerRenamed(playlistID, evData[MediaEnum.Event.NAME]);

                            break;

                        case MediaEnum.Attr.Container.ActionType.CREATE:
                            this._containerCreated(playlistID, evData[MediaEnum.Event.NAME]);

                            break;

                        case MediaEnum.Attr.Container.ActionType.DELETE:
                            //reload list of playlists!
                            this._containerDeleted(playlistID);

                            break;

                        default:
                            console.info("PlaylistLoader received unsupported action in playlistchanged_event: " + action);
                            break;
                    }
                }
            }

            /**
             * Gives info on wether or not the container ID provided is being edited on this UI or not.
             * @param cntrId
             * @returns {boolean}
             * @private
             */
            _isBeingEdited(cntrId) {
                var editing = MediaServerComp.getActiveEditingTarget(),
                    target,
                    active = false;

                if (editing && editing.mediaTypeDetails) {
                    target = editing.mediaTypeDetails[MediaEnum.Attr.SERVICE._][MediaEnum.Attr.SERVICE.UID];
                    active = target === this.target[MediaEnum.Attr.SERVICE.UID];
                }

                return active;
            }

            /**
             * Will add the newly created playlist to the dataset from this extension. If not fully loaded it will
             * invalidate the cache and cause a complete reload
             * @param id    the id of the newly created playlist
             * @param name  the name of the new playlist
             * @private
             */
            _containerCreated(id, name) {
                var cacheResult = this._getFromCache(ROOT_ID),
                    newPlaylist = {};

                if (cacheResult.totalitems === cacheResult.items.length) {
                    newPlaylist[MediaEnum.Event.FILE_TYPE] = MediaEnum.FileType.PLAYLIST_EDITABLE;
                    newPlaylist[MediaEnum.Event.NAME] = name;
                    newPlaylist[MediaEnum.Event.ID] = id; // fully loaded, insert and we're done

                    cacheResult.totalitems++;
                    cacheResult.items.push(newPlaylist); // we need to sort the data alphabetically

                    cacheResult.items.sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    }); // update the content - use a reloadcause to make sure the UI updates.

                    this.updateContent(ROOT_ID, this.mediaTypeDetails, cacheResult, MediaEnum.ReloadCause.CHANGED);
                } else {
                    //TODO-woessto: no recursive invalidation!
                    this.invalidateContentCachesOf(ROOT_ID, MediaEnum.ReloadCause.RENAMED, this.mediaTypeDetails);
                }
            }

            /**
             * Will update the local dataset so the container is shown with it's new name.
             * @param id    the id of the newly created playlist
             * @param name  the new name of the new playlist
             * @private
             */
            _containerRenamed(id, name) {
                var cacheResult = this._getFromCache(ROOT_ID),
                    renamed;

                renamed = cacheResult.items.some(function (item) {
                    var found = false;

                    if (item[MediaEnum.Event.ID] === id) {
                        item[MediaEnum.Event.NAME] = name;
                        found = true;
                    }

                    return found;
                }.bind(this));

                if (renamed) {
                    this.updateContent(ROOT_ID, this.mediaTypeDetails, cacheResult, MediaEnum.ReloadCause.RENAMED);
                    this.updateContent(id, this.mediaTypeDetails, cacheResult, MediaEnum.ReloadCause.RENAMED);
                } else {
                    //TODO-woessto: no recursive invalidation!
                    this.invalidateContentCachesOf(ROOT_ID, MediaEnum.ReloadCause.RENAMED, this.mediaTypeDetails);
                    this.invalidateContentCachesOf(id, MediaEnum.ReloadCause.RENAMED, this.mediaTypeDetails);
                }
            }

            /**
             * Will remove the deleted playlist from the dataset of this extension. If not fully loaded it will cause
             * a complete reload in order to keep the data clean.
             * @param id    the id of the removed playlist
             * @private
             */
            _containerDeleted(id) {
                var cacheResult = this._getFromCache(ROOT_ID),
                    i;

                if (cacheResult.totalitems === cacheResult.items.length) {
                    for (i = 0; i < cacheResult.items.length; i++) {
                        if (cacheResult.items[i][MediaEnum.Event.ID] === id) {
                            cacheResult.items.splice(i, 1);
                            cacheResult.totalitems--;
                            break;
                        }
                    } // update the content - use a reloadcause to make sure the UI updates.


                    this.updateContent(ROOT_ID, this.mediaTypeDetails, cacheResult, MediaEnum.ReloadCause.CHANGED);
                } else {
                    //TODO-woessto: no recursive invalidation!
                    this.invalidateContentCachesOf(ROOT_ID, MediaEnum.ReloadCause.CHANGED, this.mediaTypeDetails);
                }
            }

        }

        Components.MediaServer.extensions.PlaylistLoader = PlaylistLoader;
    }
    return Components;
}(window.Components || {});
