'use strict';

window.Components = function (Components) {
    class SearchExtBase extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        /**
         * Starts the search
         * returns {Promise}
         */
        searchFor() {
            //fast-class-es6-converter: auto generated
            throw "Implement the 'searchFor' function in your class";
        }

        createAudioResultCell(item, type, delegate) {
            item.contentType = this._getContentTypeForType(type);

            var isBrowsable = MediaServerComp.isFileTypeBrowsable(item[MediaEnum.Event.FILE_TYPE]),
                identifier = this._getIdentifierFromType(type),
                username = this._getUsernameForType(type),
                buttonTapped,
                action,
                showStateArgs;

            if (delegate.ViewController instanceof GUI.AudioZoneControlContentViewController || delegate.ViewController instanceof GUI.AudioZoneControlDetailedViewController) {
                if (delegate._showContextMenuForItem) {
                    buttonTapped = delegate._showContextMenuForItem.bind(delegate, item);
                }
            } else if (delegate.ViewController instanceof GUI.AddMediaViewControllerBase) {
                buttonTapped = delegate.ViewController.addItem.bind(delegate.ViewController, item, {
                    service: {
                        cmd: identifier + "/" + username
                    }
                });
            }

            if (isBrowsable) {
                action = function () {
                    var srcCmd; // We need to fetch the album in a different way!

                    if ((item.tag === "album" || item.tag === "playlist" || item.tag === "artist") && item.contentType === MediaEnum.MediaContentType.LIBRARY) {
                        srcCmd = "audio/cfg/searchdetails/library/nouser/" + item.id + "/0/50";
                    }

                    showStateArgs = [Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(item), null, {
                        username: username,
                        lastSelectedItem: item,
                        identifier: identifier,
                        contentTypes: [delegate.contentType],
                        mediaType: delegate.mediaType,
                        isFromSearch: true,
                        srcCmd: srcCmd
                    }];
                    delegate.ViewController.showState.apply(delegate.ViewController, showStateArgs);
                }.bind(this);
            } else {
                action = MediaServerComp.playFileUrl.bind(this, item, delegate.getMediaInfo(item.contentType));
            }

            return this._getMediaBrowserFromType(type).getCellFromContentTypeItem(item, {
                identifier: this._getIdentifierFromType(type),
                username: username
            }, item.contentType, false, isBrowsable, action, buttonTapped, delegate.ViewController);
        }

        _createSpecificTableContent() {
            //fast-class-es6-converter: auto generated
            throw "Implement the '_createSpecificTableContent' function in your class";
        }

        _getIdentifierFromType() {
            //fast-class-es6-converter: auto generated
            throw "Implement the '_getIdentifierFromType' function in your class";
        }

        _getUsernameForType() {
            //fast-class-es6-converter: auto generated
            throw "Implement the '_getUsernameForType' function in your class";
        }

        _getMediaBrowserFromType() {
            //fast-class-es6-converter: auto generated
            throw "Implement the '_getMediaBrowserFromType' function in your class";
        }

        _getContentTypeForType() {
            //fast-class-es6-converter: auto generated
            throw "Implement the '_getContentTypeForType' function in your class";
        }

    }

    Components.MediaServer.extensions.SearchExtBase = SearchExtBase;
    return Components;
}(window.Components || {});
