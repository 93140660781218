'use strict';

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        /**
         * Attributes in an reloadmusicapp_event
         * @type {{CAUSE: string, ACTION: string, ACCOUNT: string}}
         */
        var ReloadApp = {
            CAUSE: 'cause',
            ACTION: 'action',
            USER: 'user'
        };

        class CentralCommunicationNode extends Components.Extension {
            constructor(component, extensionChannel) {
                super(...arguments);
                this.rebootPromise = null;
                this.activeCommunicationChannel = null;
                this.registerExtensionEv(this.component.ECEvent.Start, this._processStart.bind(this));
                this.registerExtensionEv(this.component.ECEvent.Stop, this._processStop.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ServerInfoReceived, this._processServerInfoReceived.bind(this));
                this.registerExtensionEv(this.component.ECEvent.EventReceived, this._processEventReceived.bind(this));
                this.registerExtensionEv(this.component.ECEvent.SendMessage, this._processSendMessage.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ConnEstablished, this._processedConnectionEstablished.bind(this));
                this.registerExtensionEv(this.component.ECEvent.VerifyConnectivity, this._processVerifyConn.bind(this)); // TODO-woessto: remove workaround https://www.wrike.com/open.htm?id=91947923

                window.onbeforeunload = this._disconnect.bind(this);
                this.informedAboutApiMissmatch = null;
                this.apiMissmatch = false;
                this.reachMode = ReachMode.NONE; // for queueing cmds that cannot be sent (connection not yet availble)

                this.cmdQueue = {};
                this.queueId = 0;
            }

            destroy() {
                this._processStop(); // unregister from state changes & tear down comm.
                // clear potential timeouts due to commands in the queue.


                var qIds = Object.keys(this.cmdQueue);

                for (var i = 0; i < qIds.length; i++) {
                    clearTimeout(this.cmdQueue[qIds[i]].timeout);
                }

                this.cmdQueue = null;
                super.destroy(...arguments);
            }

            isRestricted() {
                return this.reachMode !== ReachMode.LOCAL;
            }

            _disconnect() {
                Debug.Media.CentralCommunicationNode && console.log(this.name + ": _disconnect");

                if (this.activeCommunicationChannel) {
                    this.activeCommunicationChannel.disconnect();
                    this.activeCommunicationChannel.destroy();
                    this.activeCommunicationChannel = null;
                }

                this.reachMode = ReachMode.NONE;
            }

            _connect() {
                Debug.Media.CentralCommunicationNode && console.log(this.name + ": _connect");
                var mediaServ = this.component.getActiveMediaServer();
                var reachMode = CommunicationComponent.getCurrentReachMode(); // decide whether to connect to the mediaServer directly or via the miniserver

                if (!mediaServ) {
                    this._disconnect();

                    return;
                }

                if (reachMode === ReachMode.LOCAL && mediaServ.hasOwnProperty('host')) {
                    // connect directly
                    Debug.Media.CentralCommunicationNode && console.info(this.name, "Contacting the MediaServer directly!");
                    this.activeCommunicationChannel = this._initExtension(Components.MediaServer.extensions.MediaSocket);
                    this.reachMode = ReachMode.LOCAL;
                } else {
                    Debug.Media.CentralCommunicationNode && console.info(this.name, "Contacting the MediaServer via Miniserver as proxy!");
                    this.activeCommunicationChannel = this._initExtension(Components.MediaServer.extensions.MiniserverMediaProxy);
                    this.reachMode = ReachMode.REMOTE;
                }

                this.emit(this.component.ECEvent.ReachModeChanged, this.reachMode);

                if (this.activeCommunicationChannel) {
                    this.activeCommunicationChannel.connect(mediaServ);
                }
            }

            _initExtension(Ext) {
                return new Ext(this.component, this.component);
            }

            // TODO-woessto: remove workaround https://www.wrike.com/open.htm?id=91947923
            _processVerifyConn() {
                Debug.Media.CentralCommunicationNode && console.info(this.name, "_processVerifyConn");

                if (this.reachMode !== ReachMode.NONE && this.reachMode !== CommunicationComponent.getCurrentReachMode()) {
                    this._processStop();
                }

                Debug.Media.CentralCommunicationNode && console.info("          _processVerifyConn done");
            }

            _processServerInfoReceived(event, args) {
                Debug.Media.CentralCommunicationNode && console.info(this.name, "MediaServer-Info was received: " + JSON.stringify(args)); // store the current server info in the structure-file.

                var mediaServ = this.component.getActiveMediaServer();
                mediaServ.serverInfo = args;
                var supportedServerApiVersion = MediaServerComp.supportedServerApiVersion;
                this.apiMissmatch = args.apiVersion[0] > supportedServerApiVersion[0];

                if (this.apiMissmatch) {
                    if (this.informedAboutApiMissmatch && this.informedAboutApiMissmatch === mediaServ.host) {
                        console.warn("App is outdated, but the user was already informed about it.");
                    } else {
                        var supportedString = supportedServerApiVersion[0] + ".x";
                        var currentString = args.apiVersion[0] + "." + args.apiVersion[1];
                        this.emit(this.component.ECEvent.ShowPopup, {
                            title: _("media.app-outdated.title"),
                            message: _("media.app-outdated.info", {
                                server: MediaServerComp.getServerName()
                            }) + "<br><br>" + _("media.app-outdated.cause", {
                                server: MediaServerComp.getServerName(),
                                supported: supportedString,
                                current: currentString
                            }),
                            buttonOk: true,
                            icon: Icon.CAUTION,
                            color: window.Styles.colors.red
                        });
                        this.informedAboutApiMissmatch = mediaServ.host;
                    }

                    this._disconnect.call(this);
                } else {
                    this.informedAboutApiMissmatch = null;
                }
            }

            _receivedMiniserverStates(states) {
                Debug.Media.CentralCommunicationNode && console.info(this.name, "_receivedMiniserverStates");

                if (states.hasOwnProperty("serverState") && this.serverState !== states.serverState) {
                    this.serverState = states.serverState;
                    Debug.Media.CentralCommunicationNode && console.info("  ", "server state received! " + MediaServerComp.getServerStateStr(this.serverState));

                    if (this.serverState <= MediaEnum.ServerState.INITIALIZING) {
                        this._disconnect();
                    } else if (!this.activeCommunicationChannel) {
                        this._connect();
                    }

                    this.channel.emit(MediaServerComp.ECEvent.ServerStateReceived, this.serverState);
                }
            }

            _processEventReceived(event, args) {
                var key = Object.keys(args)[0];

                if (key === MediaEnum.EventIdentifier.GOING_TO_STANDBY) {
                    // server will go to standby
                    this._disconnect();
                } else if (key === MediaEnum.EventIdentifier.RELOAD_APP) {
                    Debug.Media.CentralCommunicationNode && console.log(this.name + ": reloadmusicapp_event received");

                    if (this._newAppReloadHandling(args[key][0])) {// new handling of reload event in place, don't respond.
                    } else if (this.component.getAudioViewsVisible()) {
                        if (this.rebootPromise === null) {
                            var popup = {
                                title: _("media.app-reload-required.title"),
                                message: _("media.app-reload-required.message", {
                                    servername: MediaServerComp.getServerName()
                                }),
                                buttonOk: _("media.app-reload-required.button-reload"),
                                icon: Icon.INFO
                            };
                            this.rebootPromise = NavigationComp.showPopup(popup);
                            Debug.Media.CentralCommunicationNode && console.log(this.name, "NavigationComp.showPopup responded: " + this.rebootPromise);
                            this.rebootPromise.done(function () {
                                this.rebootPromise = null;
                                NavigationComp.reloadWithCurrentUrl(); //document.location.reload(true);
                            }.bind(this));
                        } else {
                            console.warn("A RELOAD_APP event was received while a popup asking for a reboot is already shown!");
                        }
                    } else {
                        // simply reconnect & emit an event
                        this.channel.emit(this.component.ECEvent.ResetMediaApp);

                        this._disconnect();

                        this._connect();
                    }
                }
            }

            /**
             * Handles a reloadapp-event from the Music Server. Potentially takes additional action when it is
             * received.
             * @param reloadObj     the object containing e.g. the cause of the reloadapp-event.
             * @returns {boolean}   whether or not the event has been dealt with inside this method.
             * @private
             */
            _newAppReloadHandling(reloadObj) {
                var ignore = false;

                if (reloadObj.hasOwnProperty(ReloadApp.CAUSE)) {
                    // inspect further - only spotify events are to be ignored as of now.
                    switch (reloadObj[ReloadApp.CAUSE]) {
                        case MediaEnum.Service.SPOTIFY:
                        case MediaEnum.Service.GOOGLE:
                            ignore = true; // the cause identifies the service. create a UID object and store it.

                            var uid = reloadObj[ReloadApp.CAUSE] + "/" + reloadObj[ReloadApp.USER];
                            reloadObj[MediaEnum.Attr.SERVICE.UID] = uid;
                            reloadObj[MediaEnum.Attr.SERVICE.CMD] = reloadObj[ReloadApp.CAUSE];
                            this.channel.emit(MediaServerComp.ECEvent.ServiceChanged, reloadObj);
                            break;

                        default:
                            break;
                    }
                }

                return ignore;
            }

            _processStart() {
                Debug.Media.CentralCommunicationNode && console.log(this.name + ": START received");
                var requiredConfigVersion = MediaServerComp.requiredConfigVersion;

                if (!ActiveMSComponent.hasRequiredConfigVersion(requiredConfigVersion)) {
                    this.channel.emit(this.component.ECEvent.ShowPopup, {
                        title: MediaServerComp.getServerName() + " not connected",
                        message: "Since your config is outdated (Current: " + ActiveMSComponent.getConfigVersion() + " - Required: " + requiredConfigVersion + " or newer), the App cannot work with the MediaServer.",
                        buttonOk: true,
                        icon: Icon.INFO
                    });
                    Debug.Media.CentralCommunicationNode && console.warn("Current Miniserver has an old config-version. " + "Current: " + ActiveMSComponent.getConfigVersion() + " - Required: " + requiredConfigVersion);
                } else {
                    this.svrUuid = this.component.getActiveMediaServer().uuidAction;
                    Debug.Media.CentralCommunicationNode && console.log("        register for state changes of " + this.component.getActiveMediaServer().name);
                    SandboxComponent.registerForStateChangesForUUID(this.svrUuid, this, this._receivedMiniserverStates);
                }
            }

            _processStop() {
                Debug.Media.CentralCommunicationNode && console.log(this.name + ": STOP received");
                delete this.serverState;
                SandboxComponent.unregisterForStateChangesForUUID(this.svrUuid, this);

                this._disconnect();
            }

            _processSendMessage(event, cmdObj) {
                if (MediaServerComp.taskRecorderActive && this._isRecordableCommand(cmdObj.cmd)) {
                    this._handleTaskRecorderCommand(cmdObj);
                } else if (this.activeCommunicationChannel) {
                    this.activeCommunicationChannel.sendCommand(cmdObj);
                } else {
                    // no connection is active, enqueue this command for a short period of time and send it
                    this._enqueueCmd(cmdObj);
                }
            }

            /**
             * checks the cmdQueue for commands that may not have been sent yet. Sends them if needed.
             * @private
             */
            _processedConnectionEstablished() {
                Debug.Media.CentralCommunicationNode && console.log(this.name + ": _processedConnectionEstablished");
                var queueKeys = Object.keys(this.cmdQueue).sort(),
                    cmdObj;
                Debug.Media.CentralCommunicationNode && console.log(this.name + ":       " + queueKeys.length + " items in queue");

                for (var i = 0; i < queueKeys.length; i++) {
                    cmdObj = this._removeFromQueue(queueKeys[i]);
                    Debug.Media.CentralCommunicationNode && console.log(this.name + ":       sending enqueuend cmd: " + cmdObj.cmd);
                    this.activeCommunicationChannel.sendCommand(cmdObj);
                }
            }

            /**
             * Adds a cmdObj temporarily to a queue. When the connection isn't opened after a period of time, it's removed
             * from the queue automatically
             * @param cmdObj    the command object to enqueue for sending later when the connection is up and running again.
             * @private
             */
            _enqueueCmd(cmdObj) {
                Debug.Media.CentralCommunicationNode && console.log(this.name + ": _enqueueCmd: " + cmdObj.cmd);
                var cmdId = this.queueId++,
                    queueTimeout = 60000;
                this.cmdQueue[cmdId] = {
                    cmdObj: cmdObj,
                    queueId: cmdId,
                    timeout: setTimeout(function () {
                        // after queueTimout milliseconds, the command will not be of any interest anymore.
                        var obj = this._removeFromQueue(cmdId);

                        console.info("Audio-Command not sent: " + obj.cmd + " - (no conn for more than " + Math.round(queueTimeout / 1000) + " seconds)");
                    }.bind(this), queueTimeout)
                };
            }

            /**
             * Removes a cmdObj from the cmdQueue & invalidates it's own remove from queue timeout
             * @param cmdId the command to remove from the queue
             * @returns {*} the cmdObj
             * @private
             */
            _removeFromQueue(cmdId) {
                var queueObj = this.cmdQueue[cmdId];
                delete this.cmdQueue[cmdId];
                clearTimeout(queueObj.timeout);
                return queueObj.cmdObj;
            }

            _isRecordableCommand(cmd) {
                var parts = cmd.split("/"); // 1. first part has to be audio

                if (parts[0] !== 'audio') {
                    return false;
                } // 2. second part has to be a playerid between 0 and 100


                var playerid = parseInt(parts[1]);

                if (!(playerid > 0 && playerid < 100)) {
                    return false;
                } // 3. it has to be a control command


                parts.splice(0, 2);
                var remCmd = parts.join("/");
                var isRecordable = false;

                if (!this.recordableCmds) {
                    this.recordableCmds = this._prepareRecordableCmdList();
                }

                for (var i = 0; i < this.recordableCmds.length; i++) {
                    if (remCmd.indexOf(this.recordableCmds[i]) === 0) {
                        isRecordable = true;
                    }
                }

                return isRecordable;
            }

            _prepareRecordableCmdList() {
                var recordableCmds = [];

                var tc = function (c) {
                    // removes a trailing /
                    return c.split("/").join("/");
                };

                var ac = function (cmd) {
                    recordableCmds.push(tc(cmd));
                }; // player commands


                ac(MediaEnum.AudioCommands.SEEK.TO);
                ac(MediaEnum.AudioCommands.PLAY);
                ac(MediaEnum.AudioCommands.PAUSE);
                ac(MediaEnum.AudioCommands.VOLUME.SET_TO);
                ac(MediaEnum.AudioCommands.VOLUME.UP);
                ac(MediaEnum.AudioCommands.VOLUME.UP_BY);
                ac(MediaEnum.AudioCommands.VOLUME.DOWN);
                ac(MediaEnum.AudioCommands.VOLUME.DOWN_BY);
                ac(MediaEnum.AudioCommands.QUEUE.NEXT);
                ac(MediaEnum.AudioCommands.QUEUE.PREV);
                ac(MediaEnum.AudioCommands.SYNC);
                ac(MediaEnum.AudioCommands.UNSYNC); // add/clear/playing sources

                ac(MediaEnum.AudioCommands.QUEUE.JUMP_TO);
                ac(MediaEnum.AudioCommands.QUEUE.CLEAR);
                ac(MediaEnum.AudioCommands.QUEUE.ADD);
                ac(MediaEnum.AudioCommands.QUEUE.INSERT);
                ac(MediaEnum.AudioCommands.QUEUE.INSERT_AND_PLAY);
                ac(MediaEnum.AudioCommands.LIBRARY.PLAY);
                ac(MediaEnum.AudioCommands.PLAYLIST.PLAY);
                ac(MediaEnum.AudioCommands.FAVORITE.PLAY);
                ac(MediaEnum.AudioCommands.ZONE_FAV.PLAY);
                ac(MediaEnum.AudioCommands.SERVICE.PLAY);
                ac(MediaEnum.AudioCommands.SERVICE.ADD);
                ac(MediaEnum.AudioCommands.SERVICE.INSERT);
                ac(MediaEnum.AudioCommands.SERVICE.INSERT_AND_PLAY);
                ac(MediaEnum.AudioCommands.LINEIN.PLAY);
                ac(MediaEnum.AudioCommands.SEARCH.PLAY);
                ac(MediaEnum.AudioCommands.SEARCH.ADD);
                ac(MediaEnum.AudioCommands.SEARCH.INSERT);
                ac(MediaEnum.AudioCommands.SEARCH.INSERT_AND_PLAY);
                return recordableCmds;
            }

            _handleTaskRecorderCommand(cmdObj) {
                Debug.Media.CentralCommunicationNode && console.log(this.name + ": Adding to Task-Recorder '" + JSON.stringify(cmdObj.command) + "'");
                var command = cmdObj.cmd;
                var automatic = !!cmdObj.auto;
                var uuidAction = this.component.getActiveMediaServer().uuidAction;
                var isSecured = false; // A media-zone cannot be secured at the moment!

                var type = Commands.Type.QUEUE;
                var encodedCommand = encodeURIComponent(command); // the miniserver will decode it before forwarding it.

                var promise = SandboxComponent.sendCommand(this, uuidAction, encodedCommand, type, isSecured, !automatic, false, cmdObj.argumentTexts);
                promise.then(function success() {
                    Debug.Media.Comm && console.log(this.name + ": task stored '" + command + "'");
                }.bind(this), function error(e) {
                    Debug.Media.Comm && console.error(this.name + ": task storing failed! " + command + " - " + JSON.stringify(e));
                }.bind(this), function notify(i) {
                    Debug.Media.Comm && console.info(this.name + ": task storing notify " + command + " - " + JSON.stringify(i));
                }.bind(this));
            }

        }

        Components.MediaServer.extensions.CentralCommunicationNode = CentralCommunicationNode;
    }
    return Components;
}(window.Components || {});
