'use strict';

window.Components = function (Components) {
    class SpotifyPlaylistEditExtension extends Components.MediaServer.extensions.PlaylistEditExtension {
        constructor() {
            super(...arguments);
        }

        /**
         * Returns the remove argument for this item. Needs to be subclassable since e.g. spotify requires the
         * items ID instead of the index.
         * @param idx       what position was the item to be removed
         * @param item      the item object that is to be removed
         * @returns {string}    the argument needed for the update command that removes an item.
         */
        getRemoveItemArgument(idx, item) {
            return item[MediaEnum.Event.ID];
        }

        // -------------------------------------------------------
        //            Methods used by subclasses
        // -------------------------------------------------------

        /**
         * Returns the MediaContentType of these containers. e.g. MediaEnum.MediaContentType.SERVICE
         * @returns {string}
         */
        getContentType() {
            return MediaEnum.MediaContentType.SERVICE;
        }

        getRootContainerId() {
            return MediaEnum.Spotify.TYPES.MY_PLAYLISTS;
        }

    }

    Components.MediaServer.extensions.SpotifyPlaylistEditExtension = SpotifyPlaylistEditExtension;
    return Components;
}(window.Components || {});
