'use strict';

window.Components = function (Components) {
    class FavoriteLoader extends Components.MediaServer.extensions.MediaLoaderBase {
        //region Private
        ID = 0; //endregion Private

        // pass on 0 as ID, there is only one favorite list.
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        getAcquireCommand() {
            return 'getfavorites';
        }

        getAcquireCommandArguments(request) {
            /* ignore the request id. It's faked and not required by the mediaServer, since there is only one
                favorites list. You cannot dig deeper into it - you'd have to use other commands.
             */
            return request.start + "/" + request.nItems;
        }

        _processResult(data, cmd) {
            console.log("Processing Result: " + JSON.stringify(data)); // we don't have an ID in the result. for internal handling we need one.

            data.id = this.ID;

            super._processResult(...arguments);
        }

        _doesHandleEvent(event) {
            Debug.Media.Loader && console.log(this.name + ": _doesHandleEvent: " + JSON.stringify(event));
            var doHandle = event.hasOwnProperty(MediaEnum.EventIdentifier.FAVORITES_CHANGED);
            Debug.Media.Editor && console.log("        doHandle: " + doHandle);
            return doHandle;
        }

        _processEvent(event) {
            Debug.Media.Loader && console.log(this.name + ": _processEvent: " + JSON.stringify(event));
            this.invalidateContentCachesOf(this.ID);
        }

    }

    Components.MediaServer.extensions.FavoriteLoader = FavoriteLoader;
    return Components;
}(window.Components || {});
