'use strict';

window.Components = function (Components) {
    var SCAN_EVENT = "ScanEvent";

    class LibraryLoader extends Components.MediaServer.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.isScanning = false;
            this.registerExtensionEv(component.ECEvent.LibraryErrorReceived, this._handleLibraryError.bind(this)); // initially request the current scan state - later on we receive updates for this.

            this.registerExtensionEv(this.component.ECEvent.FeatureCheckingReady, function () {
                MediaServerComp.Feature.LIB_SCAN_HANDLING && this._requestScanState();
            }.bind(this));
        }

        // -------------------------------------------------------
        //            Handling Events
        // -------------------------------------------------------

        /**
         * Superclass MediaLoaderExtension is already registered for events. Override but still call superclasses
         * handleEvent at first.
         */
        _handleEventReceived(evId, event) {
            super._handleEventReceived(...arguments);

            var key = Object.keys(event)[0];

            if (key === MediaEnum.EventIdentifier.LIBRARY.RESCAN) {
                if (MediaServerComp.Feature.BG_LIB_SCANING) {
                    // only respond to scan ends, not scan starts - since scanning is bein processed in the background.
                    if (event[key][0].status === 0) {
                        this.rejectAndDeleteAllRequests(MediaEnum.ReloadCause.CONTENT_UPDATED);
                        this.invalidateContentCachesOf(0, MediaEnum.ReloadCause.CONTENT_UPDATED);
                    }
                }

                if (event[key][0].status === 0) {
                    // we're not rescanning.
                    Debug.Media.LibraryLoader && console.log(this.name, "Received a 'rescanning finished'/'not rescanning' event.");
                    MediaServerComp.Feature.LIB_SCAN_HANDLING && this._scanEnded();
                } else {
                    Debug.Media.LibraryLoader && console.log(this.name, "Received a 'scanning active' event.");
                    MediaServerComp.Feature.LIB_SCAN_HANDLING && this._scanStarted(); // only reject & invalidate when a start was received from a music server that isn't capable of
                    // background scanning.

                    if (!MediaServerComp.Feature.BG_LIB_SCANING) {
                        this.rejectAndDeleteAllRequests(MediaEnum.PopupMessageId.RESCANNING);
                        this.invalidateContentCachesOf(0, MediaEnum.PopupMessageId.RESCANNING);
                    }
                }
            }
        }

        _handleLibraryError(evId, event) {
            // something is wrong with the library. invalidate all caches.
            console.error("Library error received, invalidating the cache! " + JSON.stringify(event));
            this.invalidateContentCachesOf(0, MediaEnum.PopupMessageId.BADFILE);
        }

        // -------------------------------------------------------
        //            Public Methods Events
        // -------------------------------------------------------
        getAcquireCommand() {
            return 'getmediafolder';
        }

        /**
         * Registers a new listener for scanning events.
         * @param listener      the function to call when a scan event is received
         * @returns {number}    a listenerId using which the listener can unregister again.
         */
        registerScanListener(listener) {
            Debug.Media.LibraryLoader && console.log(this.name, "registerScanListener");
            var listenerId = this.on(SCAN_EVENT, listener);
            setTimeout(function () {
                // dispatch first state async right away
                listener(null, this.isScanning);
            }.bind(this), 200);
            return listenerId;
        }

        /**
         * Removes a scan listener from receiving scan state updates.
         * @param listenerId    the ID to identify the listener, returned by the registerScanListener method.
         */
        removeScanListener(listenerId) {
            Debug.Media.LibraryLoader && console.log(this.name, "removeScanListener: " + JSON.stringify(listenerId));
            this.off(listenerId);
        }

        /**
         * This method is used to deal with errors that occurred during the current media request. After this method
         * the current request has already been rejected. after this method the next request from the queue will be
         * processed.
         * @param error         the error that has occured
         * @returns {boolean}   wether or not the error has already been processed
         * @private
         */
        _processRequestError(error) {
            Debug.Media.LibraryLoader && console.log("Processing Error: " + JSON.stringify(error));
            var handled = false;

            if (error.reason === MediaEnum.PopupMessageId.RESCANNING) {
                var nextRequest;

                while (nextRequest = this.requestQueue.shift()) {
                    if (nextRequest.deferred) {
                        nextRequest.deferred.reject(error.reason);
                    }
                }

                handled = true;
            }

            return handled;
        }

        _requestScanState() {
            Debug.Media.LibraryLoader && console.log(this.name, "_requestScanState");
            MediaServerComp.sendMediaServerCommand(MediaEnum.Commands.LIBRARY.SCAN_STATUS, true).done(function (res) {
                Debug.Media.LibraryLoader && console.log(this.name, "_requestScanState: " + JSON.stringify(res));
                var isScanning = res.data[0].scanning !== 0;

                if (this.isScanning !== isScanning) {
                    if (isScanning) {
                        this._scanStarted();
                    } else {
                        this._scanEnded();
                    }
                }
            }.bind(this), function () {
                Debug.Media.LibraryLoader && console.error(this.name + ":_requestScanState: failed!");
            }.bind(this));
        }

        _scanStarted() {
            Debug.Media.LibraryLoader && console.log(this.name, "_scanStarted");

            this._updateAndDispatchScanState(true);
        }

        _scanEnded() {
            Debug.Media.LibraryLoader && console.log(this.name, "_scanEnded");

            this._updateAndDispatchScanState(false);
        }

        _updateAndDispatchScanState(isScanning) {
            Debug.Media.LibraryLoader && console.log(this.name, "_updateAndDispatchScanState: " + JSON.stringify(isScanning));
            this.isScanning = isScanning;
            this.emit(SCAN_EVENT, this.isScanning);
        }

    }

    Components.MediaServer.extensions.LibraryLoader = LibraryLoader;
    return Components;
}(window.Components || {});
