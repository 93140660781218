/**
 * This component is used when the mediaServer is not directly reachable. It then sends all the communication to the
 * Miniserver, which then passes on the commands to the mediaServer.
 */
window.Components = function (Components) {
    class MiniserverMediaProxy extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.apiVersionReceived = false;
            this.messageCache = [];
        }

        connect(mediaServer) {
            Debug.Media.MiniserverProxy && console.log("MiniserverMediaProxyExt", "connect '" + JSON.stringify(mediaServer) + "'");
            this.uuidAction = mediaServer.uuidAction;
            this.apiVersionReceived = false;
            this.channel.emit(MediaServerComp.ECEvent.ConnEstablished);
            SandboxComponent.registerForStateChangesForUUID(this.uuidAction, this, this._receivedStates);
        }

        disconnect() {
            SandboxComponent.unregisterForStateChangesForUUID(this.uuidAction, this);
            this.messageCache = [];
            this.apiVersionReceived = false;
            this.channel.emit(MediaServerComp.ECEvent.ConnClosed);
        }

        sendCommand(cmdObj) {
            Debug.Media.Comm && console.log("MiniserverMediaProxyExt", "Sending command '" + JSON.stringify(command) + "'");
            var command = cmdObj.cmd,
                automatic = !!cmdObj.auto,
                isSecured = false,
                // A media-zone cannot be secured at the moment!
                type = cmdObj.type || Commands.Type.QUEUE,
                encodedCommand = encodeURIComponent(command),
                // the miniserver will decode it before forwarding it.
                // ensure 'dontRecord' is set to true here. All commands sent from here mustn't be recorded, centralCommNode filters
                // those who are to be recorded!
                promise = SandboxComponent.sendCommand(this, this.uuidAction, encodedCommand, type, isSecured, automatic, true);
            promise.then(function success(s) {
                Debug.Media.Comm && console.log("MiniserverMediaProxyExt", "command sent '" + command + "'");
            }, function error(e) {
                Debug.Media.Comm && console.error("MiniserverMediaProxyExt", "command failed! " + command + " - " + JSON.stringify(e));
            }, function notify(i) {
                Debug.Media.Comm && console.info("MiniserverMediaProxyExt", "command info " + command + " - " + JSON.stringify(i));
            });
        }

        // state callbacks
        _receivedStates(newStates) {
            // Important that this message is always dispatched at first.
            if (newStates.hasOwnProperty('apiVersion')) {
                var apiVersion = newStates.apiVersion.text;

                if (!this.apiVersionReceived) {
                    // the message is received more than once
                    Debug.Media.Comm && console.log("MiniserverMediaProxyExt", "Received Api-Version from Miniserver: " + apiVersion);
                    this.channel.emit(MediaServerComp.ECEvent.MessageReceived, apiVersion);
                    this.apiVersionReceived = true;

                    this._dispatchCachedMessages();
                }

                delete newStates.apiVersion; // delete, we've already processed it!
            }

            if (newStates.hasOwnProperty('audioEvent')) {
                var msgString = newStates.audioEvent.text;

                if (msgString && msgString !== "") {
                    var debugLen = 200;
                    var outputStr = msgString.length > debugLen ? msgString.substr(0, debugLen / 2) + " .. " + msgString.substr(msgString.length - (debugLen / 2 + 1), debugLen / 2) : msgString;
                    Debug.Media.Comm && console.log("MiniserverMediaProxyExt", "Received mediaSever-message from Miniserver: " + outputStr);

                    if (!this.apiVersionReceived) {
                        this._cacheMessage(msgString);
                    } else {
                        this.channel.emit(MediaServerComp.ECEvent.MessageReceived, msgString);
                    }
                }

                delete newStates.audioEvent; // delete, we've already processed it!
            }
        }

        // Messages are cached if the API message hasn't arrived yet.
        _cacheMessage(msg) {
            this.messageCache.push(msg);
        }

        // Messages are dispatched once the API message has arrived.
        _dispatchCachedMessages() {
            var i;

            for (i = 0; i < this.messageCache.length; i++) {
                this.channel.emit(MediaServerComp.ECEvent.MessageReceived, this.messageCache[i]);
            }

            this.messageCache = [];
        }

    }

    Components.MediaServer.extensions.MiniserverMediaProxy = MiniserverMediaProxy;
    return Components;
}(window.Components || {});
