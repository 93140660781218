'use strict';

window.Components = function (Components) {
    class QueueLoader extends Components.MediaServer.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        // -------------------------------------------------------
        //            Handling Events
        // -------------------------------------------------------

        /**
         * Superclass MediaLoaderExtension is already registered for events. Override but still call superclasses
         * handleEvent at first.
         */
        _handleEventReceived(evId, event) {
            super._handleEventReceived(...arguments);

            if (Object.keys(event)[0] === MediaEnum.EventIdentifier.AUDIO.QUEUE) {
                this._handleQueueEvent(event);
            }
        }

        // -------------------------------------------------------
        //            Public Methods Events
        // -------------------------------------------------------
        getAcquireCommand() {
            if (!this.hasOwnProperty(MediaEnum.Event.PLAYER_ID)) {
                return false;
            } else {
                return 'audio/' + this[MediaEnum.Event.PLAYER_ID] + '/' + MediaEnum.AudioCommands.QUEUE.GET;
            }
        }

        prepareResult(result) {
            var adoptedResult = super.prepareResult(...arguments); // queue items usually have no file type, just an audioType. As we rely on fileTypes, assign one.

            adoptedResult.items.forEach(function (item) {
                if (!item.hasOwnProperty(MediaEnum.Event.FILE_TYPE)) {
                    switch (item[MediaEnum.Event.AUDIO_TYPE]) {
                        case MediaEnum.AudioType.STREAM:
                        case MediaEnum.AudioType.AIRPLAY:
                        case MediaEnum.AudioType.SPOTIFY_CONNECT:
                        case MediaEnum.AudioType.FILE:
                            item[MediaEnum.Event.FILE_TYPE] = MediaEnum.FileType.LOCAL_FILE;
                            break;

                        case MediaEnum.AudioType.PLAYLIST:
                            item[MediaEnum.Event.FILE_TYPE] = MediaEnum.FileType.LOCAL_PLAYLIST;
                            break;

                        case MediaEnum.AudioType.LINEIN:
                            item[MediaEnum.Event.FILE_TYPE] = MediaEnum.FileType.HW_INPUT;
                            break;

                        default:
                            break;
                    }
                }
            });
            return adoptedResult;
        }

        _handleQueueEvent(event) {
            var playerId = event[MediaEnum.EventIdentifier.AUDIO.QUEUE][0][MediaEnum.Event.PLAYER_ID];
            Debug.Control.AudioZone.QueueLoader && console.log(this.name, "Queue changed for " + playerId);

            if (this[MediaEnum.Event.PLAYER_ID] && playerId !== this[MediaEnum.Event.PLAYER_ID]) {
                // Queue changed for a different player than we're dealing with right now.
                return;
            }

            Debug.Control.AudioZone.QueueLoader && console.log("         cancel all requests (" + this.requestQueue.length + (this.currentRequest ? " + 1" : "") + ") total: " + Object.keys(this.requests).length);
            this.currentRequest >= 0 && this._rejectAndDeleteRequest(this.currentRequest);
            this.currentRequest = -1;
            var nextRequest;

            while (nextRequest = this.requestQueue.shift()) {
                this._rejectAndDeleteRequest(nextRequest);
            }

            Debug.Control.AudioZone.QueueLoader && console.log("         after cancelling all requests (" + this.requestQueue.length + (this.currentRequest ? " + 1" : "") + ") total: " + Object.keys(this.requests).length);
            Debug.Control.AudioZone.QueueLoader && console.log("         invalidate the cache");
            this.invalidateContentCachesOf(playerId);
            Debug.Control.AudioZone.QueueLoader && console.log("        invalidated");
        }

        requestContent(identifier, packageSize, mediaTypeDetails) {
            this._updatePlayer(mediaTypeDetails);

            return super.requestContent(...arguments);
        }

        prefetchContent(identifier, packageSize, mediaTypeDetails) {
            this._updatePlayer(mediaTypeDetails);

            return super.prefetchContent(...arguments);
        }

        _updatePlayer(mediaTypeDetails) {
            if (mediaTypeDetails && mediaTypeDetails.hasOwnProperty(MediaEnum.Event.PLAYER_ID)) {
                var newPlayerid = mediaTypeDetails[MediaEnum.Event.PLAYER_ID];

                if (!this[MediaEnum.Event.PLAYER_ID]) {
                    Debug.Media.Loader && console.log(this.name, "No player so far, nothing to do");
                } else if (this[MediaEnum.Event.PLAYER_ID] !== newPlayerid) {
                    Debug.Media.Loader && console.log(this.name, "The player has changed from " + this[MediaEnum.Event.PLAYER_ID] ? this[MediaEnum.Event.PLAYER_ID] : "no player" + "" + " to " + newPlayerid + " - resetting the cache"); // the service did change, respond by invalidating all the cache

                    this.cache = {};
                }

                this[MediaEnum.Event.PLAYER_ID] = newPlayerid;
            }
        }

        /*  Overwrite baseclass, since getAcquireCommand is handled. Other loaders assume 'audio/cfg' is prepended
                     to their commands, in this subclass we return the full command in getAcquireCommand - except for the
                     arguments.
                     */
        _doesHandleResult(result) {
            var doHandle = false;

            if (!doHandle && result.hasOwnProperty("command")) {
                // test new mapping handling
                var lastRqObj = this.requests[this.currentRequest];

                if (lastRqObj && result.command === lastRqObj.command) {
                    doHandle = true;
                }
            }

            return doHandle;
        }

        /**
         * Concates the arguments to the command. Is handled different than in the other loaders since here
         * getAcquireCommand returns the full command (audio/ does not need to be concatenated).
         * @param command contains the full command (audio/{playerid}/getqueue)
         * @param args the arguments that need to be appended to the command (start/nitems)
         * @returns {string}
         * @private
         */
        _send(command, args) {
            var fullCmd = command + "/" + args;
            this.channel.emit(this.component.ECEvent.SendMessage, {
                cmd: fullCmd,
                auto: true
            });
            return fullCmd;
        }

        /* Overwrite baseclass implementation, we don't need the request id in our command */
        getAcquireCommandArguments(request) {
            //return request.id + "/" + request.start + "/" + request.nItems;
            return request.start + "/" + request.nItems;
        }

    }

    Components.MediaServer.extensions.QueueLoader = QueueLoader;
    return Components;
}(window.Components || {});
