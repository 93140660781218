'use strict';

window.Components = function (Components) {
    class ZoneFavoriteLoader extends Components.MediaServer.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        getAcquireCommand() {
            return 'getroomfavs';
        }

        _processResult(data, cmd) {
            Debug.Media.Loader && console.log(this.name, "Processing Result: " + JSON.stringify(data));

            super._processResult(...arguments);
        }

        // don't insert empty slots
        prepareResult(result, finished) {
            Debug.Media.Loader && console.log(this.name, "prepareResult: " + JSON.stringify(result));

            for (var j = 0; j < result.items.length; j++) {
                var item = result.items[j];
                item[MediaEnum.Event.FILE_TYPE] = MediaEnum.FileType.ROOM_FAVORITE;
            } // sort by slot id


            result.items.sort(function compare(a, b) {
                if (a[MediaEnum.Event.SLOT] < b[MediaEnum.Event.SLOT]) {
                    return -1;
                }

                if (a[MediaEnum.Event.SLOT] > b[MediaEnum.Event.SLOT]) {
                    return 1;
                }

                return 0;
            });
            return result;
        }

    }

    Components.MediaServer.extensions.ZoneFavoriteLoader = ZoneFavoriteLoader;
    return Components;
}(window.Components || {});
