'use strict';

window.Components = function (Components) {
    class ServiceLoader extends Components.MediaServer.extensions.ServiceLoader {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        getAcquireCommand() {
            if (!this.service) {
                return false;
            } else {
                return 'searchdetails/' + this.service[MediaEnum.Attr.SERVICE.UID];
            }
        }

    }

    Components.MediaServer.extensions.SearchDetailLoader = ServiceLoader;
    return Components;
}(window.Components || {});
