'use strict';

window.Components = function (Components) {
    class OldPlaylistLoader extends Components.MediaServer.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        getAcquireCommand() {
            return 'getplaylists';
        }

        getAcquireCommandArguments(request) {
            /* ignore the request id. It's faked and not required by the mediaServer, since there is only one
                list of all playlists. You cannot dig deeper into it - you'd have to use other commands.
             */
            return request.start + "/" + request.nItems;
        }

        _processResult(data, cmd) {
            Debug.Media.Loader && console.log("Processing Result: " + JSON.stringify(data)); // we don't have an ID in the result. for internal handling we need one.

            data.id = 0;

            super._processResult(...arguments);
        }

    }

    Components.MediaServer.extensions.OldPlaylistLoader = OldPlaylistLoader;
    return Components;
}(window.Components || {});
