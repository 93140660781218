'use strict';

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        var SEARCH_FILE_NAME = 'mediaSearch',
            MAX_SEARCH_RESULTS = 5;

        class GlobalSearchExt extends Components.MediaServer.extensions.SearchExtBase {
            constructor(component, extensionChannel) {
                super(...arguments);
                this.username = MediaEnum.NOUSER;
                this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                    if (result.oldCommand === MediaEnum.GlobalSearch) {
                        this.globalSearchDefer.notify(this._processResult(result));
                    }
                }.bind(this));
            }

            searchFor(delegate, keyword) {
                var command = "";
                this.delegate = delegate;
                this.cachedTables = {};

                if (this.globalSearchDefer) {
                    this.globalSearchDefer.reject();
                    this.globalSearchDefer = null;
                    this.globalSearchDefer = Q.defer();
                } else {
                    this.globalSearchDefer = Q.defer();
                }

                this._getAvailableSources().then(function (path) {
                    // Music Server only handles encoded urls
                    command = path + '/' + encodeURIComponent(keyword);
                    Debug.Control.AudioZone.GlobalSearch && console.log(this.name + "GlobalSearch Command: " + command);
                    MediaServerComp.sendMediaServerCommand(command);
                });

                return this.globalSearchDefer.promise;
            }

            saveKeyword(identifier, keyword, controlUuid) {
                var fileContent = {};
                return PersistenceComponent.loadFile(SEARCH_FILE_NAME, DataType.OBJECT).then(function (structure) {
                    if (structure[controlUuid]) {
                        if (structure[controlUuid][identifier]) {
                            if (!structure[controlUuid][identifier].includes(keyword)) {
                                structure[controlUuid][identifier].push(keyword);
                                structure[controlUuid][identifier].splice(0, structure[controlUuid][identifier].length - 4);
                                return PersistenceComponent.saveFile(SEARCH_FILE_NAME, structure, DataType.OBJECT);
                            }
                        } else {
                            structure[controlUuid][identifier] = [];
                            structure[controlUuid][identifier].push(keyword);
                            return PersistenceComponent.saveFile(SEARCH_FILE_NAME, structure, DataType.OBJECT);
                        }
                    } else {
                        structure[controlUuid] = {};
                        structure[controlUuid][identifier] = [];
                        structure[controlUuid][identifier].push(keyword);
                        return PersistenceComponent.saveFile(SEARCH_FILE_NAME, structure, DataType.OBJECT);
                    }
                }.bind(this), function (err) {
                    fileContent[controlUuid] = {};
                    fileContent[controlUuid][identifier] = [];
                    fileContent[controlUuid][identifier].push(keyword);
                    return PersistenceComponent.saveFile(SEARCH_FILE_NAME, fileContent, DataType.OBJECT);
                }.bind(this));
            }

            deleteKeywords(identifier, controlUuid) {
                return PersistenceComponent.loadFile(SEARCH_FILE_NAME, DataType.OBJECT).then(function (structure) {
                    if (structure[controlUuid]) {
                        if (structure[controlUuid][identifier]) {
                            delete structure[controlUuid][identifier];
                            return PersistenceComponent.saveFile(SEARCH_FILE_NAME, structure, DataType.OBJECT);
                        }
                    }
                }.bind(this));
            }

            loadLastUsedKeywords(identifier, controlUuid) {
                return PersistenceComponent.loadFile(SEARCH_FILE_NAME, DataType.OBJECT).then(function (structure) {
                    if (structure[controlUuid]) {
                        if (structure[controlUuid][identifier]) {
                            return structure[controlUuid][identifier];
                        }
                    }
                }.bind(this));
            }

            getCurrentSpotifyUsername() {
                return this.username;
            }

            getPreparedTableContent(type) {
                var returnContent;

                if (this.cachedTables) {
                    switch (type) {
                        case MediaEnum.Search_Result.LOCAL:
                            returnContent = this.cachedTables[MediaEnum.Search_Result.LOCAL];
                            break;

                        case MediaEnum.Search_Result.TUNE_IN:
                            returnContent = this.cachedTables[MediaEnum.Search_Result.TUNE_IN];
                            break;

                        case MediaEnum.Search_Result.SPOTIFY:
                            returnContent = this.cachedTables[MediaEnum.Search_Result.SPOTIFY];
                            break;

                        default:
                            returnContent = [];
                            break;
                    }
                }

                return returnContent;
            }

            createAudioResultCell(content, type) {
                content.contentType = this._getContentTypeForType(type);

                var isBrowsable = MediaServerComp.isFileTypeBrowsable(content[MediaEnum.Event.FILE_TYPE]),
                    identifier = this._getIdentifierFromType(type),
                    username = this._getUsernameForType(type),
                    buttonTapped,
                    action,
                    showStateArgs;

                if (this.delegate.ViewController instanceof GUI.AudioZoneControlContentViewController || this.delegate.ViewController instanceof GUI.AudioZoneControlDetailedViewController) {
                    if (this.delegate._showContextMenuForItem) {
                        buttonTapped = this.delegate._showContextMenuForItem.bind(this.delegate, content);
                    }
                } else if (this.delegate.ViewController instanceof GUI.AddMediaViewControllerBase) {
                    buttonTapped = this.delegate.ViewController.addItem.bind(this.delegate.ViewController, content, this.delegate.getMediaInfo());
                }

                if (isBrowsable) {
                    action = function () {
                        showStateArgs = [Controls.AudioZoneControl.MediaBrowserBase.getScreenStateForItem(content), null, {
                            username: username,
                            lastSelectedItem: content,
                            identifier: identifier,
                            contentTypes: [this.delegate.contentType],
                            mediaType: this.delegate.mediaType
                        }];
                        this.delegate.ViewController.showState.apply(this.delegate.ViewController, showStateArgs);
                    }.bind(this);
                } else {
                    action = MediaServerComp.playFileUrl.bind(this, content, this.delegate.getMediaInfo(content.contentType));
                }

                return this._getMediaBrowserFromType(type).getCellFromContentTypeItem(content, {
                    identifier: this._getIdentifierFromType(type),
                    username: this.username
                }, content.contentType, false, isBrowsable, action, buttonTapped, this.delegate.ViewController);
            }

            // ----------------
            // Private Section
            // ----------------
            _getIdentifierFromType(type) {
                if (type === MediaEnum.Search_Result.LOCAL || type === MediaEnum.Search_Result.TUNE_IN) {
                    return MediaEnum.Service.LOCAL;
                } else if (type === MediaEnum.Search_Result.SPOTIFY) {
                    return MediaEnum.Service.SPOTIFY;
                }
            }

            _getUsernameForType(type) {
                if (type === MediaEnum.Search_Result.SPOTIFY) {
                    return this.username;
                } else {
                    return MediaEnum.NOUSER;
                }
            }

            _getMediaBrowserFromType(type) {
                if (type === MediaEnum.Search_Result.LOCAL) {
                    return Controls.AudioZoneControl.MediaBrowserLibrary;
                } else if (type === MediaEnum.Search_Result.SPOTIFY || type === MediaEnum.Search_Result.TUNE_IN) {
                    return Controls.AudioZoneControl.MediaBrowserService;
                } else {
                    return Controls.AudioZoneControl.MediaBrowserBase;
                }
            }

            _getAvailableSources() {
                var path = MediaEnum.Commands.GLOBAL_SEARCH.BASE_CMD,
                    arr = [],
                    username = "";
                return MediaServerComp.getCurrentSpotifyId(true).then(function (res) {
                    this.username = res;
                    Debug.Control.AudioZone.GlobalSearch && console.log(this.name + ": Saved Spotify Username: " + this.username);
                    return MediaServerComp.getCurrentServices().promise.then(function (servicesObject) {
                        if (servicesObject.length) {
                            username = servicesObject[0].user;

                            if (!this.username) {
                                this.username = username;
                            }
                        }

                        return MediaServerComp.sendMediaServerCommand(MediaEnum.Commands.GLOBAL_SEARCH.DESCRIBE).then(function (res) {
                            Object.keys(res.globalsearch_result).forEach(function (key) {
                                if (key === MediaEnum.Service.SPOTIFY && this.username) {
                                    arr.push(key + '@' + this.username + ':' + res.globalsearch_result[key].join('#' + MAX_SEARCH_RESULTS + ",") + '#' + MAX_SEARCH_RESULTS);
                                } else {
                                    arr.push(key + ':' + res.globalsearch_result[key].join('#' + MAX_SEARCH_RESULTS + ",") + '#' + MAX_SEARCH_RESULTS);
                                }
                            }.bind(this));
                            path += arr.join('|');
                            return path;
                        }.bind(this));
                    }.bind(this));
                }.bind(this));
            }

            _processResult(result) {
                var update;

                if (result.type) {
                    update = {
                        type: result.type
                    };

                    switch (result.type) {
                        case MediaEnum.Search_Result.LOCAL:
                        case MediaEnum.Search_Result.TUNE_IN:
                            update.tableContent = this._createSpecificTableContent(result.globalsearch_result, result.type, this.delegate);
                            break;

                        case MediaEnum.Search_Result.SPOTIFY:
                            update.tableContent = this._createSpecificTableContent(result.globalsearch_result.result, result.type, this.delegate);
                            break;
                    }
                }

                return update;
            }

            _createSpecificTableContent(result, type, delegate) {
                var content = [],
                    section;
                result && Object.keys(result).forEach(function (key) {
                    section = {
                        rows: [],
                        type: key
                    };
                    section.headerTitle = result[key].caption;

                    if (result[key].link) {
                        section.sectionRightButtonTitle = _('media.global-search-button.show-more');
                        section.link = result[key].link;
                    }

                    if (key === 'topresults') {//this.topResultsSection = section;
                    } else if (key !== MediaEnum.Attr.SERVICE.USER) {
                        if (!result[key].items) {
                            console.warn('no items in result: ' + key);
                        }

                        section.rows = result[key].items.map(function (item) {
                            return this.createAudioResultCell(item, type, delegate);
                        }.bind(this));

                        if (section.rows.length) {
                            content.push(section);
                        }
                    }
                }.bind(this)); // Sort the tune in results, the radio stations should be on the top

                if (type === MediaEnum.Search_Result.TUNE_IN) {
                    content = content.sort(function (a, b) {
                        if (a.type === "station") {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                }

                this.cachedTables[type] = content;
                return content;
            }

            _getContentTypeForType(type) {
                var contentType;

                switch (type) {
                    case MediaEnum.Search_Result.LOCAL:
                        contentType = MediaEnum.MediaContentType.LIBRARY;
                        break;

                    case MediaEnum.Search_Result.SPOTIFY:
                    case MediaEnum.Search_Result.TUNE_IN:
                        contentType = MediaEnum.MediaContentType.SERVICE;
                }

                return contentType;
            }

        }

        Components.MediaServer.extensions.GlobalSearchExt = GlobalSearchExt;
    }
    return Components;
}(window.Components || {});
