'use strict';

window.Components = function (Components) {
    /**
     * This extension will replace the PopupExt. Dialogs are popups with additional functionality.
     * Dialogs are used to give the Music Server full control on what to present to the user. It enables to
     * show/update/remove popups (not only sticky ones like in the popupExt). It additionaly provides the ability to
     * only present it to those users that are currently in an audio zone that is affected by the dialog. Users can respond
     * to these kinds of popups with commands or are redirected to links.
     */
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        /**
         * Attributes of an dialog event.
         * @type {{ID: string, PLAYER_IDS: string, STATE: string, LEVEL: string, TITLE: string, MSG: string, MSG_TYPE: string, RESPONSES: string, CANCEL: string}}
         */
        var DialogAttr = {
            ID: "ID",
            // identifier that identifies a dialog, identifies the source music server too.
            PLAYER_IDS: "playerids",
            // csv string of the player ids affected by this dialog
            STATE: "state",
            // the dialog state, show update hide or force - see below
            LEVEL: "level",
            // the severity level of the dialog, 1 = info, 2 = warning, 3 = critical
            TITLE: "title",
            // title of the popup, user friendly & localized
            MSG: "message",
            // message of popup, user friendly & localized
            MSG_TYPE: "messagetype",
            // semantic info on what the message is about, so the client can take additional action
            BUTTONS: "buttons",
            // array of button objects. see below for info on the buttons action attributes.
            CANCEL: "cancelbtn",
            // to customize the cancel button, contains an object like in responses array
            TTL: "ttl" // the dialog will only be valid for a limited amout of seconds. after that the client will remove it.

        };
        /**
         * Attributes of a button object as used in an dialog event.
         * @type {{TITLE: string, CMD: string, COLOR: string, LINK: string}}
         */

        var DialogButtonAttr = {
            TITLE: "title",
            // the user friendly & localized response button text
            CMD: "command",
            // an optional command to send back to the music server
            COLOR: "color",
            // an optional color for the button, default is the popups color (green, red, gray)
            LINK: "link" // an optional link to open when the user taps the response button

        };
        /**
         * The States of a dialog as used in an dialog event.
         * @type {{SHOW: string, UPDATE: string, HIDE: string, FORCE: string}}
         */

        var DialogState = {
            SHOW: "show",
            // (default) show a new dialog
            HIDE: "hide",
            // hide the dialog
            FORCE: "force" // show it without being cancellable, the user has to respond (formerly it was sticky)

        };
        /**
         * The dialog-level provides info on how ciritical the dialog is. It affects the icon & color used in the
         * dialog.
         * @type {{INFO: number, WARNING: number, CRITICAL: number}}
         */

        var DialogLevel = {
            INFO: 1,
            WARNING: 2,
            CRITICAL: 3
        };

        class DialogExt extends Components.Extension {
            constructor(component, extensionChannel) {
                super(...arguments);
                this.currentZoneId = 0;
                this.dialogQueue = []; // array containing all the dialog IDs in the order they are received

                this.dialogMap = {}; // map containing all active dialogs mapped with their IDs

                this.activeDialog = null; // for storing the active dialogs contentOld.

                this.ttlMap = {}; // map containing all TTL-Timers mapped with their dialogs IDs

                this.registerExtensionEv(this.component.ECEvent.EventReceived, this._handleEventReceived.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ZoneChanged, this._handleZoneChanged.bind(this));
            }

            destroy() {
                super.destroy(...arguments);
            }

            // ---------------------------------------------------------------
            //            Handling events
            // ---------------------------------------------------------------
            _handleEventReceived(evId, event) {
                var key = Object.keys(event)[0];
                var eventData = event[key];

                if (key === MediaEnum.EventIdentifier.DIALOG) {
                    Debug.Media.DialogExt && console.log("DialogExt", "Popup event received: " + JSON.stringify(event));
                    var i;

                    for (i = 0; i < eventData.length; i++) {
                        this._respondToDialogEvent(eventData[i]);
                    }
                }
            }

            /**
             * Called whenever the zoneChanged event is fired.
             * @param evId          unused
             * @param zoneControl   either null if the zone view was left, or the active zone.
             * @private
             */
            _handleZoneChanged(evId, zoneControl) {
                var playerid = zoneControl ? zoneControl.details.playerid : -1;
                Debug.Media.DialogExt && console.log("DialogExt", "_handleZoneChanged: " + playerid);
                this.currentZoneId = playerid;
            }

            // ------------------------------------------------------------------------------------------
            // ------------------  respondToDialogEvent (Show/Hide/Force Dialogs)  ----------------------
            // ------------------------------------------------------------------------------------------

            /***
             * Entry-Method used to handle a dialog contentOld. Is also used to process dialog contents that where
             * in the queue because something else was shown before..
             * @param content   contentOld of a dialog event, an object containing the data a dialog event sends out.
             * @param dequeued  if it's a dialog content from the queue that is responded to. If so, don't mind TTL
             * @private
             */
            _respondToDialogEvent(content, dequeued) {
                Debug.Media.DialogExt && console.log("DialogExt", "_respondToDialogEvent: " + content.ID); // gather the minimum information

                var dialogId = content[DialogAttr.ID],
                    state = content[DialogAttr.STATE]; // check whether or not this content is of any relevance now.

                if (!this._isRelevant(content)) {
                    Debug.Media.DialogExt && console.log("DialogExt", "      popup with id " + content.ID + " is not relevant right now");
                    return;
                } // on initial responds the TTL needs to be processed.


                if (!dequeued && content.hasOwnProperty(DialogAttr.TTL)) {
                    this._startTtlTimer(content);
                }

                switch (state) {
                    case DialogState.HIDE:
                        this._hideDialog(dialogId);

                        break;

                    case DialogState.FORCE:
                        this._forceDialog(dialogId, content);

                        break;

                    default:
                        // show is the default
                        this._showDialog(dialogId, content);

                        break;
                }
            }

            /**
             * Handle a simple show dialog - not a forced one.
             * @param dialogId  the id of the dialog to show
             * @param content   the content of the dialog.
             * @private
             */
            _showDialog(dialogId, content) {
                Debug.Media.DialogExt && console.log("DialogExt", "_showDialog: " + dialogId);

                if (this.activeDialog) {
                    this._enqueueContent(dialogId, content);
                } else {
                    this.activeDialog = content;

                    this._presentActiveDialog();
                }
            }

            /**
             * Hides the dialog with the ID provided. If not shown yet, it won't be shown later. Triggered either
             * directly via _respondToDialogEvent or called when a TTL timer fires.
             * @param dialogId  the id of the dialog to hide.
             * @private
             */
            _hideDialog(dialogId) {
                Debug.Media.DialogExt && console.log("DialogExt", "_hideDialog: " + dialogId); // first of all, remove the TTS if running.

                this._stopTtlTimer(dialogId);

                if (this.activeDialog != null && this.activeDialog[DialogAttr.ID] === dialogId) {
                    this._dismissActiveDialog();
                } else {
                    // it's not visible so removing it from the queue suffices
                    this._dequeueContent(dialogId);
                }
            }

            /**
             * Handles presenting a forced dialog. It will override any currently present dialog. If it's one that
             * was forced too, it'll put it back into the queue for handling it later.
             * @param dialogId  the id of the active dialog to handle.
             * @param content   the content to present forced.
             * @private
             */
            _forceDialog(dialogId, content) {
                Debug.Media.DialogExt && console.log("DialogExt", "_forceDialog: " + dialogId); // Check if there is an active dialog to be removed first.

                if (this.activeDialog) {
                    // if the active dialog was forced, enqueue it for displaying it later
                    if (this.activeDialog[DialogAttr.STATE] === DialogState.FORCE) {
                        // enqueue the currently active forced dialog to display it again later on.
                        this._enqueueContent(this.activeDialog[DialogAttr.ID], this.activeDialog);
                    } // enqueue the new contentOld, so it's presented by the activeDialogs cancel promise.


                    this._enqueueContent(dialogId, content); // dismissing the active dialog will call it's cancel promise & therefore present a new popup.


                    this._dismissActiveDialog();
                } else {
                    // update the active dialog attribute.
                    this.activeDialog = content;

                    this._presentActiveDialog();
                }
            }

            // --------------------------------------------------------------------
            // ------------------  Present & Dismiss Dialogs ----------------------
            // --------------------------------------------------------------------

            /**
             * Displays the activeDialog-Content Object on the UI. Does not alter the "activeDialog"-attribute
             * @private
             */
            _presentActiveDialog() {
                Debug.Media.DialogExt && console.log("DialogExt", "_presentActiveDialog: " + this.activeDialog.ID); // clone the content (it's modified afterwards)

                var popupContent = JSON.parse(JSON.stringify(this.activeDialog)); // Map the attributes of the incoming dialogEvent to match the PopupMangers needs.

                this._mapCancelButtonAttribute(popupContent);

                this._mapDialogLevel(popupContent);

                this._mapColorsOfButtons(popupContent.buttons); // present the dialog


                this.currentPromise = NavigationComp.showPopup(popupContent);
                this.currentPromise.then(this._respondToActiveDialogButton.bind(this), this._respondToCancelDialogButton.bind(this, popupContent[DialogAttr.ID]));
            }

            /**
             * Removes the active dialog object from the UI. The cancel response of the currentPromise will be
             * called, in order to avoid a command being sent back to the music server or alike (is never wanted if
             * a dialog is dismissed by a timeout or a HIDE-event) it also does reset "activeDialog" attribute.
             * @private
             */
            _dismissActiveDialog() {
                Debug.Media.DialogExt && console.log("DialogExt", "_dismissActiveDialog"); // reset active dialog attribute to avoid responding in the currentPromises cancel response.

                this.activeDialog = null; // remove popup, will call the cancel response of the currentPromise

                NavigationComp.removePopup(this.currentPromise);
            }

            // -------------------------------------------------------------
            // ------------------  Respond to Buttons ----------------------
            // -------------------------------------------------------------

            /**
             * Called when a user taps one of the buttons of an active dialog (NOT the cancel button)
             * @param btnIdx    the index of the button of the active dialog that was tapped
             * @private
             */
            _respondToActiveDialogButton(btnIdx) {
                Debug.Media.DialogExt && console.log("DialogExt", "_respondToActiveDialogButton: " + btnIdx);

                this._stopTtlTimer(this.activeDialog[DialogAttr.ID]);

                if (this._respondToButton(this.activeDialog.buttons[btnIdx])) {
                    this._proceedToNextDialog();
                }
            }

            /**
             * Called either when the user taps the cancel button or if the dialog was dismissed programmatically
             * via a HIDE-Event or the TTL timer. If dismissed manually it's dialogId must equal the activeDialog's ID
             * @param dialogId    the id of the dialog that was cancelled.
             * @private
             */
            _respondToCancelDialogButton(dialogId) {
                Debug.Media.DialogExt && console.log("DialogExt", "_respondToCancelDialogButton: " + dialogId);

                this._stopTtlTimer(dialogId); // if it's an active dialog === the user tapped cancel, respond.


                if (this.activeDialog && dialogId === this.activeDialog[DialogAttr.ID]) {
                    Debug.Media.DialogExt && console.log("DialogExt", "       active dialog, respond!");

                    if (this.activeDialog.hasOwnProperty(DialogAttr.CANCEL)) {
                        this._respondToButton(this.activeDialog[DialogAttr.CANCEL]);
                    }
                }

                this._proceedToNextDialog();
            }

            /**
             * Called when a button is tapped, responds according to its attributes. The dialog itself is not
             * handled here, it's all about the button itself.
             * @param button        the buttons object containing it's attributes (such as an optional command or link)
             * @returns {boolean}   returns whether or not the next dialog can be shown.
             * @private
             */
            _respondToButton(button) {
                Debug.Media.DialogExt && console.log("DialogExt", "_respondToButton: " + JSON.stringify(button));
                var proceed = true;

                if (button.hasOwnProperty(DialogButtonAttr.CMD)) {
                    this._sendCommand(button[DialogButtonAttr.CMD]);
                }

                if (button.hasOwnProperty(DialogButtonAttr.LINK) && button[DialogButtonAttr.LINK] !== "") {
                    this._openLink(button[DialogButtonAttr.LINK]);

                    proceed = false;
                }

                return proceed;
            }

            /**
             * Will send a commandas a response to a dialog to the Music Server.
             * @param cmd   the cmd string to send to the Music Server, fully qualified (e.g.: "audio/4/unsync")
             * @private
             */
            _sendCommand(cmd) {
                Debug.Media.DialogExt && console.log("DialogExt", "_sendCommand: " + cmd);

                if (cmd && cmd !== "") {
                    // send as background cmd to mediaServer. bg since it's irrelevant for the task-recorder.
                    MediaServerComp.sendMediaServerCommand(cmd, true).done(null, function () {
                        console.error("DialogExt: Command failed " + cmd + "!");
                    });
                }
            }

            /**
             * Opens a link as a response to a button press in an dialog. Will either proceed to the next dialog
             * if opening the link succeeded or it will respond just like if the cancel button was hit.
             * @param link  the link to open (e.g. for spotify auth, additional infos, settings, ..)
             * @private
             */
            _openLink(link) {
                Debug.Media.DialogExt && console.log("DialogExt", "_openLink: " + link);
                NavigationComp.openWebsite(link).done(this._proceedToNextDialog.bind(this), this._respondToCancelDialogButton.bind(this, this.activeDialog[DialogAttr.ID]));
            }

            // ----------------------------------------------------------
            // ------------------  Enqueue/Dequeue ----------------------
            // ----------------------------------------------------------

            /**
             * dequeue and present the next dialog, if there is one. Will update the activeDialog in subsequent
             * method calls. (via _respondToDialogEvent.
             * @private
             */
            _proceedToNextDialog() {
                Debug.Media.DialogExt && console.log("DialogExt", "_proceedToNextDialog");
                this.activeDialog = null; // it'll be set properly in the respond method.

                var nxt = this._dequeueContent();

                if (nxt) {
                    this._respondToDialogEvent(nxt, true); // it's dequeued, don't mind TTS

                }
            }

            /**
             * Dequeues a dialog content object. Removes it from the dialogQueue and the dialogMap
             * @param [dialogId]   optionally specify the ID of a specific content to dequeue (remove)
             * @returns {*} either null if no content left, or the next content to be displayed.
             * @private
             */
            _dequeueContent(dialogId) {
                Debug.Media.DialogExt && console.log("DialogExt", "_dequeueContent: " + dialogId);
                var nextContent = null,
                    nextId;

                if (dialogId) {
                    nextId = dialogId; // the dialogId might be in the queue (if a not yet shown dialog times out) - remove it.

                    var idx = this.dialogQueue.indexOf(dialogId);

                    if (idx >= 0) {
                        this.dialogQueue.splice(idx, 1);
                    }
                } else if (this.dialogQueue.length > 0) {
                    nextId = this.dialogQueue.splice(0, 1)[0];
                }

                if (nextId) {
                    nextContent = this.dialogMap[nextId];
                    delete this.dialogMap[nextId];
                }

                return nextContent;
            }

            /**
             * Puts a new content with its id in the queue for displaying it later on.
             * @param dialogId      the id of the content to add
             * @param content       the content of the dialog
             * @private
             */
            _enqueueContent(dialogId, content) {
                Debug.Media.DialogExt && console.log("DialogExt", "_enqueueContent: " + dialogId);
                this.dialogQueue.push(dialogId);
                this.dialogMap[dialogId] = content;
            }

            // ----------------------------------------------
            // ------------------  TTL ----------------------
            // ----------------------------------------------

            /**
             * Will start a timer that will hide the dialog with this contentOld. the time is specified via the TTL-argument.
             * @param content   the content of the dialog to dismiss.
             * @private
             */
            _startTtlTimer(content) {
                var ttlMs = content[DialogAttr.TTL] * 1000,
                    // TTL is provided in seconds, not MS
                    timeout,
                    dialogId = content[DialogAttr.ID];

                if (ttlMs < 1) {
                    return;
                }

                Debug.Media.DialogExt && console.log("DialogExt", "_startTtlTimer for: " + content.ID);
                timeout = setTimeout(function () {
                    Debug.Media.DialogExt && console.log("DialogExt", "_ttl timer fired for: " + content.ID); // hide dialog will take care of the rest (also on removing the timer from the TTL Map)

                    this._hideDialog(dialogId);
                }.bind(this), ttlMs);
                this.ttlMap[dialogId] = timeout;
            }

            /**
             * Checks if a TTL timer is running and stops it if needed.
             * @param dialogId      the ID of the dialog whoms TTL is to be stopped.
             * @private
             */
            _stopTtlTimer(dialogId) {
                Debug.Media.DialogExt && console.log("DialogExt", "_stopTtlTimer of: " + dialogId);

                if (this.ttlMap.hasOwnProperty(dialogId)) {
                    clearTimeout(this.ttlMap[dialogId]);
                    delete this.ttlMap[dialogId];
                }
            }

            // -----------------------------------------------
            // ------------------  MISC ----------------------
            // -----------------------------------------------

            /**
             * Returns whether or not this popup is of any interest right now. Uses the playerids provided in the
             * dialog content and the currently visible zone attribute of this extension.
             * @param content       the content to check for relevance
             * @returns {boolean}   whether or not it'S relevant.
             * @private
             */
            _isRelevant(content) {
                Debug.Media.DialogExt && console.log("DialogExt", "_isRelevant: " + content.ID);
                var relevant = true,
                    // default if attribute is missing
                    zoneIDs = [];

                if (content.hasOwnProperty(DialogAttr.PLAYER_IDS) && content[DialogAttr.PLAYER_IDS] !== "") {
                    zoneIDs = content[DialogAttr.PLAYER_IDS].split(',');
                    relevant = zoneIDs.includes(this.currentZoneId.toString());
                }

                return relevant;
            }

            /**
             * map button colors -> from "red", "green", "gray" to Lx Colors
             * @param buttons   an array of buttons whos colors are to be mapped.
             * @private
             */
            _mapColorsOfButtons(buttons) {
                if (!buttons) {
                    return; // no buttons
                }

                for (var i = 0; i < buttons.length; i++) {
                    var button = buttons[i];

                    if (button.hasOwnProperty(DialogButtonAttr.COLOR)) {
                        var color = button[DialogButtonAttr.COLOR];

                        switch (color) {
                            case "red":
                                color = window.Styles.colors.red;
                                break;

                            case "green":
                                color = window.Styles.colors.green;
                                break;

                            case "grey":
                                color = Color.STATE_INACTIVE;
                                break;

                            default:
                                break;
                        }

                        button[DialogButtonAttr.COLOR] = color;
                    }
                }
            }

            /**
             * Dialogs are given specific levels (1 = info, 2 = warning, 3 = critical). Each of these levels has a
             * specific icon and a color of the dialog attached to it.
             * @param dialogContent     the content of the dialog whos level is to be translated to colors and icons
             * @private
             */
            _mapDialogLevel(dialogContent) {
                switch (dialogContent[DialogAttr.LEVEL]) {
                    case DialogLevel.INFO:
                        dialogContent.icon = Icon.INFO;
                        dialogContent.color = window.Styles.colors.green;
                        break;

                    case DialogLevel.WARNING:
                        dialogContent.icon = Icon.WARNING;
                        dialogContent.color = window.Styles.colors.green; // no more yellow popups! bad contrast

                        break;

                    case DialogLevel.CRITICAL:
                        dialogContent.icon = Icon.ERROR;
                        dialogContent.color = window.Styles.colors.red;
                        break;

                    default:
                        dialogContent.icon = Icon.INFO;
                        dialogContent.color = Color.STATE_INACTIVE;
                        console.error("Dialog-Event received with unknown level: " + dialogContent[DialogAttr.LEVEL]);
                        break;
                }
            }

            /**
             * The dialog events btncancel is different than the one used in the PopupManger. This method ensures
             * that the cancel button attribute is properly set for the PopupManager to display it.
             * @param dialogContent     the content do adopt for the popupMangaer
             * @private
             */
            _mapCancelButtonAttribute(dialogContent) {
                var cancelButton = null;

                if (dialogContent.hasOwnProperty(DialogAttr.CANCEL)) {
                    cancelButton = dialogContent[DialogAttr.CANCEL];
                    cancelButton.type = GUI.PopupBase.ButtonType.CANCEL;
                    dialogContent.buttons.push(cancelButton);
                } else if (dialogContent[DialogAttr.STATE] !== DialogState.FORCE) {
                    dialogContent.buttonCancel = true;
                }
            }

        }

        Components.MediaServer.extensions.DialogExt = DialogExt;
    }
    return Components;
}(window.Components || {});
