'use strict';

window.Components = function (Components) {
    {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

        // Name of internal event used to dispatch to clients that registered for it.
        var SERVICE_CHANGED = "ServiceChangedEvent";
        /**
         * Attributes of a reloadService-Event
         * @type {{ADD: string, CHANGE: string, REMOVE: string}}
         */

        var ReloadServiceEvent = {
            ACTION: 'action',
            USER: 'user',
            SERVICE: 'cmd'
        };

        class ServiceHandlerExt extends Components.Extension {
            constructor(component, extensionChannel) {
                super(...arguments);
                this.services = null;
                this.radios = null;
                this.availableServices = null;
                this.socketIsOpen = false;
                this.registerExtensionEv(this.component.ECEvent.ConnEstablished, this._handleConnectionEstablished.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ConnClosed, this._handleConnectionClosed.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                    this._handleResult(result);
                }.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ResultErrorReceived, function (evId, error) {
                    this._handleResultError(error);
                }.bind(this));
                this.registerExtensionEv(this.component.ECEvent.EventReceived, function (ev, args) {
                    var key = Object.keys(args)[0];

                    if (key === MediaEnum.EventIdentifier.SERVICE_CFG_ERROR) {
                        this._handleServiceCfgError(args[key]);
                    }
                }.bind(this));
                this.registerExtensionEv(this.component.ECEvent.ServiceChanged, this._handleServiceChanged.bind(this));
            }

            /**
             * Once registered, the changedFn is called once new services are loaded.
             * @param changedFn     the fn to call when changed, it has the following arguments
             *                              id          "ServiceChangedEvent"
             *                              services    up to date array of service-objects
             *                              [changed]   what service has changed - if known
             *                              [user]      what user in specific was changed - if known
             *                              [action]    was he added removed or udpated - if known
             * @returns {*} an unregister method. Call it and you no longer hear of service changes.
             */
            registerForServiceChanges(changedFn) {
                return this.on(SERVICE_CHANGED, changedFn);
            }

            /**
             * Returns a listing of the currently configured music service accounts
             * @returns {*}
             */
            getCurrentServices() {
                var result = {};

                if (this.currentServicesDeferred != null) {
                    return this.currentServicesDeferred;
                }

                this.currentServicesDeferred = Q.defer();

                if (this.services && this.services.length) {
                    result.data = this.services; // resolve async - some screens will require a promise in order to work (processAsync in Menu)

                    setTimeout(function () {
                        this.currentServicesDeferred.resolve(this.services);
                        this.currentServicesDeferred = null;
                    }.bind(this), 0);
                } else {
                    // only request if the services have changed or are not known yet.
                    this._requestServices();
                }

                result.promise = this.currentServicesDeferred.promise;
                return result;
            }

            /**
             * Returns a list of services that are available, which means that new service accounts may be added.
             * @returns {*}
             */
            getAvailableServices() {
                var result = {};

                if (this.availableServicesDeferred != null) {
                    return this.availableServicesDeferred;
                }

                this.availableServicesDeferred = Q.defer();

                if (this.availableServices) {
                    result.data = this.availableServices; // resolve async - some screens will require a promise in order to work (processAsync in Menu)

                    setTimeout(function () {
                        this.availableServicesDeferred.resolve(this.availableServices);
                        this.availableServicesDeferred = null;
                    }.bind(this), 0);
                } else {
                    // only request if the availableServices have changed or are not known yet.
                    this._requestAvailableServices();
                }

                result.promise = this.availableServicesDeferred.promise;
                return result;
            }

            getRadios() {
                var result = {};

                if (this.radiosDeferred != null) {
                    return this.radiosDeferred;
                }

                this.radiosDeferred = Q.defer();

                if (this.radios) {
                    result.data = this.radios; // notifiy does not work here, no listener yet
                } // everytime someone requests current services, load them!


                this._requestRadios();

                result.promise = this.radiosDeferred.promise;
                return result;
            }

            getServiceName(cmd) {
                var service = this._searchArrayForService(this.services, cmd);

                if (!service) {
                    service = this._searchArrayForService(this.availableServices, cmd);
                }

                if (service) {
                    return service.name;
                } else if (cmd === MediaEnum.Target.LIBRARY) {
                    return _("media.library");
                } else {
                    console.error("Service '" + cmd + "' was neither found in current nor available services!");
                    return cmd;
                }
            }

            getServiceIcon(cmd, user) {
                var service = this._searchArrayForService(this.services, cmd, user),
                    result;

                if (service) {
                    result = service[MediaEnum.Attr.SERVICE.ICON];
                } else if (cmd === MediaEnum.Target.LIBRARY) {
                    result = Icon.AudioZone.LIBRARY_ICON;
                } else if (cmd === MediaEnum.Target.RADIO) {
                    result = Icon.AudioZone.TUNEIN_ICON;
                } else if (cmd === MediaEnum.Target.LMS) {
                    result = null;
                } else {
                    console.error("Service '" + cmd + "' was neither found in current nor available services!");
                    result = MediaServerComp.getDefaultIconForUnknown();
                }

                return result;
            }

            isSearchableService(service) {
                var result = false;

                if (service) {
                    // look in the services array for this service
                    result = this._searchArrayForService(this.services, service[MediaEnum.Attr.SERVICE.CMD]) !== null;
                    result = result && service[MediaEnum.Attr.SERVICE.CMD] === MediaEnum.Service.SPOTIFY; // atm only spotify is searchable

                    if (!result && MediaServerComp.Feature.SEARCH_RADIO) {
                        // maybe it's a radio? all radios are searchable
                        result = this._searchArrayForService(this.radios, service[MediaEnum.Attr.SERVICE.CMD]) !== null;
                    }
                }

                return result;
            }

            /**
             * Checks whether or not the "cmd" attribute given is one of the radios or not. Needed in the search.
             * @param cmd the cmd attribute returned by the getradios-cmd (or the getservices cmd)
             * @returns {boolean} wether or not it's a radio
             */
            isRadio(cmd) {
                return cmd === MediaEnum.SearchLoc.RADIO || this._searchArrayForService(this.radios, cmd) !== null;
            }

            /**
             * Checks whether or not the service provided is a proper music service like spotify, google music or
             * if it's a regular service like the radios. This check is necessary as here new services can be checked
             * too, since the list of current and available services is available here.
             * @param service
             * @returns {boolean}
             */
            isMusicService(service) {
                var isService,
                    cmd = service[MediaEnum.Attr.SERVICE.CMD];

                try {
                    // if that is a music service, it has to be either in the list of currentl services (accounts)
                    isService = this._searchArrayForService(this.services, cmd) !== null; // or in the available services list

                    isService = isService || this._searchArrayForService(this.availableServices, cmd) !== null;
                } catch (ex) {
                    console.error("isMusicService check did fail. Assuming that it is not a music service!");
                    isService = false;
                }

                return isService;
            }

            /**
             * Provides Info on whether or not an item from a service can be "followed".
             * @param service   the service where that item is from
             * @param item      the item in question
             * @returns {*}
             */
            isServiceItemFollowable(service, item) {
                var isService = this.isMusicService(service),
                    cmd = service[MediaEnum.Attr.SERVICE.CMD],
                    playable = MediaServerComp.isFileTypePlayable(item[MediaEnum.Event.FILE_TYPE], MediaEnum.MediaContentType.SERVICE),
                    followable = false,
                    tag = null;

                if (item.hasOwnProperty(MediaEnum.Attr.Item.TAG)) {
                    tag = item[MediaEnum.Attr.Item.TAG];
                } // if it's not playable or not from a service, it cannot be followable


                if (playable && isService) {
                    // atm only spotify supports following
                    if (cmd === MediaEnum.Service.SPOTIFY && MediaServerComp.Feature.SPOTIFY_FOLLOWABLE) {
                        // atm only playlists can be followed
                        followable = tag === MediaEnum.Tag.PLAYLIST && item[MediaEnum.Event.FILE_TYPE] === MediaEnum.FileType.PLAYLIST_BROWSABLE;
                    }
                }

                return followable;
            }

            // -------------------------------------------------------------------------
            // PRIVATE
            // -------------------------------------------------------------------------
            _handleServiceCfgError(eventData) {
                var popup = eventData[0];
                popup.icon = Icon.INFO;
                popup.color = window.Styles.colors.green; // no more yellow popups! bad contrast

                popup.message = _("media.popup.service.unauthorized.message", {
                    user: popup.user
                });
                popup.buttonOk = _("media.service.auth-error.show");

                if (popup.hasOwnProperty('cmd')) {
                    switch (popup.cmd) {
                        case MediaEnum.Service.SPOTIFY:
                            popup.message += "<br><br>";
                            popup.message += _("media.popup.service.unauthorized.hint.spotify");
                            break;

                        case MediaEnum.Service.GOOGLE:
                            popup.message += "<br><br>";
                            popup.message += _("media.popup.service.unauthorized.hint.google");
                            break;

                        default:
                            break;
                    }
                }

                popup.buttonCancel = _("okay");

                if (this.serviceErrorPopup) {
                    // a new serviceconfigerror was received while the old one wasn't handled.
                    // dismiss the old one first
                    NavigationComp.removePopup(this.serviceErrorPopup);
                    this.serviceErrorPopup = null;
                }

                this.serviceErrorPopup = NavigationComp.showPopup(popup);
                this.serviceErrorPopup.done(function (buttonId) {
                    Debug.Media.ServiceHandler && console.log(this.name, "popup responded with " + JSON.stringify(buttonId));

                    this._showServiceEditor(popup);
                }.bind(this), function () {// do nothing;
                });
            }

            /**
             * Deals with a serviceChanged event. ServiceChanged is broadcasted via centralCommNode receiving a
             * reloadmusicapp_event with a service as cause. It'll reset the stored services & inform everyone who
             * needs to know about it.
             * @param id        unused - will always be "ServiceChanged"
             * @param event     the object containing the service, user and action that caused the change.
             * @private
             */
            _handleServiceChanged(id, event) {
                var action = event[ReloadServiceEvent.ACTION],
                    service = event[ReloadServiceEvent.SERVICE],
                    user = event[ReloadServiceEvent.USER]; // reset the stored services

                this.services = null;
                this.availableServices = null;

                this._requestServices();

                this._requestAvailableServices(); // emit so registered listeners know they need to reload their data.


                this.emit(SERVICE_CHANGED, this.services, service, user, action);
            }

            /**
             * Searches for a service in a given array (availableServices or activeServices)
             * @param serviceArr        where to search for the service
             * @param serviceCmd        the cmd for the service (e.g. local/spotify/googlemusic)
             * @param [serviceUser]     optionally the user can be specified too, only for availableServices
             * @returns {*}             the service object if found, null otherwise
             * @private
             */
            _searchArrayForService(serviceArr, serviceCmd, serviceUser) {
                if (!serviceArr) {
                    return null;
                }

                var result = null;
                serviceArr.some(function (service) {
                    if (service[MediaEnum.Attr.SERVICE.CMD] === serviceCmd) {
                        if (!serviceUser || service[MediaEnum.Attr.SERVICE.USER] === serviceUser) {
                            result = service;
                            return true;
                        }

                        return false;
                    }
                });
                return result;
            }

            // -------------------------------------------------------------------------
            // Acquire Data

            /**
             * Requests the services currently configured on this music server
             * Either it requests them right away or it stores a deferred to request them when the connection
             * is ready.
             * @private
             */
            _requestServices() {
                if (this.socketIsOpen) {
                    this.channel.emit(this.component.ECEvent.SendMessage, {
                        cmd: MediaEnum.Commands.SERVICES.GET,
                        auto: true
                    });
                } else {
                    // create a deferred that resolves with this very method once the connection is ready.
                    this._pendingServicesRq = Q.defer();

                    this._pendingServicesRq.promise.done(this._requestServices.bind(this));
                }
            }

            /**
             * Requests the services that can still be added to the Music Server.
             * Either right away or it stores a deferred to request them when the connection is ready.
             * @private
             */
            _requestAvailableServices() {
                if (this.socketIsOpen) {
                    this.channel.emit(this.component.ECEvent.SendMessage, {
                        cmd: MediaEnum.Commands.SERVICES.GET_AVAILABLE,
                        auto: true
                    });
                } else {
                    // create a deferred that resolves with this very method once the connection is ready.
                    this._pendingAvServicesRq = Q.defer();

                    this._pendingAvServicesRq.promise.done(this._requestAvailableServices.bind(this));
                }
            }

            /**
             * Either requests the radios right away or it stores a deferred to request them when the connection
             * is ready.
             * @private
             */
            _requestRadios() {
                if (this.socketIsOpen) {
                    this.channel.emit(this.component.ECEvent.SendMessage, {
                        cmd: MediaEnum.Commands.RADIOS.GET,
                        auto: true
                    });
                } else {
                    // create a deferred that resolves with this very method once the connection is ready.
                    this._pendingRadiosRq = Q.defer();

                    this._pendingRadiosRq.promise.done(this._requestRadios.bind(this));
                }
            }

            // -------------------------------------------------------------------------
            // Handle Extension Channel Events
            _handleResult(result) {
                if (!result.hasOwnProperty("command")) {// nothing to do
                } else if (result.command === MediaEnum.Commands.SERVICES.GET) {
                    // get services result received
                    Debug.Media.ServiceHandler && console.log(this.name, "result received: " + JSON.stringify(result));
                    this.services = result.data;
                    this.services = this._sanitizeServices(this.services);

                    if (this.currentServicesDeferred) {
                        this.currentServicesDeferred.resolve(this.services);
                        this.currentServicesDeferred = null;
                    }
                } else if (result.command === MediaEnum.Commands.SERVICES.GET_AVAILABLE) {
                    // get services result received
                    Debug.Media.ServiceHandler && console.log(this.name, "result received: " + JSON.stringify(result));
                    this.availableServices = result.data; // just like regular services, also sanitize availableServices (result is in same format, so the
                    // method can be reused)

                    this.availableServices = this._sanitizeServices(this.availableServices);

                    if (this.availableServicesDeferred) {
                        this.availableServicesDeferred.resolve(this.availableServices);
                        this.availableServicesDeferred = null;
                    }
                } else if (result.command === MediaEnum.Commands.RADIOS.GET) {
                    // get services result received
                    Debug.Media.ServiceHandler && console.log(this.name, "result received: " + JSON.stringify(result));
                    this.radios = this._sanitizeRadios(result.data);

                    if (this.radiosDeferred) {
                        this.radiosDeferred.resolve(this.radios);
                        this.radiosDeferred = null;
                    }
                }
            }

            _handleResultError(error) {
                if (!error.hasOwnProperty("command")) {// nothing to do
                } else if (error.command === MediaEnum.Commands.SERVICES.GET) {
                    // get services result received
                    Debug.Media.ServiceHandler && console.log(this.name, "error received: " + JSON.stringify(result));
                    this.services = null;

                    if (this.currentServicesDeferred) {
                        this.currentServicesDeferred.reject();
                        this.currentServicesDeferred = null;
                    }
                } else if (error.command === MediaEnum.Commands.SERVICES.GET_AVAILABLE) {
                    // get services result received
                    Debug.Media.ServiceHandler && console.log(this.name, "error received: " + JSON.stringify(result));
                    this.availableServices = null;

                    if (this.availableServicesDeferred) {
                        this.availableServicesDeferred.reject();
                        this.availableServicesDeferred = null;
                    }
                } else if (error.command === MediaEnum.Commands.RADIOS.GET) {
                    // get services result received
                    Debug.Media.ServiceHandler && console.log(this.name, "error received: " + JSON.stringify(result));
                    this.radios = null;

                    if (this.radiosDeferred) {
                        this.radiosDeferred.reject();
                        this.radiosDeferred = null;
                    }
                }
            }

            _handleConnectionEstablished() {
                this.socketIsOpen = true; // Now that the socket is open, are there pending requests we need to process now?

                this._pendingServicesRq && this._pendingServicesRq.resolve();
                this._pendingRadiosRq && this._pendingRadiosRq.resolve();
                this._pendingAvServicesRq && this._pendingAvServicesRq.resolve();
                this._pendingServicesRq = null;
                this._pendingRadiosRq = null;
                this._pendingAvServicesRq = null;
            }

            _handleConnectionClosed() {
                this.services = null;
                this.availableServices = null;
                this.radios = null;
                this.socketIsOpen = false;
            }

            _sanitizeServices(services) {
                if (!services) {
                    return null;
                }

                for (var i = 0; i < services.length; i++) {
                    this._createUidForService(services[i]);

                    var curr = services[i];

                    switch (curr[MediaEnum.Attr.SERVICE.CMD]) {
                        case MediaEnum.Service.SPOTIFY:
                            curr[MediaEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/Icon-Spotify.svg";
                            curr[MediaEnum.Attr.SERVICE.HELPLINK] = MediaEnum.ServiceHelpLinks.SPOTIFY;
                            break;

                        case MediaEnum.Service.GOOGLE:
                            curr[MediaEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/Icon-Google-Play-Music.svg";
                            curr[MediaEnum.Attr.SERVICE.HELPLINK] = MediaEnum.ServiceHelpLinks.GOOGLE;
                            break;

                        case MediaEnum.Service.AMAZON:
                            curr[MediaEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/Icon-Amazon.svg";
                            break;

                        default:
                            break;
                    }

                    if (services[i].hasOwnProperty(MediaEnum.Attr.SERVICE.ICON)) {
                        delete services[i][MediaEnum.Attr.SERVICE.ICON_EXTERN];
                    }
                }

                return services;
            }

            _sanitizeRadios(radios) {
                if (!radios) {
                    return null;
                }

                for (var i = 0; i < radios.length; i++) {
                    this._createUidForService(radios[i]);

                    radios[i].type = MediaEnum.FileType.LOCAL_DIR;

                    if (!MediaServerComp.Feature.PRETTY_RADIO_ICONS) {
                        switch (radios[i][MediaEnum.Attr.SERVICE.CMD]) {
                            case MediaEnum.Radios.LOCAL:
                                radios[i][MediaEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/icon-radio-local.svg";
                                break;

                            case MediaEnum.Radios.WORLD:
                                radios[i][MediaEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/icon-radio-world.svg";
                                break;

                            case MediaEnum.Radios.MUSIC:
                                radios[i][MediaEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/icon-radio-genre.svg";
                                break;

                            case MediaEnum.Radios.NEWS:
                                radios[i][MediaEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/icon-radio-news.svg";
                                break;

                            case MediaEnum.Radios.PODCAST:
                                radios[i][MediaEnum.Attr.SERVICE.ICON] = "resources/Images/Controls/AudioZone/icon-radio-podcasts.svg";
                                break;

                            default:
                                break;
                        }
                    }

                    if (radios[i].hasOwnProperty(MediaEnum.Attr.SERVICE.ICON)) {
                        delete radios[i][MediaEnum.Attr.SERVICE.ICON_EXTERN];
                    }
                }

                return radios;
            }

            _createUidForService(service) {
                service[MediaEnum.Attr.SERVICE.UID] = service[MediaEnum.Attr.SERVICE.CMD];

                if (service.hasOwnProperty(MediaEnum.Attr.SERVICE.USER)) {
                    service[MediaEnum.Attr.SERVICE.UID] += "/" + service[MediaEnum.Attr.SERVICE.USER];
                } else {
                    service[MediaEnum.Attr.SERVICE.UID] += "/" + MediaEnum.NOUSER;
                }
            }

            _showServiceEditor(errorServiceEvent) {
                Debug.Media.ServiceHandler && console.log(this.name, "_showServiceEditor: " + JSON.stringify(errorServiceEvent));

                if (!this.services) {
                    // no services yet, download them first
                    Debug.Media.ServiceHandler && console.log("   no services yet, download them first");
                    this.getCurrentServices().done(function (services) {
                        Debug.Media.ServiceHandler && console.log("   services downloaded.");

                        var serviceObj = this._findService(services, errorServiceEvent[MediaEnum.Attr.SERVICE.CMD], errorServiceEvent[MediaEnum.Attr.SERVICE.USER]);

                        this._showEditorWithService(serviceObj);
                    }.bind(this));
                } else {
                    var service = this._findService(this.services, errorServiceEvent[MediaEnum.Attr.SERVICE.CMD], errorServiceEvent[MediaEnum.Attr.SERVICE.USER]);

                    this._showEditorWithService(service);
                }
            }

            _findService(services, cmd, user) {
                var service, i;

                for (i = 0; i < services.length; i++) {
                    if (services[i][MediaEnum.Attr.SERVICE.CMD] === cmd && services[i][MediaEnum.Attr.SERVICE.USER] === user) {
                        service = services[i];
                        break;
                    }
                }

                return service;
            }

            _showEditorWithService(service) {
                Debug.Media.ServiceHandler && console.log(this.name, "_showEditorWithService: " + JSON.stringify(service));
                var targetScreen = Controls.AudioZoneControl.ScreenState.SERVICE_LOGIN;
                var details = {
                    service: service
                };
                NavigationComp.showState(targetScreen, details);
            }

        }

        Components.MediaServer.extensions.ServiceHandlerExt = ServiceHandlerExt;
    }
    return Components;
}(window.Components || {});
