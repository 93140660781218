'use strict';

window.Components = function (Components) {
    class MiniserverZoneFavoriteLoaderExt extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.registerExtensionEv(this.component.ECEvent.ZoneChanged, this._handleZoneChanged.bind(this));
            this.registerExtensionEv(this.component.ECEvent.EventReceived, this._handleEventReceived.bind(this));
        }

        /**
         * Requests the favorites for the active zone.
         * @result Returns a promise that dispatches the results
         */
        requestContent() {
            Debug.Media.MiniserverLoader && console.log(this.name, "requestContent");
            var result = {};
            this.deferred = Q.defer();

            if (this.zoneFavoritesResult) {
                // set data, resolve promise later.
                result.data = this.prepareResult(this.zoneFavoritesResult);
                setTimeout(function () {
                    Debug.Media.MiniserverLoader && console.log("    dispatch result after requestContent with cached data");

                    if (this.deferred) {
                        // it may already have been resolved in the meantime (by incoming state updates)
                        this._dispatchResult(this.zoneFavoritesResult);
                    }
                }.bind(this), 50);
            } else {
                console.error(this.name, "No ZoneFavorites here right now, awaiting update...");
            }

            result.promise = this.deferred.promise;
            return result;
        }

        /**
         * Called whenever the cache needs to be invalidated (e.g. when the favorites where edited). Will inform
         * all registered listeners to reload their data.
         * @param id            the id of the element who's cache is to be invalidated.
         * @param reason  why where the contens invalidated? used for the reloadEvent
         * @param remove  should the view reload the content or should it remove itself from the screen.
         */
        invalidateContentCachesOf(id, reason, remove) {
            Debug.Media.MiniserverLoader && console.log(this.name, "invalidateContentCachesOf: " + id + " - " + reason);
            this.zoneFavoritesResult = null;

            this._dispatchReloadEvent(reason, remove);
        }

        /**
         * Stump - not necessary in this implementation
         */
        prefetchContent() {
        }

        /**
         * Stump - not necessary in this implementation
         */
        stopRequestFor() {
        }

        /**
         * Stump - not necessary in this implementation
         */
        rejectAndDeleteAllRequests() {
        }

        /**
         * Delegates that are registered by this method will receive an event once the mediaLoader updates
         * the contentOld, so the items dispatched earlier become invalid and the whole content needs to be reloaded.
         * @param delegate the method that will be called once a reload is neccessary
         * @returns {number}
         */
        registerForReloadEvent(delegate) {
            Debug.Media.MiniserverLoader && console.log(this.name, "registerForReloadEvent");

            if (!this.reloadDelegates) {
                this.reloadDelegates = {};
            }

            var regId = Object.keys(this.reloadDelegates).length;
            this.reloadDelegates[regId] = delegate;
            return regId;
        }

        /**
         * Removes the reloadDelegates registered via registerForReloadEvent
         * @param regId the regId which was returned by registerForReloadEvent
         */
        unregisterFromReloadEvent(regId) {
            Debug.Media.MiniserverLoader && console.log(this.name, "unregisterFromReloadEvent");

            if (this.reloadDelegates.hasOwnProperty(regId)) {
                delete this.reloadDelegates[regId];
            }
        }

        // don't insert empty slots
        prepareResult(result) {
            result = cloneObject(result);
            Debug.Media.MiniserverLoader && console.log(this.name, "prepareResult: " + JSON.stringify(result));

            for (var j = 0; j < result.items.length; j++) {
                var item = result.items[j];
                item.contentType = MediaEnum.MediaContentType.ZONE_FAVORITES;
                item[MediaEnum.Event.FILE_TYPE] = MediaEnum.FileType.ROOM_FAVORITE;
            } // sort by slot id


            result.items.sort(function compare(a, b) {
                if (a[MediaEnum.Event.SLOT] < b[MediaEnum.Event.SLOT]) {
                    return -1;
                }

                if (a[MediaEnum.Event.SLOT] > b[MediaEnum.Event.SLOT]) {
                    return 1;
                }

                return 0;
            });
            result.isFinished = result.totalitems === result.items.length;
            return result;
        }

        // -------------------------------------------------------------------------
        // PRIVATE
        // -------------------------------------------------------------------------

        /**
         * Only called with multi music server feature. Registers for state changes so that the sync info can
         * be received from the miniserver.
         * @param event         identifier for the event
         * @param zoneControl   either the zoneControl that has just been entered, or null if the zone was left.
         * @private
         */
        _handleZoneChanged(event, zoneControl) {
            Debug.Media.MiniserverLoader && console.log(this.name, "_handleZoneChanged: " + JSON.stringify(zoneControl));

            if (zoneControl) {
                this.zoneControl = zoneControl;
                this.zonesPlayerId = zoneControl.details.playerid;
                this.zoneUuid = zoneControl.uuidAction;
                this.zoneFavoritesResult = null;
                SandboxComponent.registerForStateChangesForUUID(this.zoneUuid, this, this._receivedZoneStates);
            } else {
                this.zonesPlayerId = null;
                SandboxComponent.unregisterForStateChangesForUUID(this.zoneUuid, this);
            }
        }

        /**
         * When a zone favorite has changed, we're notified using a roomfavchanged-event.
         * @param event
         * @param data      e.g. {"roomfavchanged_event":[{"playerid":4 }]}
         * @private
         */
        _handleEventReceived(event, data) {// Nothing to do here
        }

        /**
         * States that are received from the Miniserver for the current zone (this.zoneUuid)
         * @param states
         * @private
         */
        _receivedZoneStates(states) {
            Debug.Media.MiniserverLoader && console.log(this.name, "_receivedZoneStates: " + JSON.stringify(states)); // the UI expects a result object. don't use the zoneFavorites array, use the raw thing

            if (JSON.stringify(this.zoneFavoritesResult) !== JSON.stringify(states.zoneFavoritesResult)) {
                console.info(this.name, "Got zoneFav update!");
                this.zoneFavoritesResult = states.zoneFavoritesResult;

                if (this.deferred) {
                    this._dispatchResult(this.zoneFavoritesResult);
                } else {
                    this._dispatchReloadEvent(MediaEnum.ReloadCause.CONTENT_UPDATED);
                }
            }
        }

        /**
         * Dispatches an array of zone favorites-items.
         * @param result    the array of items.
         * @private
         */
        _dispatchResult(result) {
            Debug.Media.MiniserverLoader && console.log(this.name, "_dispatchResult: " + JSON.stringify(result));
            console.info(this.name, "Dispatching new ZoneFavs");
            this.deferred.resolve(this.prepareResult(result));
            this.deferred = null;
        }

        /**
         * Will inform all registered listeners that something occurred that will trigger a reload. Can also tell
         * the view to dismiss itself and all parent views of the same type.
         * @param reason    What did trigger the reload event?
         * @param remove    // don't bother reloading, remove the screen (e.g. Service Account removed)
         * @private
         */
        _dispatchReloadEvent(reason, remove) {
            Debug.Media.MiniserverLoader && console.log(this.name, "_dispatchReloadEvent: " + JSON.stringify(reason));

            if (this.reloadDelegates) {
                var registeredKeys = Object.keys(this.reloadDelegates);
                var i; // the rescanning events need to be dispatched from the end to the start, otherwise the currently
                // visible view receives it's reload event at last. (and might no longer be alive, e.g. Library)

                for (i = registeredKeys.length - 1; i >= 0; i--) {
                    try {
                        var delegateId = registeredKeys[i];

                        if (this.reloadDelegates.hasOwnProperty(delegateId)) {
                            this.reloadDelegates[delegateId](reason, remove); // calls the reload events.
                        } else {// view might already have been dismissed & unregistered!
                        }
                    } catch (exc) {
                        console.error("Error while dispatching the reloadEvents!");
                    }
                }
            }
        }

    }

    /**
     * Imitates a mediaLoader, but handles things completely different.
     */
    Components.MediaServer.extensions.MiniserverZoneFavoriteLoader = MiniserverZoneFavoriteLoaderExt;
    return Components;
}(window.Components || {});
