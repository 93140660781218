"use strict";

window.MediaStateHandler = {
    get Mixin() {
        return {
            _mediaRegistratons: {},
            __chunkQueueMap: {},
            EVENT_TYPES: MediaEnum.MediaContentType,
            DYNAMIC_FUNCTION_TPL: {
                ALL_DATA_RECEIVED: "received{eventType}AllDataEvent",
                CHUNKED_DATA_DATA: "received{eventType}ChunkEvent",
                RELOAD: "received{eventType}ReloadEvent",
                ERROR: "received{eventType}ErrorEvent"
            },

            /**
             * Register for MediaServerEvents
             * @note Takes an argument list of this.EVENT_TYPES
             * @private
             */
            _registerForMediaServerEvents: function _registerForMediaServerEvents() {
                this._registerForMediaServerReloadEvents.apply(this, arguments);

                this._requestEventContent.apply(this, arguments);
            },

            /**
             * Register for MediaServer Reload Events
             * @note Takes an argument list of this.EVENT_TYPES
             * @private
             */
            _registerForMediaServerReloadEvents: function _registerForMediaServerReloadEvents() {
                var eventTypes = Array.from(arguments);
                eventTypes.forEach(function (eventType) {
                    if (!this.__isRegisteredForMediaServerReloadEvent(eventType)) {
                        this._mediaRegistratons[eventType] = MediaServerComp.registerForReloadEvent(eventType, this._receivedReloadEvent.bind(this, eventType));
                    } else {// Don't register multiple times!
                    }
                }.bind(this));
            },

            /**
             * Unregister from MediaServerEvents
             * @note Takes any argument list of this.EVENT_TYPES. All registered events will be unregistered if no arguments are given
             * @private
             */
            _unregisterForMediaServerEvents: function _unregisterForMediaServerEvents() {
                var eventTypes = Array.from(arguments); // No eventTypes given, assume we should unregister from all events

                if (!eventTypes.length) {
                    eventTypes = Object.keys(this._mediaRegistratons);
                }

                eventTypes.forEach(function (eventType) {
                    if (this._mediaRegistratons && this._mediaRegistratons.hasOwnProperty(eventType)) {
                        MediaServerComp.unregisterFromReloadEvent(eventType, this._mediaRegistratons[eventType]);
                        delete this._mediaRegistratons[eventType];
                    }

                    if (this._mediaEventRequests && this._mediaEventRequests.hasOwnProperty(eventType)) {
                        this._mediaEventRequests[eventType].forEach(function (request) {
                            MediaServerComp.stopRequestFor(eventType, request, this.__getMediaInfo(eventType));
                            request = null;
                        }.bind(this));

                        delete this._mediaEventRequests[eventType];
                    }
                }.bind(this));
            },

            /**
             * A reload event has been received
             * @note This will trigger a reload of the event content
             * @param eventType
             * @param reason
             * @private
             */
            _receivedReloadEvent: function _receivedReloadEvent(eventType, reason) {
                var reloadPrms; // Don't call the function if the component is not registered for events (anymore)

                if (this.__isRegisteredForMediaServerReloadEvent(eventType)) {
                    reloadPrms = Q(this.__callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, this.DYNAMIC_FUNCTION_TPL.RELOAD, [reason])); // Special reason handling

                    if (reason !== MediaEnum.ReloadCause.USER_CHANGED) {
                        reloadPrms = reloadPrms.then(function () {
                            // Wait with requesting the content until we are allowed to!
                            return this._requestEventContent(eventType);
                        }.bind(this));
                    }
                } else {
                    reloadPrms = Q(true);
                }

                return reloadPrms;
            },

            /**
             * Loads the event content of the given events
             * @note Takes any argument list of this.EVENT_TYPES and will request its contents
             * @private
             */
            _requestEventContent: function _requestEventContent() {
                var eventTypes = Array.from(arguments),
                    mediaEventRequest;
                this._mediaEventRequests = this._mediaEventRequests || {};
                eventTypes.forEach(function (eventType) {
                    if (!this._mediaEventRequests.hasOwnProperty(eventType)) {
                        this._mediaEventRequests[eventType] = [];
                    }

                    mediaEventRequest = MediaServerComp.requestContent(eventType, this.__getMediaId(eventType), // always start with patch 0!
                        this.__getRequestPacketSizeForEventType(eventType), this.__getMediaInfo(eventType));

                    if (mediaEventRequest) {
                        if (mediaEventRequest.data) {
                            this.__onEventDataReceived(eventType, cloneObject(mediaEventRequest.data));
                        }

                        mediaEventRequest.promise.then(this.__onEventDataReceived.bind(this, eventType), this.__onEventErrorReceived.bind(this, eventType), this.__onEventDataChunkReceived.bind(this, eventType)).finally(function () {
                            if (this._mediaEventRequests.hasOwnProperty(eventType)) {
                                var prmsIdx = this._mediaEventRequests[eventType].indexOf(mediaEventRequest.promise);

                                if (prmsIdx !== -1) {
                                    this._mediaEventRequests[eventType].splice(prmsIdx, 1);
                                }
                            }
                        }.bind(this));

                        this._mediaEventRequests[eventType].push(mediaEventRequest.promise);
                    } else {
                        this.__onEventErrorReceived(eventType);
                    }
                }.bind(this));
            },

            /**
             * Returns a function name based on the eventType and template
             * @param eventType The Event Type the function name is for
             * @param template  The template which is used to build the function name
             * @note "{eventType}" will be replaced with the content of the argument eventType
             * @return {*|void|string}
             * @private
             */
            __getFunctionNameForEventTypeWithTemplate: function __getFunctionNameForEventTypeWithTemplate(eventType, template) {
                return template.replace("{eventType}", eventType);
            },

            /**
             * Yey, we got event data! Pass it on to the generic function depending on the event type
             * @note The data may come in chunks (depending on its length and "__getRequestPacketSizeForEventType"
             * @param eventType
             * @private
             */
            __onEventDataReceived: function __onEventDataReceived(eventType) {
                var data = Array.from(arguments).splice(1)[0];
                return Q(this.__onEventDataChunkReceived.apply(this, arguments) || true).then(function () {
                    if (data && data.isFinished) {
                        return this.__callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, this.DYNAMIC_FUNCTION_TPL.ALL_DATA_RECEIVED, [data]);
                    } else {
                        return Q(true);
                    }
                }.bind(this));
            },

            /**
             * Yey, we got event data! Pass it on to the generic function depending on the event type
             * @note The data may come in chunks (depending on its length and "__getRequestPacketSizeForEventType"
             * @param eventType
             * @private
             */
            __onEventDataChunkReceived: function __onEventDataChunkReceived(eventType) {
                var processChunk = function processChunk() {
                    var args = this.__chunkQueueMap[eventType].pop();

                    if (args) {
                        this.__chunkedPrms = this.__callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, this.DYNAMIC_FUNCTION_TPL.CHUNKED_DATA_DATA, Array.from(args).splice(1));
                    } else {
                        this.__chunkedPrms = null;
                    }
                }.bind(this);

                this.__chunkQueueMap[eventType] = this.__chunkQueueMap[eventType] || [];

                this.__chunkQueueMap[eventType].push(arguments);

                if (!this.__chunkedPrms) {
                    processChunk();
                }

                return Q(this.__chunkedPrms || true).then(function () {
                    if (this.__chunkQueueMap[eventType].length) {
                        processChunk();
                        return this.__chunkedPrms;
                    }
                }.bind(this));
            },

            /**
             * No! there was an error, just do what we did previously and just ignore it
             * @param eventType
             * @param [cause]
             * @private
             */
            __onEventErrorReceived: function __onEventErrorReceived(eventType, cause) {
                if (cause === MediaEnum.ReloadCause.STOPPED) {
                    console.info("Manually canceled an ongoing request! (id = '" + this.__getMediaId(eventType) + "')");
                } else {
                    console.warn("Error loading the event data for '" + eventType + "'");
                    return this.__callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, this.DYNAMIC_FUNCTION_TPL.ERROR, Array.from(arguments).splice(1));
                }
            },

            /**
             * Calls a generic function based on the eventType and the template with the given arguments
             * @note The template may look like this "myAwesome{eventType}Function" which results in the function "myAwesomeQueueFunction" if the eventType equals this.EVENT_TYPE.QUEUE
             * @param eventType
             * @param template
             * @param args
             * @private
             */
            __callDynamicEventTypeFunctionFromTemplateWithArgs: function __callDynamicEventTypeFunctionFromTemplateWithArgs(eventType, template, args) {
                var funcName = this.__getFunctionNameForEventTypeWithTemplate(eventType, template);

                if (typeof this[funcName] === "function") {
                    return this[funcName].apply(this, cloneObject(args));
                } else {
                    console.warn(this.name, "__callDynamicEventTypeFunctionFromTemplateWithArgs: '" + funcName + "' is not defined!");
                }
            },

            /**
             * Checks if the component is already registered for a given eventType
             * @param eventType
             * @return {boolean}
             * @private
             */
            __isRegisteredForMediaServerReloadEvent: function __isRegisteredForMediaServerReloadEvent(eventType) {
                return this._mediaRegistratons.hasOwnProperty(eventType);
            },

            /**
             * Defines the package size to be fetched from the MusicServer
             * @param eventType
             * @return {number}
             */
            __getRequestPacketSizeForEventType: function __getRequestPacketSizeForEventType(eventType) {
                if (MediaServerComp.Feature.IMPROVED_REQUESTS) {
                    return 50;
                } else {
                    if (eventType === this.EVENT_TYPES.SERVICE || eventType === this.EVENT_TYPES.QUEUE) {
                        return 5;
                    } else {
                        return 20;
                    }
                }
            },

            /**
             * The information which is given used by the specific MediaLoader
             * @return {*}
             */
            __getMediaInfo: function __getMediaInfo(eventType) {
                var mediaInfo = this.control.details;

                if (this.getMediaInfo) {
                    mediaInfo = this.getMediaInfo(eventType);
                }

                return mediaInfo;
            },

            /**
             * Returns the ID to be fetched 0 means start...
             * @returns {number}
             * @private
             */
            __getMediaId: function __getMediaId(eventType) {
                if (this.getMediaId) {
                    return this.getMediaId(eventType);
                } else if (eventType === this.EVENT_TYPES.QUEUE && this.control) {
                    return this.control.details.playerid;
                } else {
                    return 0;
                }
            }
        };
    }

};
