'use strict';

window.Components = function (Components) {
    class ServiceLoader extends Components.MediaServer.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.registerExtensionEv(this.component.ECEvent.ServiceChanged, this._handleServiceChanged.bind(this));
            this.noPrefetchMap = {};
        }

        getAcquireCommand() {
            if (!this.service) {
                return false;
            } else {
                return 'getservicefolder/' + this.service[MediaEnum.Attr.SERVICE.UID];
            }
        }

        _processResult(data, cmd) {
            Debug.Media.Loader && console.log(this.name + ": Processing Result: " + JSON.stringify(data));

            super._processResult(...arguments);
        }

        requestContent(identifier, packageSize, mediaTypeDetails) {
            if (packageSize > 50) {
                console.warn(this.name, "Loading portions greater than 50 is not a good idea. Loading content from " + "external services is quite cumbersome and takes time.");
            } // Always invalidate any remaining cache, We need to ask the musicserver for any new content, or the user won't
            // see any updates unless he closes the AudioZone


            this._invalidateCacheFor(identifier);

            this._updateServiceType(mediaTypeDetails);

            return super.requestContent(...arguments);
        }

        prefetchContent(identifier, packageSize, mediaTypeDetails) {
            Debug.Media.Loader && console.log(this.name + ": prefetchContent first " + packageSize + " of " + identifier);

            if (this.avoidPrefetching(identifier)) {
                Debug.Media.Loader && console.log("    prefetching for " + identifier + " not allowed");
                return;
            }

            this._updateServiceType(mediaTypeDetails);

            return super.prefetchContent(...arguments);
        }

        /**
         * Used to intercept prefetching. E.g. the playlist folder inside the getservicefolder result mustn't
         * be prefetched as it will be loaded using a different command later.
         * @param id            the id of the container in question
         * @returns {boolean}   if is to be avoided (true) of if it is allowed (false).
         */
        avoidPrefetching(id) {
            return this.noPrefetchMap.hasOwnProperty(id);
        }

        _updateServiceType(mediaTypeDetails) {
            if (mediaTypeDetails && mediaTypeDetails.hasOwnProperty(MediaEnum.Attr.SERVICE._)) {
                var newService = mediaTypeDetails[MediaEnum.Attr.SERVICE._];

                if (!this.service) {
                    Debug.Media.Loader && console.log(this.name + ": No service so far, nothing to do");
                } else if (this.service[MediaEnum.Attr.SERVICE.UID] !== newService[MediaEnum.Attr.SERVICE.UID]) {
                    Debug.Media.Loader && console.log(this.name + ": The service has changed from " + this.service ? this.service[MediaEnum.Attr.SERVICE.UID] : "no service" + "" + " to " + newService[MediaEnum.Attr.SERVICE.UID] + " - resetting the cache"); // the service did change, respond by invalidating all the cache

                    this.cache = {}; // reset the no prefetchmap

                    this.noPrefetchMap = {};
                }

                this.service = newService;
            }
        }

        /**
         * Overwrite if the result needs to be adopted before dispatching (used in serviceLoaderExt)
         * @param result the result to adopt
         * @param finished whether or not this is the final result
         * @returns {*} the adopted result
         */
        prepareResult(result, finished) {
            // to ensure the items names are decoded, pass on to the base class
            result = super.prepareResult(...arguments);

            for (var i = 0; i < result.items.length; i++) {
                var item = result.items[i]; // no prefetching e.g. for playlists, as a different loader is in charge for them.

                if (item.hasOwnProperty("contentType")) {
                    this.noPrefetchMap[item[MediaEnum.Event.ID]] = true;
                }
            }

            return result;
        }

        _handleServiceChanged(id, event) {
            if (this.service && this.service[MediaEnum.Attr.SERVICE.UID] === event[MediaEnum.Attr.SERVICE.UID]) {
                // whoops, the current service is affected.
                var reason = event.action; //user add, del, update

                this.rejectAndDeleteAllRequests(reason);
                this.invalidateContentCachesOf("start", reason, true);
            }
        }

    }

    Components.MediaServer.extensions.ServiceLoader = ServiceLoader;
    return Components;
}(window.Components || {});
