'use strict';

window.Components = function (Components) {
    var resetApi = {
        apiVersion: [0, 0],
        firmwareVersion: "0.0.0.0"
    };

    function MediaServerComponent() {
        this.extensions = {};
        this.informedAboutApiMissmatch = null;
        this.apiMissmatch = false;
        this.audioViewsVisible = false;
        this.taskRecorderActive = false;
        this.activeZoneControl = null;
        this.Feature.update(resetApi); // initializes this component as extension-channel

        Observer.call(this); // Register for Component-Channel Events

        CompChannel.on(CCEvent.ConnClosed, this._handleStopMSSession.bind(this));
        CompChannel.on(CCEvent.Pause, this._handleStopMSSession.bind(this));
        CompChannel.on(CCEvent.StructureReady, this._handleStructureReady.bind(this));
        CompChannel.on(CCEvent.StopMSSession, this._handleStopMSSession.bind(this));
        CompChannel.on(CCEvent.TaskRecorderStart, this._handleTaskRecorderStart.bind(this));
        CompChannel.on(CCEvent.TaskRecorderEnd, this._handleTaskRecorderEnd.bind(this)); // Also register for extension-channel events

        this.on(this.ECEvent.ResultReceived, this._handleResult.bind(this));
        this.on(this.ECEvent.ServerInfoReceived, this._handleServerInfoReceived.bind(this));
        this.on(this.ECEvent.FeatureCheckingReady, this._handleFeatureCheckingReady.bind(this));
        this.on(this.ECEvent.ConnEstablished, this._handleConnEstablished.bind(this));
        this.on(this.ECEvent.ConnClosed, this._handleConnClosed.bind(this));
    }

    /**
     * Used to START the MediaServerComponent. it'll check if there is a MediaServer ready, instantiate the extensions
     * and then post a start-event on the ext-channel.
     * @private
     */


    MediaServerComponent.prototype._handleStructureReady = function _handleStructureReady() {
        Debug.Media.Component && console.log("MediaServerComp", "CCEvent.StructureReady");
        this.emit(this.ECEvent.VerifyConnectivity); // TODO-woessto: remove workaround https://www.wrike.com/open.htm?id=91947923

        if (this.getActiveMediaServer()) {
            this._checkExtensions();

            this.emit(this.ECEvent.Start);
        } else {
            this._destroyExtensions();
        }
    };
    /**
     * This is called when the comp-channel receives a stopMSSession event.
     * Used to tear down the whole Media Server-Component. it'll reset the feature checking, reset all caches via the
     * resetMediaApp-Event and post a stop onto the ext-channel.
     * @private
     */


    MediaServerComponent.prototype._handleStopMSSession = function _handleStopMSSession() {
        Debug.Media.Component && console.log("MediaServerComp", "CCEvent.StopMSSession");
        this.audioViewsVisible = false;

        if (this.getActiveMediaServer()) {
            // the connection needs to be stopped as soon as any form of conn is active.
            this.emit(this.ECEvent.ResetMediaApp, {
                stopped: true
            }); // invalidate all caches, but don't reload!

            this.emit(this.ECEvent.Stop);
        }
    };
    /**
     * Update the attribute & emit the corresponding ext-channel event.
     * @private
     */


    MediaServerComponent.prototype._handleTaskRecorderStart = function _handleTaskRecorderStart() {
        this.taskRecorderActive = true;
        this.emit(MediaServerComp.ECEvent.TaskRecorderStart);
    };
    /**
     * Update the attribute & emit the corresponding ext-channel event.
     * @private
     */


    MediaServerComponent.prototype._handleTaskRecorderEnd = function _handleTaskRecorderEnd() {
        this.taskRecorderActive = false;
        this.emit(MediaServerComp.ECEvent.TaskRecorderEnd);
    };
    /**
     * Called when the server info was received either by the music-server-proxy or the media-socket.
     * It'll update the Feature-Checking and post the feature-checking-ready event on the ext-channel.
     * @private
     */


    MediaServerComponent.prototype._handleServerInfoReceived = function _handleServerInfoReceived(evId, result) {
        this.serverInfo = result;
        this.Feature.update(this.serverInfo);

        this._handleFeatureCheckingReady(); // Notify the Extensions about this


        this.emit(this.ECEvent.FeatureCheckingReady);
    };
    /**
     * Respond to the feature-checking-ready event on the ext-channel. Some extensions are only used when a certain
     * feature is available.
     * @private
     */


    MediaServerComponent.prototype._handleFeatureCheckingReady = function _handleFeatureCheckingReady() {
        if (this.Feature.PAGED_QUEUE) {
            // pass in potentially stored reload delegates, as a view presenting the queue might currently be visible.
            this.extensions.queueLoaderExt = initExtension(Components.MediaServer.extensions.QueueLoader, this, this.queueReloadDelegates);
        }

        if (this.Feature.NEW_PLAYLISTS) {
            this.extensions.playlistsExt = initExtension(Components.MediaServer.extensions.PlaylistLoader, this);
        } else {
            this.extensions.playlistsExt = initExtension(Components.MediaServer.extensions.OldPlaylistLoader, this);
        }

        if (this.Feature.V2_FIRMWARE) {
            this.extensions.searchExt = initExtension(Components.MediaServer.extensions.GlobalSearchExt, this);
        } else {
            this.extensions.searchExt = initExtension(Components.MediaServer.extensions.LegacySearchExt, this);
        }
    };
    /**
     * Once the ext-channel event connEstablished is called, a number of commands are sent to gather information
     * on the Media-Server on the other side of the connection.
     * @private
     */


    MediaServerComponent.prototype._handleConnEstablished = function _handleConnEstablished() {
        this.emit(this.ECEvent.SendMessage, {
            cmd: MediaEnum.Commands.GETMAC,
            auto: true
        });
        this.sendMediaServerCommand(MediaEnum.Commands.GETKEY, true).done(function (result) {
            this.publicKey = result.data[0].pubkey; // from a certain point on, music servers provide an exponent - check if this one does

            if (result.data[0].hasOwnProperty('exp')) {
                // yes it does, it transfers the exp as integer, so we need to convert it to a base 16 string.
                this.exp = Math.abs(result.data[0].exp).toString(16);
            } else {
                // not provided, assume the default exponent 65537
                this.exp = "10001";
            }

            Debug.Media.Component && console.log("Public key received: " + this.publicKey + ", exp: " + this.exp);
        }.bind(this));
    };
    /**
     * Only to be used if th MEDIA connection is closed, not the Miniservers.
     * Once a connection is closed, the queueLoader extension is to be destroyed.
     * @private
     */


    MediaServerComponent.prototype._handleConnClosed = function _handleConnClosed() {
        // destroy the queue loader ext as it will be re-initialized once the new feature checking is ready.
        if (this.extensions.queueLoaderExt) {
            // store the reload delegates for later, e.g. when the queue is currently being viewed, while the conn drops.
            this.queueReloadDelegates = this.extensions.queueLoaderExt.getReloadDelegates();
            this.extensions.queueLoaderExt.destroy();
            delete this.extensions.queueLoaderExt;
        } // Dispatch stopped - otherwise e.g. the media listing won't reload a potentially updated content on the RESUMED event


        this.emit(this.ECEvent.ResetMediaApp, {
            stopped: true
        }); // invalidate all caches, but don't reload!
    };
    /**
     * Since the MediaServerComp itself uses it's extensions to communicate with the MediaServer, it listens to the
     * result-received event and processes it.
     * @param evId      static "ResultReceived"
     * @param result    the result received on the socket / proxy.
     * @private
     */


    MediaServerComponent.prototype._handleResult = function _handleResult(evId, result) {
        if (result.oldCommand === "mac") {
            var rawMac = result.data[0].macaddress;
            var find = ':';
            var re = new RegExp(find, 'g');
            this.macAddress = rawMac.replace(re, "").toUpperCase();
            return;
        }

        if (!this.commands || !this.commands.hasOwnProperty(result.command)) {
            Debug.Media.Component && console.log("MediaServerComp doesn't handle the result: " + JSON.stringify(result));
            return;
        }

        Debug.Media.Component && console.log("result received! " + result.command);
        var deferreds = this.commands[result.command];

        for (var i = 0; i < deferreds.length; i++) {
            try {
                deferreds[i].resolve(result);
            } catch (exc) {
                deferreds[i].reject("Error while resolving");
            }
        }

        delete this.commands[result.command];
    };
    /**
     * Extensions are being torn down and set right back up when a MS-Session is started. Calling it again without
     * destroying the extensions before does not have any affect.
     * @private
     */


    MediaServerComponent.prototype._checkExtensions = function _checkExtensions() {
        if (Object.keys(this.extensions).length > 0) {
            return;
        }

        Debug.Media.Component && console.log("MediaServerComp: _checkExtensions");
        this.extensions.centralCommunicationNode = initExtension(Components.MediaServer.extensions.CentralCommunicationNode, this);
        this.extensions.mediaMessageParserExt = initExtension(Components.MediaServer.extensions.MediaMessageParser, this);
        this.extensions.audioZoneExt = initExtension(Components.MediaServer.extensions.AudioZone, this);

        if (Feature.MULTI_MUSIC_SERVER) {
            this.extensions.zoneFavoritesExt = initExtension(Components.MediaServer.extensions.MiniserverZoneFavoriteLoader, this);
        } else {
            this.extensions.zoneFavoritesExt = initExtension(Components.MediaServer.extensions.ZoneFavoriteLoader, this);
        }

        this.extensions.zoneGroupExt = initExtension(Components.MediaServer.extensions.ZoneGroup, this);
        this.extensions.voiceRecorderExt = initExtension(Components.MediaServer.extensions.VoiceRecorderExt, this);
        this.extensions.popupExt = initExtension(Components.MediaServer.extensions.PopupExt, this);
        this.extensions.dialogExt = initExtension(Components.MediaServer.extensions.DialogExt, this);
        this.extensions.serviceHandlerExt = initExtension(Components.MediaServer.extensions.ServiceHandlerExt, this);
        this.extensions.inputExt = initExtension(Components.MediaServer.extensions.InputExt, this);
        this.extensions.inputLoaderExt = initExtension(Components.MediaServer.extensions.InputLoader, this);
        this.extensions.zoneFavExt = initExtension(Components.MediaServer.extensions.ZoneFavoriteExt, this);
        this.extensions.libraryExt = initExtension(Components.MediaServer.extensions.LibraryLoader, this);
        this.extensions.favoritesExt = initExtension(Components.MediaServer.extensions.FavoriteLoader, this); // queueLoaderExt && playlistLoaderExt will be initialized once the feature checking is ready.

        this.servicesExt = initExtension(Components.MediaServer.extensions.ServiceLoader, this);
        this.extensions.searchDetailExt = initExtension(Components.MediaServer.extensions.SearchDetailLoader, this);
        this.extensions.audioZoneSettingsExt = initExtension(Components.MediaServer.extensions.AudioZoneSettings, this);
    };
    /**
     * Will destroy all extensions, ensures nothing is happening while communicating/changeing to a miniserver without
     * a MediaServer in its config.
     * @private
     */


    MediaServerComponent.prototype._destroyExtensions = function _destroyExtensions() {
        Debug.Media.Component && console.log("_destroyExtensions");
        Object.keys(this.extensions).forEach(function (key) {
            var extension = this.extensions[key];

            try {
                extension.destroy();
            } catch (ex) {
                console.error("Could not destroy the MediaServerComp-Extension '" + key + "'");
                console.error(ex);
            }
        }.bind(this));
        this.extensions = {};
    }; // ---------------------------------------------------------------------------
    //                    Public Methods
    // ---------------------------------------------------------------------------

    /**
     *
     * @returns {boolean}
     */


    MediaServerComponent.prototype.sendCommandToServer = function sendCommandToServer(control, cmd, sendToMusicServer) {
        if (typeof sendToMusicServer !== 'boolean') {
            sendToMusicServer = false;
        }

        if (sendToMusicServer) {
            return MediaServerComp.sendAudioZoneCommand(control.details.playerid, {
                cmd: cmd
            });
        } else {
            return SandboxComponent.sendCommand(this, control.uuidAction, cmd, null, control.isSecured, null, false);
        }
    };
    /**
     * Returns weather or not the MediaServerComp is currently active (= communication runs through it). This is not the case
     * as long as no audioZone is active and multi music server is in use. Otherwise it's always true;
     * @returns {*}
     */


    MediaServerComponent.prototype.isActive = function isActive() {
        var result = true;

        if (Feature.MULTI_MUSIC_SERVER) {
            result = this.activeZoneControl !== null;
        }

        return result;
    };
    /**
     * Returns the MediaServerObject that corresponds to the active zone.
     * @returns {*}
     */


    MediaServerComponent.prototype.getActiveMediaServer = function getActiveMediaServer() {
        var server = null;

        if (Feature.MULTI_MUSIC_SERVER) {
            server = this.activeZoneControl ? this.getMediaServer(this.activeZoneControl.details.server) : null;
        } else {
            server = this.getMediaServer(null);
        }

        return server;
    };
    /**
     * Looks up a certain Music Server from the structure manager.
     * @param svrUuid   the uuid of the server we're looking for.
     * @returns {*}
     */


    MediaServerComponent.prototype.getMediaServer = function getMediaServer(svrUuid) {
        var wantedServer = null;

        try {
            var servers = ActiveMSComponent.getStructureManager().getMediaServerSet();

            if (servers != null) {
                var keys = Object.keys(servers);

                for (var i = 0; i < keys.length; i++) {
                    var candidate = servers[keys[i]];

                    if (candidate.type === MediaEnum.ServerType.MusicServer && (!svrUuid || svrUuid === candidate.uuidAction)) {
                        wantedServer = candidate;
                        break; // we've found him, lets get outta here.
                    }
                }
            }
        } catch (e) {
            console.error("Error while looking for the Loxone Multimedia Server!");
            console.error(e.stack);
        }

        return wantedServer;
    };
    /**
     * Returns the name of a media server. Either the active one or specified by the svrUuid
     * @param svrUuid   optional - the uuid of the server, default active server.
     * @returns {*}
     */


    MediaServerComponent.prototype.getServerName = function getServerName(svrUuid) {
        var mediaServ;

        if (svrUuid) {
            mediaServ = this.getMediaServer(svrUuid);
        } else {
            mediaServ = this.getActiveMediaServer();
        }

        if (!mediaServ) {
            console.error("Won't show proper server name for server with uuid: " + svrUuid);
        }

        return mediaServ ? mediaServ.name : _("media.server");
    };

    MediaServerComponent.prototype.getServerInfo = function getServerInfo() {
        return this.serverInfo;
    };

    MediaServerComponent.prototype.getServerFirmwareVersion = function getServerFirmwareVersion() {
        return this.serverInfo.firmwareVersion;
    };

    MediaServerComponent.prototype.getServerSerialNumber = function getServerSerialNumber() {
        return this.macAddress;
    };

    MediaServerComponent.prototype.getActiveZoneControl = function getActiveZoneControl() {
        return this.activeZoneControl;
    };

    MediaServerComponent.prototype.playFileUrl = function playFileUrl(rowContent) {
        var cmdObj = {
                cmd: MediaEnum.AudioCommands.SEARCH.PLAY + rowContent[MediaEnum.Event.AUDIO_PATH],
                argumentTexts: []
            },
            currentZone = MediaServerComp.getActiveZoneControl();
        MediaServerComp.sendAudioZoneCommand(currentZone.details.playerid, cmdObj);
    };
    /**
     * Returns the ip of a media server. Either the active one or specified by the svrUuid
     * @param svrUuid   optional - the uuid of the server, default active server.
     * @returns {*}
     */


    MediaServerComponent.prototype.getServerIp = function getServerIp(svrUuid) {
        var mediaServ;

        if (svrUuid) {
            mediaServ = this.getMediaServer(svrUuid);
        } else {
            mediaServ = this.getActiveMediaServer();
        }

        var host = mediaServ.host;
        return host.split(":")[0];
    };
    /**
     * Returns an rsa-encrypted cipher of the payload provided. The public key of the Media-Server is used
     * to encrypt the payload. This way only the Media-Server is capable of decrypting it.
     * @param payload
     * @returns {*}
     */


    MediaServerComponent.prototype.encryptWithPublicKey = function encryptWithPublicKey(payload) {
        var encrypt = new JSEncrypt();
        encrypt.getKey().setPublic(this.publicKey, this.exp);
        return encrypt.encrypt(payload);
    };

    MediaServerComponent.prototype.getServerStateStr = function getServerStateStr(serverState) {
        var serverStateStr = "--undefined--";

        switch (serverState) {
            case MediaEnum.ServerState.ONLINE:
                serverStateStr = "Online";
                break;

            case MediaEnum.ServerState.OFFLINE:
                serverStateStr = "Offline";
                break;

            case MediaEnum.ServerState.INITIALIZING:
                serverStateStr = "Initializing";
                break;

            case MediaEnum.ServerState.INVALID_ZONE:
                serverStateStr = "Invalid Zone";
                break;

            case MediaEnum.ServerState.NOT_REACHABLE:
                serverStateStr = "Not Reachable";
                break;

            default:
                serverStateStr = "-unknown- " + states.serverState;
                break;
        }

        return serverStateStr;
    };
    /**
     * the MediaServerComp needs to know whether or not any (fullscreen) audio-views are visible, e.g. for the popupExt
     * or when an app-reload event is received.
     * @param visible       Whether or not there are any audioViews visible
     * @param [zoneControl] the zone that was opened
     * @param [viewCtrl]    The viewController presenting the audio-views, only provided if visible is true.
     */


    MediaServerComponent.prototype.setAudioViewsVisible = function setAudioViewsVisible(visible, zoneControl, viewCtrl) {
        Debug.Media.Component && console.log("MediaServerComp setAudioViewsVisible: " + JSON.stringify(visible));

        if (Feature.MULTI_MUSIC_SERVER) {
            this._handleAudioViewsVisibleMulti(visible, zoneControl, viewCtrl);
        } else {
            // only emit a screen loaded event, if the value changed from false to true.
            var emit = this.audioViewsVisible !== visible && visible;
            var zoneChanged = this.audioViewsVisible !== visible || JSON.stringify(this.activeZoneControl) !== JSON.stringify(zoneControl);
            this.audioViewsVisible = visible;

            if (emit) {
                this.emit(this.ECEvent.InitialAudioScreenLoaded);
            }

            if (visible) {
                this.AudioViewController = viewCtrl;
            } else {
                this.AudioViewController = null;
            }

            if (zoneChanged) {
                // store the active zone control (e.g. for determining the active music server).
                this.activeZoneControl = zoneControl;
                this.emit(this.ECEvent.ZoneChanged, this.activeZoneControl);
            }
        }

        if (!visible) {
            this.serverInfo = resetApi;
        }

        CompChannel.emit(CCEvent.MUSIC_ZONE_CHANGED, zoneControl.details.server);
    };
    /**
     * When Multiple Music Servers are supported, the connection handling will depend on setAudioViewsVisible.
     * @param visible       Whether or not there are any audioViews visible
     * @param [zoneControl] the zone that was opened
     * @param [viewCtrl]    The viewController presenting the audio-views, only provided if visible is true.
     */


    MediaServerComponent.prototype._handleAudioViewsVisibleMulti = function _handleAudioViewsVisibleMulti(visible, zoneControl, viewCtrl) {
        this.audioViewsVisible = visible;

        if (visible) {
            this.activeZoneControl = zoneControl;
            this.AudioViewController = viewCtrl;

            this._checkExtensions();

            this.emit(this.ECEvent.InitialAudioScreenLoaded);
            this.emit(this.ECEvent.ZoneChanged, zoneControl);
            this.emit(this.ECEvent.Start);
        } else {
            this.emit(this.ECEvent.ResetMediaApp); // invalidate all caches

            this.emit(this.ECEvent.Stop);
            this.Feature.update(resetApi);

            this._destroyExtensions();

            this.AudioViewController = null;
            this.activeZoneControl = null;
        }
    };
    /**
     * Gives info on if there are any audioViews visible at the moment.
     * @returns {boolean|*}
     */


    MediaServerComponent.prototype.getAudioViewsVisible = function getAudioViewsVisible() {
        return this.audioViewsVisible;
    };
    /**
     * Returns whether or not the MediaServer-Communication is restricted. When the communication is routed
     * via the Miniserver, there aren't all features available. This is done when connected externally.
     * @returns {boolean} whether or not the functionality is restricted
     */


    MediaServerComponent.prototype.isRestricted = function isRestricted() {
        var isRestricted = true;

        if (Feature.MULTI_MUSIC_SERVER) {
            isRestricted = CommunicationComponent.getCurrentReachMode() === ReachMode.REMOTE;
        } else if (this.extensions.centralCommunicationNode) {
            isRestricted = this.extensions.centralCommunicationNode.isRestricted.apply(this.extensions.centralCommunicationNode, arguments);
        }

        return isRestricted;
    };
    /**
     * Shows a popup informing the user that certain features can only be controlled locally.
     */


    MediaServerComponent.prototype.showRestrictedPopup = function showRestrictedPopup() {
        var content = {
            title: _("miniserver.connected.external"),
            message: _("media.remote-not-available.text"),
            buttonOk: true,
            icon: Icon.INFO
        };
        NavigationComp.showPopup(content);
    };
    /**
     * Sends a command to the MediaServer, does not prequel "audio/2" or alike.
     * @param command       the command to send
     * @param background    if a command is sent in the background, it'll not be minded by the task-recorder.
     * @returns {deferred.promise|{then, catch, finally}}
     */


    MediaServerComponent.prototype.sendMediaServerCommand = function sendMediaServerCommand(command, background) {
        var deferred = Q.defer();
        this.emit(this.ECEvent.SendMessage, {
            cmd: command,
            auto: !!background
        });

        if (!this.commands) {
            this.commands = {};
        }

        if (!this.commands[command]) {
            this.commands[command] = [];
        }

        this.commands[command].push(deferred);
        return deferred.promise;
    };
    /**
     * Sends an http request to the mediaServer of the zone
     * @param control       the control object representing the zone
     * @param cmd           the command to send, it will be prequeled by "audio/PLAYERID/"
     * @param [background]  whether or not this command is to be sent via background (important for task-recording).
     * @returns {deferred.promise|{then, catch, finally}}
     */


    MediaServerComponent.prototype.sendHttpPlayerCommand = function sendHttpPlayerCommand(control, cmd, background) {
        var deferred = Q.defer();

        if (!this.taskRecorderActive) {
            // first get the targeted mediaServer
            var server = ActiveMSComponent.getStructureManager().getMediaServerSet()[control.details.server]; // then send the command

            var fullCmd = "http://" + server.host + "/audio/" + control.details.playerid + "/" + cmd;
            $.ajax({
                url: fullCmd,
                context: this,
                success: function () {
                    deferred.resolve();
                },
                error: function (err) {
                    console.error("Error: cmd: " + cmd);
                    console.error(err);
                    deferred.reject();
                },
                crossDomain: true
            });
        } else {
            // while task-recording is active, use the sandbox, it'll take care of the task recording.
            SandboxComponent.sendCommand(this, control.uuidAction, cmd, null, control.isSecured, null, background);
            setTimeout(function () {
                deferred.resolve();
            }, 1);
        }

        return deferred.promise;
    };

    MediaServerComponent.prototype.isFileTypeBrowsable = function isFileTypeBrowsable(fileType) {
        var result = false;

        switch (fileType) {
            case MediaEnum.FileType.LOCAL_DIR:
                result = true;
                break;

            default:
                result = this.isFileTypePlaylist(fileType);
                break;
        }

        return result;
    };

    MediaServerComponent.prototype.isFileTypePlaylist = function isFileTypePlaylist(fileType) {
        var result = false;

        switch (fileType) {
            case MediaEnum.FileType.PLAYLIST_BROWSABLE:
            case MediaEnum.FileType.PLAYLIST_EDITABLE:
            case MediaEnum.FileType.FOLLOWED_PLAYLIST:
                result = true;
                break;

            default:
                result = false;
                break;
        }

        return result;
    };

    MediaServerComponent.prototype.isFileTypePlayable = function isFileTypePlayable(fileType, contentType) {
        var result = false;

        switch (fileType) {
            case MediaEnum.FileType.LOCAL_PLAYLIST:
            case MediaEnum.FileType.PLAYLIST_BROWSABLE:
            case MediaEnum.FileType.LOCAL_FILE:
            case MediaEnum.FileType.PLAYLIST_EDITABLE:
            case MediaEnum.FileType.FOLLOWED_PLAYLIST:
                result = true;
                break;

            case MediaEnum.FileType.LOCAL_DIR:
                result = contentType === MediaEnum.MediaContentType.LIBRARY;
                break;

            default:
                // some contentTypes are playable regardless of their fileType.
                result = contentType === MediaEnum.MediaContentType.FAVORITES;
                result = result || contentType === MediaEnum.MediaContentType.ZONE_FAVORITES; // if something is playing right now, it has to be playable

                result = result || contentType === MediaEnum.MediaContentType.PLAYING;
                break;
        }

        return result;
    };

    MediaServerComponent.prototype.isFileTypeEditable = function isFileTypeEditable(fileType) {
        var result;

        switch (fileType) {
            case MediaEnum.FileType.PLAYLIST_EDITABLE:
                result = true;
                break;

            default:
                result = false;
                break;
        }

        return result;
    };

    MediaServerComponent.prototype.isFileTypeDeletable = function isFileTypeDeletable(fileType, contentType) {
        var result;

        switch (fileType) {
            case MediaEnum.FileType.PLAYLIST_EDITABLE:
                result = true;
                break;

            default:
                result = false;
                break;
        }

        return result;
    };

    MediaServerComponent.prototype.isHandledFileType = function isHandledFileType(fileType) {
        var result = false;

        switch (fileType) {
            case MediaEnum.FileType.LOCAL_PLAYLIST:
            case MediaEnum.FileType.LOCAL_FILE:
            case MediaEnum.FileType.LOCAL_DIR:
            case MediaEnum.FileType.HW_INPUT:
            case MediaEnum.FileType.FAVORITE:
            case MediaEnum.FileType.ROOM_FAVORITE:
            case MediaEnum.FileType.PLAYLIST_BROWSABLE:
            case MediaEnum.FileType.PLAYLIST_EDITABLE:
            case MediaEnum.FileType.FOLLOWED_PLAYLIST:
                result = true;
                break;

            case MediaEnum.FileType.SEARCH:
            case MediaEnum.FileType.FUNCTION:
            case MediaEnum.FileType.TEXT:
                result = false;
                break;

            default:
                result = false;
                break;
        }

        return result;
    };
    /**
     * Returns the play command for an item. Every item can be played in 4 different ways, specified by the PlayType-Enum.
     * @param item              the item to play
     * @param playType          "how" to play it (now, next, ..)
     * @param contentType       where the item is from
     * @param mediaTypeDetails  service information of the item.
     * @returns {string}        the fully qualified command for playing an item.
     */


    MediaServerComponent.prototype.getPlayCommandForItem = function getPlayCommandForItem(item, playType, contentType, mediaTypeDetails) {
        var cmd = null;

        switch (contentType) {
            case MediaEnum.MediaContentType.LIBRARY:
                cmd = this._getLibraryPlayCommandForItem(item, playType);
                break;

            case MediaEnum.MediaContentType.PLAYLISTS:
                cmd = this._getPlaylistPlayCommandForItem(item, playType, mediaTypeDetails);
                break;

            case MediaEnum.MediaContentType.SERVICE:
                cmd = this._getServicePlayCommandForItem(item, playType, mediaTypeDetails);
                break;

            case MediaEnum.MediaContentType.SEARCH:
                cmd = this._getSearchPlayCommandForItem(item, playType);
                break;

            case MediaEnum.MediaContentType.FAVORITES:
                cmd = this._getFavoritePlayCommandForItem(item, playType);
                break;

            case MediaEnum.MediaContentType.ZONE_FAVORITES:
                cmd = this._getZoneFavoritePlayCommandForItem(item, playType);
                break;

            case MediaEnum.MediaContentType.QUEUE:
                cmd = this._getQueuePlayCommand(item, playType);
                break;

            case MediaEnum.MediaContentType.LINEIN:
                cmd = this._getInputPlayCommand(item, playType);
                break;

            case MediaEnum.MediaContentType.PLAYING:
                cmd = null; // it is playing right now - what do we want?

                break;

            default:
                throw new Error("Could not acquire a play command for the contentType " + contentType);
        }

        return cmd;
    };

    MediaServerComponent.prototype._getSearchPlayCommandForItem = function _getSearchPlayCommandForItem(item, playType) {
        var cmd = null;

        switch (playType) {
            case MediaEnum.PlayType.NOW:
                cmd = MediaEnum.AudioCommands.SEARCH.INSERT_AND_PLAY;
                break;

            case MediaEnum.PlayType.NEXT:
                cmd = MediaEnum.AudioCommands.SEARCH.INSERT;
                break;

            case MediaEnum.PlayType.REPLACE:
                cmd = MediaEnum.AudioCommands.SEARCH.PLAY;
                break;

            case MediaEnum.PlayType.ADD:
                cmd = MediaEnum.AudioCommands.SEARCH.ADD;
                break;

            default:
                cmd = null;
                break;
        }

        cmd += this._getItemId(item);
        return cmd;
    };

    MediaServerComponent.prototype._getQueuePlayCommand = function _getQueuePlayCommand(item, playType) {
        var cmd = null;

        switch (playType) {
            case MediaEnum.PlayType.NOW:
                cmd = MediaEnum.AudioCommands.QUEUE.JUMP_TO;
                break;

            default:
                cmd = null;
                break;
        }

        if (cmd) {
            cmd += item[MediaEnum.Event.QUEUE_INDEX];
        }

        return cmd;
    };

    MediaServerComponent.prototype._getInputPlayCommand = function _getInputPlayCommand(item, playType) {
        var cmd = null;

        switch (playType) {
            case MediaEnum.PlayType.REPLACE:
                cmd = MediaEnum.AudioCommands.LINEIN.PLAY;
                break;

            default:
                cmd = null;
                break;
        }

        if (cmd) {
            cmd += item[MediaEnum.Event.ID];
        }

        return cmd;
    };

    MediaServerComponent.prototype._getFavoritePlayCommandForItem = function _getFavoritePlayCommandForItem(item, playType) {
        var cmd = null;

        switch (playType) {
            case MediaEnum.PlayType.NOW:
                cmd = MediaEnum.AudioCommands.QUEUE.INSERT_AND_PLAY;
                break;

            case MediaEnum.PlayType.NEXT:
                cmd = MediaEnum.AudioCommands.QUEUE.INSERT;
                break;

            case MediaEnum.PlayType.ADD:
                cmd = MediaEnum.AudioCommands.QUEUE.ADD;
                break;

            case MediaEnum.PlayType.REPLACE:
                cmd = MediaEnum.AudioCommands.FAVORITE.PLAY;
                break;

            default:
                cmd = null;
                break;
        }

        if (cmd) {
            cmd += this._getItemId(item, true); // The Musicserver requires the id, not the audiopath property!
        }

        return cmd;
    };

    MediaServerComponent.prototype._getZoneFavoritePlayCommandForItem = function _getZoneFavoritePlayCommandForItem(item, playType) {
        var cmd = null;

        switch (playType) {
            case MediaEnum.PlayType.REPLACE:
                cmd = MediaEnum.AudioCommands.ZONE_FAV.PLAY;
                break;

            default:
                cmd = null;
                break;
        }

        if (cmd) {
            // zone favorites have slots!
            cmd += item[MediaEnum.Event.SLOT];
        }

        return cmd;
    };

    MediaServerComponent.prototype._getLibraryPlayCommandForItem = function _getLibraryPlayCommandForItem(item, playType) {
        var cmd = null;

        switch (playType) {
            case MediaEnum.PlayType.NOW:
                cmd = MediaEnum.AudioCommands.QUEUE.INSERT_AND_PLAY;
                break;

            case MediaEnum.PlayType.NEXT:
                cmd = MediaEnum.AudioCommands.QUEUE.INSERT;
                break;

            case MediaEnum.PlayType.REPLACE:
                cmd = MediaEnum.AudioCommands.LIBRARY.PLAY;
                break;

            case MediaEnum.PlayType.ADD:
                cmd = MediaEnum.AudioCommands.QUEUE.ADD;
                break;
        }

        cmd += this._getItemId(item);
        return cmd;
    };
    /**
     * When it comes to playlists, there is only playtype, replace queue.
     * @param item
     * @param playType
     * @param mediaTypeDetails
     * @returns {*}
     * @private
     */


    MediaServerComponent.prototype._getPlaylistPlayCommandForItem = function _getPlaylistPlayCommandForItem(item, playType, mediaTypeDetails) {
        var cmd = null,
            service = mediaTypeDetails ? mediaTypeDetails[MediaEnum.Attr.SERVICE._] : null;
        var isService; //Musicserver V1: service does not contain CMD -> using UID -> when lms -> "lms/noUser"

        if (!MediaServerComp.Feature.V2_FIRMWARE) {
            isService = service && !service[MediaEnum.Attr.SERVICE.UID].startsWith(MediaEnum.Target.LMS);
        } else {
            isService = service && service[MediaEnum.Attr.SERVICE.CMD] !== MediaEnum.Target.LMS;
        }

        if (isService) {
            return this._getServicePlayCommandForItem(item, playType, mediaTypeDetails);
        } else {
            switch (playType) {
                case MediaEnum.PlayType.REPLACE:
                    cmd = MediaEnum.AudioCommands.PLAYLIST.PLAY;
                    break;

                case MediaEnum.PlayType.ADD:
                    cmd = MediaEnum.AudioCommands.QUEUE.ADD;
                    break;

                case MediaEnum.PlayType.NEXT:
                    cmd = MediaEnum.AudioCommands.QUEUE.INSERT;
                    break;

                default:
                    break;
            }

            if (cmd) {
                cmd += this._getItemId(item, true);
            }
        }

        return cmd;
    };

    MediaServerComponent.prototype._getServicePlayCommandForItem = function _getServicePlayCommandForItem(item, playType, mediaTypeDetails) {
        var cmd = null,
            service;

        switch (playType) {
            case MediaEnum.PlayType.NOW:
                cmd = MediaEnum.AudioCommands.SERVICE.INSERT_AND_PLAY;
                break;

            case MediaEnum.PlayType.NEXT:
                cmd = MediaEnum.AudioCommands.SERVICE.INSERT;
                break;

            case MediaEnum.PlayType.REPLACE:
                cmd = MediaEnum.AudioCommands.SERVICE.PLAY;
                break;

            case MediaEnum.PlayType.ADD:
                cmd = MediaEnum.AudioCommands.SERVICE.ADD;
                break;
        }

        service = mediaTypeDetails[MediaEnum.Attr.SERVICE._];

        if (service.hasOwnProperty(MediaEnum.Attr.SERVICE.UID)) {
            cmd += service[MediaEnum.Attr.SERVICE.UID];
        } else if (service.hasOwnProperty(MediaEnum.Attr.SERVICE.CMD)) {
            cmd += service[MediaEnum.Attr.SERVICE.CMD];

            if (service.hasOwnProperty(MediaEnum.Attr.SERVICE.USER)) {
                cmd += "/" + service[MediaEnum.Attr.SERVICE.USER];
            } else {
                cmd += "/" + MediaEnum.NOUSER;
            }
        }

        cmd += "/" + this._getItemId(item, true);
        return cmd;
    };
    /**
     * Returns either an items path or an items ID. Path has priority.
     * @param item      the item in question
     * @param [idFirst] if specified, the id has priority
     * @returns {*}     either the path (if available), otherwise the ID.
     * @private
     */


    MediaServerComponent.prototype._getItemId = function _getItemId(item, idFirst) {
        var id = null; // Added Track ID check, because the id of a track in a playlist is actually the index, and not a global id
        // required for Musicserver V1

        if (!MediaServerComp.Feature.V2_FIRMWARE && idFirst && item.hasOwnProperty(MediaEnum.Event.TRACK_ID)) {
            id = item[MediaEnum.Event.TRACK_ID];
        } else if (idFirst && item.hasOwnProperty(MediaEnum.Event.ID)) {
            id = item[MediaEnum.Event.ID];
        } else if (item.hasOwnProperty(MediaEnum.Event.AUDIO_PATH) && item[MediaEnum.Event.AUDIO_PATH] !== "") {
            id = item[MediaEnum.Event.AUDIO_PATH];
        } else if (item.hasOwnProperty(MediaEnum.Event.ID)) {
            id = item[MediaEnum.Event.ID];
        } else {
            throw new Error("The item " + JSON.stringify(item) + " has neither got an audioPath nor an ID!");
        }

        return id;
    };
    /**
     * Returns the specific popup (context menue) buttons for a playlist.
     * @param item              the item in question
     * @param mediaTypeDetails  details such as the source service of the item
     * @param rmCB              the callback when remove/delete/unfollow was selcted
     * @param editCB            callback for editing the playlist
     * @param renameCB          callback when renaming was selected.
     * @returns {Array}
     */


    MediaServerComponent.prototype.getPlaylistContextMenuButtons = function getPlaylistContextMenuButtons(item, mediaTypeDetails, rmCB, editCB, renameCB) {
        var buttons = [];

        if (item[MediaEnum.Event.FILE_TYPE] === MediaEnum.FileType.PLAYLIST_EDITABLE) {
            // contentOld-type/service specific actions
            //TODO-woessto: renaming playlists isn't possible? https://www.wrike.com/open.htm?id=123008490

            /*
            !renameCB && buttons.push({
                title: _("media.playlist.rename"),
                callback: renameCB
             });
             */
            !!editCB && buttons.push({
                title: _("media.playlist.edit-list"),
                callback: editCB
            });
            !!rmCB && buttons.push({
                title: _("media.playlist.delete"),
                callback: rmCB
            });
        } else if (item[MediaEnum.Event.FILE_TYPE] === MediaEnum.FileType.FOLLOWED_PLAYLIST) {
            !!rmCB && buttons.push({
                title: _("media.playlist.unfollow"),
                callback: rmCB
            });
        }

        return buttons;
    };
    /**
     * Returns a set of audioZones that are available on this Media Server. Converted into an object
     * whos attributes are converted so they can be used by the MediaServerComp source just like if
     * they came from the MediaServer and not the Miniservers structure file.
     * @param [svrUuid]     optional, provides the uuid of the music server we're looking for. Default: ActiveMediaServer
     * @returns {{}}    set of audioZones.
     */


    MediaServerComponent.prototype.getAvailableZones = function getAvailableZones(svrUuid) {
        var zoneControls = ActiveMSComponent.getStructureManager().getControlsByType("AudioZone");
        var availableZones = {};

        if (!svrUuid) {
            var activeMediaSvr = this.getActiveMediaServer();

            if (!activeMediaSvr) {
                return availableZones;
            }

            svrUuid = activeMediaSvr.uuidAction;
        }

        Debug.Media.Component && console.log("Zones received: ");

        for (var i = 0; i < zoneControls.length; i++) {
            var zone = zoneControls[i];

            if (!svrUuid || svrUuid === zone.details.server) {
                Debug.Media.Component && console.log("    " + zone.details.playerid + " - " + zone.name);
                availableZones[zone.details.playerid] = {};
                availableZones[zone.details.playerid][MediaEnum.Event.PLAYER_ID] = zone.details.playerid; // read details and uuidAction from the structure file.

                availableZones[zone.details.playerid].details = zone.details;
                availableZones[zone.details.playerid].uuidAction = zone.uuidAction;
                availableZones[zone.details.playerid].groupDetail = zone.groupDetail;
                availableZones[zone.details.playerid].controlType = zone.controlType;
                availableZones[zone.details.playerid].type = zone.type; // get state snapshot

                updateFields(availableZones[zone.details.playerid], this.extensions.audioZoneExt.getStatesOfZone(zone.details.playerid)); // The zoneStates contain a name property with the name of the Zone, we want a coherent naming scheme of the zones,
                // so we use our getName function instead of the provided name, our function also accounts for the room name

                availableZones[zone.details.playerid][MediaEnum.Event.NAME] = zone.getName();
            }
        }

        return availableZones;
    };
    /**
     * Returns whether or not the given audioType can be controlled. We do not have any power when airplay
     * or a line-in is active, so we mustn't show any controls indicating that we can do anything with them.
     * @param [audioType] the type which is to be evaluated
     * @returns {boolean} whether or not the type is controllable
     */


    MediaServerComponent.prototype.isControllableAudioType = function isControllableAudioType(audioType) {
        var controllable = true;

        switch (audioType) {
            case MediaEnum.AudioType.AIRPLAY:
            case MediaEnum.AudioType.SPOTIFY_CONNECT:
                //case MediaEnum.AudioType.LINEIN: --> you can still control while you're in line in.
                controllable = false;
        }

        return controllable;
    };
    /**
     * Returns whether or not the given audioType is a stream. it does not take into consideration if it's controllable
     * or not.
     * @param [audioType] the type which is to be evaluated
     * @returns {boolean} whether or not the type is a stream
     */


    MediaServerComponent.prototype.isStream = function isStream(audioType) {
        var isStream = false;

        switch (audioType) {
            case MediaEnum.AudioType.SPOTIFY_CONNECT:
            case MediaEnum.AudioType.AIRPLAY:
            case MediaEnum.AudioType.STREAM:
            case MediaEnum.AudioType.LINEIN:
                isStream = true;
        }

        return isStream;
    };
    /**
     * Gets the last selected user from the local storage
     * @param [forCurrentControl] if true the username for the current control will be returned
     * @returns {*}
     */


    MediaServerComponent.prototype.getCurrentSpotifyId = function getCurrentSpotifyId(forCurrentControl) {
        return PersistenceComponent.getShared(MediaEnum.CUSTOMIZATION_KEYS.CURRENT_SPOTIFY_ACC_ID).then(function (res) {
            return res;
        }, function () {
            return {};
        }).then(function (res) {
            res = res || {};

            if (forCurrentControl) {
                return res[MediaServerComp.getActiveZoneControl().uuidAction];
            } else {
                return res;
            }
        }.bind(this));
    };
    /**
     * Will return a properly formated name for the item passed in here.
     * @param item
     */


    MediaServerComponent.prototype.getNameForItem = function getNameForItem(item) {
        // clone item, no modifications on the input argument.
        var cleanItem = this.decodeItem(cloneObject(item)),
            name = cleanItem[MediaEnum.Event.NAME];

        if (!name) {
            var station = cleanItem[MediaEnum.Event.STATION]; // if a station attribute is given, use it and ignore the rest (it mostly will reflect current track info on the stream)

            if (station && station !== "") {
                name = station;
            } else {
                var title = cleanItem[MediaEnum.Event.TITLE];
                var artist = cleanItem[MediaEnum.Event.ARTIST];
                var album = cleanItem[MediaEnum.Event.ALBUM];

                if (artist) {
                    name = artist;

                    if (title) {
                        name += " - ";
                    }
                }

                if (title) {
                    if (name) {
                        name += title;
                    } else {
                        name = title;
                    }
                }

                if (album) {
                    name += " (" + album + ")";
                }
            }
        }

        return name;
    };
    /**
     * Gets the control object of the audioZone affected by the command provided.
     * @param svrUuid   the cmd target uuid (has to be the mediaServerUuid)
     * @param command   the audio/xx/xxx cmd
     * @returns {*} either a control or null.
     */


    MediaServerComponent.prototype.getZoneFromCommand = function getZoneFromCommand(svrUuid, command) {
        var zone = null;
        var mediaServer = this.getMediaServer(svrUuid);

        if (mediaServer && mediaServer.uuidAction === svrUuid) {
            var audioParts = decodeURIComponent(command).split("/");
            var playerid = parseInt(audioParts[1]);
            zone = MediaServerComp.getAvailableZones(svrUuid)[playerid];
        }

        return zone;
    };
    /**
     * Takes an item as input and creates an object containing both a title and subtitle to be displayed
     * for this item.
     * @param item
     * @returns {{title: string, subtitle: string}}
     */


    MediaServerComponent.prototype.getTitleSubtitleForItem = function getTitleSubtitleForItem(item) {
        var mainText = "";
        var subText = "";

        if (item.hasOwnProperty(MediaEnum.Event.NAME) && item[MediaEnum.Event.NAME]) {
            mainText = item[MediaEnum.Event.NAME];
        } else {
            if (item.hasOwnProperty(MediaEnum.Event.TITLE)) {
                mainText = item[MediaEnum.Event.TITLE];

                if (item[MediaEnum.Event.ARTIST] && item[MediaEnum.Event.ARTIST] !== "") {
                    subText = item[MediaEnum.Event.ARTIST];
                }

                if (item[MediaEnum.Event.ALBUM] && item[MediaEnum.Event.ALBUM] !== "") {
                    if (subText) {
                        subText += " (" + item[MediaEnum.Event.ALBUM] + ")";
                    } else {
                        subText = item[MediaEnum.Event.ALBUM];
                    }
                }

                if (mainText === "") {
                    // if the title was empty, swap main and subtext
                    mainText = subText;
                    subText = "";
                }
            } // maybe we have a radioStation set?


            if (item.hasOwnProperty(MediaEnum.Event.STATION) && item[MediaEnum.Event.STATION] !== "") {
                if (subText !== "") {
                    mainText += " - " + subText;
                }

                subText = mainText;
                mainText = item[MediaEnum.Event.STATION];
            }

            if (mainText === "" && subText === "") {
                mainText = _("media.no-music-selected");
                subText = "";
            }
        } //  playlists e.g. have an owner attribute, that's especially important for spotify, where playlists can be shared.


        if (item.hasOwnProperty(MediaEnum.Event.OWNER) && item[MediaEnum.Event.OWNER] !== "") {
            subText = subText === "" ? item[MediaEnum.Event.OWNER] : subText + SEPARATOR_SYMBOL + item[MediaEnum.Event.OWNER];
        }

        return {
            title: mainText,
            subtitle: subText
        };
    };

    MediaServerComponent.prototype.defaultIconForAudioType = function defaultIconForAudioType(audioType) {
        var iconSrc;

        switch (audioType) {
            case MediaEnum.AudioType.PLAYLIST:
                iconSrc = Icon.AudioZone.PLAYLIST_COVER;
                break;

            case MediaEnum.AudioType.STREAM:
                iconSrc = "resources/Images/Controls/AudioZone/cover-stream.svg";
                break;

            case MediaEnum.AudioType.FILE:
                iconSrc = Icon.AudioZone.TITLE_COVER;
                break;

            case MediaEnum.AudioType.LINEIN:
                iconSrc = "resources/Images/Controls/AudioZone/cover-line-in.svg";
                break;

            case MediaEnum.AudioType.AIRPLAY:
                iconSrc = "resources/Images/Controls/AudioZone/cover-airplay.svg";
                break;

            default:
                iconSrc = this.getDefaultIconForUnknown();
                break;
        }

        return iconSrc;
    };

    MediaServerComponent.prototype.getDefaultIconForUnknown = function getDefaultIconForUnknown() {
        return "resources/Images/General/Popups/caution.svg";
    };

    MediaServerComponent.prototype.defaultIconForType = function defaultIconForType(type) {
        var iconSrc;

        switch (type) {
            case MediaEnum.FileType.FAVORITE:
                iconSrc = "resources/Images/Controls/AudioZone/favorits.svg";
                break;

            case MediaEnum.FileType.ROOM_FAVORITE:
                iconSrc = Icon.AudioZone.ZONE_FAV_COVER;
                break;

            case MediaEnum.FileType.HW_INPUT:
                iconSrc = "resources/Images/Controls/AudioZone/icon-line-in.svg";
                break;

            case MediaEnum.FileType.LOCAL_PLAYLIST:
            case MediaEnum.FileType.PLAYLIST_BROWSABLE:
            case MediaEnum.FileType.PLAYLIST_EDITABLE:
            case MediaEnum.FileType.FOLLOWED_PLAYLIST:
                iconSrc = Icon.AudioZone.PLAYLIST_COVER;
                break;

            case MediaEnum.FileType.LOCAL_DIR:
                iconSrc = "resources/Images/Controls/AudioZone/cover-folder.svg";
                break;

            case MediaEnum.FileType.LOCAL_FILE:
                iconSrc = Icon.AudioZone.TITLE_COVER;
                break;

            default:
                iconSrc = this.getDefaultIconForUnknown();
                break;
        }

        return iconSrc;
    };
    /**
     * Returns info on whether or not a certain content type is editable or not
     * @param contentType   the mediaContentType to check (playlist, favorites, ..)
     * @param [fileType]    e.g. when it comes to playlists, not all of them are editable.
     * @param [mediaTypeDetails]    e.g. when it comes to playlists, not all of them are editable. google music e.g. is not editable.
     * @returns {boolean}
     */


    MediaServerComponent.prototype.isEditableContentType = function isEditableContentType(contentType, fileType, mediaTypeDetails) {
        var result = false;

        switch (contentType) {
            case MediaEnum.MediaContentType.FAVORITES:
            case MediaEnum.MediaContentType.ZONE_FAVORITES:
                result = true;
                break;

            case MediaEnum.MediaContentType.PLAYLISTS:
                result = fileType === MediaEnum.FileType.PLAYLIST_EDITABLE;

                if (result && mediaTypeDetails && mediaTypeDetails.service) {
                    result = mediaTypeDetails.service[MediaEnum.Attr.SERVICE.CMD] !== MediaEnum.Service.GOOGLE;
                }

                break;

            default:
                break;
        }

        return result;
    };

    MediaServerComponent.prototype.isSearchableContentType = function isSearchableContentType(type, service) {
        var result = false;

        switch (type) {
            case MediaEnum.MediaContentType.SERVICE:
                result = this.extensions.serviceHandlerExt.isSearchableService(service);
                break;

            case MediaEnum.MediaContentType.LIBRARY:
                result = true;
                break;

            case MediaEnum.MediaContentType.PLAYLISTS:
                result = MediaServerComp.Feature.SEARCHABLE_PLAYLISTS;
                break;

            default:
                break;
        }

        return result;
    };
    /**
     * Used to create a zoneFavorite save command. Handles all types, except for creating a zone favorite form the queue
     * @param slot                  the slot of the new zoneFavorite
     * @param playerid              what player is being edited
     * @param item                  the item object that is being saved as favorite
     * @param name                  name of the new zone favorte
     * @param contentType           contentType, e.g. Library, Service
     * @param [mediaTypeDetails]    if the contentType is service, there have to be mediaTypeDetails
     * @returns {string}            the command used to save this new zone favorite. null if the contentype wasn't supported.
     */


    MediaServerComponent.prototype.getZoneFavoriteSaveCommand = function getZoneFavoriteSaveCommand(slot, playerid, item, name, contentType, mediaTypeDetails) {
        var cmd = "audio/" + playerid + "/";
        /*
        audio/PlayerID/roomfav/saveid/SlotID/FileID/Name		        Lokale Bibliothek, files / folders -> ID aus DB
        audio/PlayerID/roomfav/savepath/SlotID/audiopath/Name		    (echter Path) Radio streamurl etc (urlencoded).
        audio/PlayerID/roomfav/saveexternalid/SlotID/service/ID/Name	service = spotify, googlemusic
        */

        switch (contentType) {
            case MediaEnum.MediaContentType.LIBRARY:
                cmd += MediaEnum.AudioCommands.ZONE_FAV.SAVE_LOCAL;
                cmd += slot + "/";
                cmd += item[MediaEnum.Event.ID] + "/";
                cmd += name;
                break;

            case MediaEnum.MediaContentType.PLAYLISTS:
            case MediaEnum.MediaContentType.FAVORITES:
            case MediaEnum.MediaContentType.PLAYING:
            case MediaEnum.MediaContentType.SEARCH:
                cmd = this._getSaveZoneFavByPathCmd(cmd, item, slot, name);
                break;

            case MediaEnum.MediaContentType.SERVICE:
                var serviceIdentifier = mediaTypeDetails.service.cmd;

                if (MediaServerComp.isRadioService(serviceIdentifier)) {
                    // deal with a radio --> they are identified by their path uniquely
                    cmd = this._getSaveZoneFavByPathCmd(cmd, item, slot, name);
                } else {
                    // deal with a service
                    cmd += MediaEnum.AudioCommands.ZONE_FAV.SAVE_EXTERNAL;
                    cmd += slot + "/";
                    cmd += serviceIdentifier + "/";
                    cmd += item[MediaEnum.Event.ID] + "/";
                    cmd += name;
                }

                break;

            case MediaEnum.MediaContentType.LINEIN:
                if (MediaServerComp.Feature.INPUT_ZONE_FAVS) {
                    cmd += MediaEnum.AudioCommands.ZONE_FAV.SAVE_INPUT;
                    cmd += slot + "/";
                    cmd += item[MediaEnum.Attr.SERVICE.CMD] + "/";
                    cmd += name;
                } else {
                    console.error("Adding INPUTs directly isn't supported yet!");
                    cmd = null;
                }

                break;

            default:
                console.error("Did not create an add-zone-favorite command for the MediaContentType " + contentType);
                cmd = null;
                break;
        }

        return cmd;
    };
    /**
     * Will return the command to save this item as zone favorite based on its path - if available. Null if no path is provided.
     * @param cmd
     * @param item
     * @param slot
     * @param name
     * @return {*}
     * @private
     */


    MediaServerComponent.prototype._getSaveZoneFavByPathCmd = function _getSaveZoneFavByPathCmd(cmd, item, slot, name) {
        var newCmd = cmd;

        if (item.hasOwnProperty(MediaEnum.Event.AUDIO_PATH) && item[MediaEnum.Event.AUDIO_PATH] !== "") {
            newCmd += MediaEnum.AudioCommands.ZONE_FAV.SAVE_PATH;
            newCmd += slot + "/";
            newCmd += encodeURIComponent(item[MediaEnum.Event.AUDIO_PATH]) + "/";
            newCmd += name;
        } else {
            newCmd = null;
            console.error("The item has no audioPath, therefore it cannot be added! " + JSON.stringify(item));
        }

        return newCmd;
    };
    /**
     * Sends a given add zone favorite command and presents a confirmation feedback if succeeded. Returns a promise that
     * resolves or rejects depending on the add result.
     * @param cmd
     * @param item
     * @param name
     * @param slot
     * @param zone  control-object of the current zone
     * @returns {deferred.promise|{then, catch, finally}}
     */


    MediaServerComponent.prototype.sendAddZoneFavoriteCmd = function sendAddZoneFavoriteCmd(cmd, item, name, slot, zone) {
        var promise = this.sendMediaServerCommand(cmd);
        promise.done(function () {
            Debug.Media.Component && console.log(this.name + ": Saving Audio-Zone-Fav succeeded " + cmd);
            var attributes = {
                name: name,
                pos: slot,
                zone: zone.name
            };

            var txt = _("media.item.add-to-favorites-of-zone.confirmation", attributes);

            MediaServerComp.showConfirmationFeedback(txt);
        }.bind(this), function () {
            console.error(this.name + ": Saving Audio-Zone-Fav failed " + cmd);
        }.bind(this));
        return promise;
    };
    /**
     * Returns if an media item has content to show in a detailOverlay
     * @param contentType   the mediaContentType of the item (Search, Service, ..)
     * @param item          the item in question itself.
     * @returns {boolean}
     */


    MediaServerComponent.prototype.hasDetailOverlay = function hasDetailOverlay(contentType, item) {
        var hasDetail = true;

        switch (contentType) {
            case MediaEnum.MediaContentType.SERVICE:
            case MediaEnum.MediaContentType.SEARCH:
                hasDetail = this.isFileTypePlayable(item[MediaEnum.Event.FILE_TYPE], contentType);
                break;

            default:
                break;
        }

        return hasDetail;
    }; // ----------------------------------------------------------------
    // Displaying Feedback
    // ----------------------------------------------------------------

    /**
     * Used for showing a confirmation (e.g. Playlist saved) to the user.
     * Presents a notification on the bottom that contains a text. It's only shown temporarily (5 seconds), but
     * if the user taps it, it'll turn into a popup and stays until the user removes clicks it.
     * @param text  the text to show in the popup.
     */


    MediaServerComponent.prototype.showConfirmationFeedback = function showConfirmationFeedback(text) {
        if (!this.notification) {
            this.notification = GUI.Notification.createGeneralNotification({
                subtitle: text,
                closeable: false,
                clickable: true,
                removeAfter: 5
            }, NotificationType.SUCCESS);
            this.notification.on(GUI.Notification.CLICK_EVENT, function () {
                this.notification.remove();
                NavigationComp.showPopup({
                    message: text,
                    buttonOk: true
                });
            }.bind(this));
            this.notification.on("destroy", function () {
                this.notification = null;
            }.bind(this));
        }
    };
    /**
     * Fallback for when the MediaServer returns items the app cannot process. It'll show a popup in english since this
     * should only occur during development. Or if the user updates the Music Server but has an outdated app/wi
     * @param item  the item that cannot be handled.
     */


    MediaServerComponent.prototype.unhandledItem = function unhandledItem(item) {
        var txt = "The item '" + item.name + "' cannot be processed.<br>(type: " + item[MediaEnum.Event.FILE_TYPE] + ")";
        this.showContent({
            title: "Item not handled",
            message: txt,
            buttonOk: true,
            icon: Icon.INFO
        });
    }; // ----------------------------------------------------------------
    // AudioZoneExt
    // ----------------------------------------------------------------


    MediaServerComponent.prototype.registerForAudioZone = function registerForAudioZone() {
        // this is the first method that is called when an audioZone/audioView is present on startup, check extensions here too.
        this._checkExtensions(); // structureReady is already fired, but the MediaServerComp receives it after the UI


        return this.extensions.audioZoneExt.registerForAudioZone.apply(this.extensions.audioZoneExt, arguments);
    };

    MediaServerComponent.prototype.unregisterFromAudioZone = function unregisterFromAudioZone() {
        this.extensions.audioZoneExt.unregisterFromAudioZone.apply(this.extensions.audioZoneExt, arguments);
    };

    MediaServerComponent.prototype.sendAudioZoneCommand = function sendAudioZoneCommand() {
        return this.extensions.audioZoneExt.send.apply(this.extensions.audioZoneExt, arguments);
    };
    /**
     * Will append the parent info (= who's the parent, and what position is the selected item at). Takes care
     * of feature checking before data is appended to the command.
     * @param cmd       the command to add the parent info to
     * @param row       what is the row of the item that will be played with this command?
     * @param parent    the parent whos info is to be appended.
     * @returns {*}     the adopted command, including the parentInfo.
     */


    MediaServerComponent.prototype.appendParentInfo = function appendParentInfo(cmd, row, parent) {
        if (!MediaServerComp.Feature.PLAY_WHOLE_LIST) {
            return cmd;
        }

        var newCmd = cmd;

        try {
            // first of all, determine the parents info.
            if (parent.hasOwnProperty(MediaEnum.Event.AUDIO_PATH) && parent[MediaEnum.Event.AUDIO_PATH] !== "") {
                newCmd += MediaEnum.ParentInfo.PATH + encodeURIComponent(parent[MediaEnum.Event.AUDIO_PATH]);
            } else if (parent.hasOwnProperty(MediaEnum.Event.ID)) {
                newCmd += MediaEnum.ParentInfo.ID + encodeURIComponent(parent[MediaEnum.Event.ID]);
            } else {
                throw new Error("Parent has neither got an ID nor an AudioPath! " + JSON.stringify(parent));
            } // now lets append the item position


            if (row >= 0) {
                newCmd += "/" + row;
            } else {
                throw new Error("The items position could not be determined!");
            }
        } catch (err) {
            console.error(this.name + ":Could not acquire parent info! " + JSON.stringify(err));
            console.error(err);
            newCmd = cmd;
        }

        return newCmd;
    }; // ----------------------------------------------------------------
    // Settings
    // ----------------------------------------------------------------


    MediaServerComponent.prototype.registerForAudioZoneSettings = function registerForAudioZoneSettings() {
        return this.extensions.audioZoneSettingsExt.registerForAudioZoneSettings.apply(this.extensions.audioZoneSettingsExt, arguments);
    };

    MediaServerComponent.prototype.unregisterFromAudioZoneSettings = function unregisterFromAudioZoneSettings() {
        this.extensions.audioZoneSettingsExt.unregisterFromAudioZoneSettings.apply(this.extensions.audioZoneSettingsExt, arguments);
    };

    MediaServerComponent.prototype.sendAudioZoneSettingsCommand = function sendAudioZoneSettingsCommand() {
        this.extensions.audioZoneSettingsExt.sendSetting.apply(this.extensions.audioZoneSettingsExt, arguments);
    }; // ----------------------------------------------------------------
    // Services & Radios
    // ----------------------------------------------------------------


    MediaServerComponent.prototype.getCurrentServices = function getCurrentServices() {
        this._restrictedFeature();

        return this.extensions.serviceHandlerExt.getCurrentServices.apply(this.extensions.serviceHandlerExt, arguments);
    };

    MediaServerComponent.prototype.getAvailableServices = function getAvailableServices() {
        this._restrictedFeature();

        return this.extensions.serviceHandlerExt.getAvailableServices.apply(this.extensions.serviceHandlerExt, arguments);
    };

    MediaServerComponent.prototype.getServiceName = function getServiceName() {
        return this.extensions.serviceHandlerExt.getServiceName.apply(this.extensions.serviceHandlerExt, arguments);
    };

    MediaServerComponent.prototype.getServiceIcon = function getServiceIcon() {
        return this.extensions.serviceHandlerExt.getServiceIcon.apply(this.extensions.serviceHandlerExt, arguments);
    };

    MediaServerComponent.prototype.registerForServiceChanges = function registerForServiceChanges() {
        return this.extensions.serviceHandlerExt.registerForServiceChanges.apply(this.extensions.serviceHandlerExt, arguments);
    }; // Radios


    MediaServerComponent.prototype.getRadios = function getRadios() {
        this._restrictedFeature();

        return this.extensions.serviceHandlerExt.getRadios.apply(this.extensions.serviceHandlerExt, arguments);
    };
    /**
     * Checks wether or not the "cmd" attribute given is one of the radios or not. Needed in the search.
     * @param cmd the cmd attribute returned by the getradios-cmd (or the getservices cmd)
     * @returns {boolean} wether or not it's a radio
     */


    MediaServerComponent.prototype.isRadioService = function isRadioService(cmd) {
        return this.extensions.serviceHandlerExt.isRadio.apply(this.extensions.serviceHandlerExt, arguments);
    };
    /**
     * Provides info on whether or not a service does support playlists.
     * @param service           the service object to check
     * @returns {boolean}     true if this service supports playlists.
     */


    MediaServerComponent.prototype.doesServiceSupportPlaylists = function doesServiceSupportPlaylists(service) {
        // first of all, is it real music service (like spotify)
        var doesSupport = this.isMusicService(service); // the music server version also has to fit the purpose.

        doesSupport = doesSupport && MediaServerComp.Feature.NEW_PLAYLISTS; // the service itself has to support playlists (atm only spotify does that)

        doesSupport = doesSupport && service[MediaEnum.Attr.SERVICE.CMD] === MediaEnum.Service.SPOTIFY;
        return doesSupport;
    };
    /**
     * Provides info on whether or not a service does support playlists.
     * @param service           the service object to check
     * @returns {boolean}     true if this service supports playlists.
     */


    MediaServerComponent.prototype.isMusicService = function isMusicService(service) {
        return this.extensions.serviceHandlerExt.isMusicService.apply(this.extensions.serviceHandlerExt, arguments);
    };
    /**
     * Returns true if the item provided comes from a service and can be followed. A feature that at the beginning was
     * only supported for spotify accounts.
     * @param service   the service in question
     * @param item      the item in question
     * @returns {*}
     */


    MediaServerComponent.prototype.isServiceItemFollowable = function isServiceItemFollowable(service, item) {
        return this.extensions.serviceHandlerExt.isServiceItemFollowable.apply(this.extensions.serviceHandlerExt, arguments);
    };
    /**
     * Returns true if the item provided can be added to a playlist. e.g. a radio or an input can't be added to playlists.
     * @param contentType   the contentType in question
     * @param item          the item in question
     * @param service       the service in question
     * @returns {*}
     */


    MediaServerComponent.prototype.isItemForPlaylist = function isItemForPlaylist(contentType, item, service) {
        var playable = MediaServerComp.isFileTypePlayable(item[MediaEnum.Event.FILE_TYPE], contentType),
            isStream = !!service && MediaServerComp.isRadioService(service[MediaEnum.Attr.SERVICE.CMD]),
            isFavorite = contentType === MediaEnum.MediaContentType.FAVORITES || contentType === MediaEnum.MediaContentType.ZONE_FAVORITES,
            hasFeature = MediaServerComp.Feature.NEW_PLAYLISTS;

        if (!isStream && item.hasOwnProperty(MediaEnum.Event.AUDIO_TYPE)) {
            switch (item[MediaEnum.Event.AUDIO_TYPE]) {
                case MediaEnum.AudioType.STREAM:
                case MediaEnum.AudioType.AIRPLAY:
                case MediaEnum.AudioType.LINEIN:
                    isStream = true;
                    break;

                default:
                    break;
            }
        }

        return hasFeature && playable && !isStream && !isFavorite;
    }; // ----------------------------------------------------------------
    // Zone Favorites
    // ----------------------------------------------------------------


    MediaServerComponent.prototype.selectNewZoneFavorite = function selectNewZoneFavorite() {
        this._restrictedFeature();

        return this.extensions.zoneFavExt.selectNewZoneFavorite.apply(this.extensions.zoneFavExt, arguments);
    };

    MediaServerComponent.prototype.deleteZoneFavorite = function deleteZoneFavorite() {
        this._restrictedFeature();

        return this.extensions.zoneFavExt.deleteZoneFavorite.apply(this.extensions.zoneFavExt, arguments);
    }; // ----------------------------------------------------------------
    // Inputs
    // ----------------------------------------------------------------


    MediaServerComponent.prototype.getInputs = function getLineIns() {
        this._restrictedFeature();

        return this.extensions.inputExt.getInputs.apply(this.extensions.inputExt, arguments);
    };

    MediaServerComponent.prototype.updateInputName = function updateInputName() {
        this._restrictedFeature();

        return this.extensions.inputExt.updateInputName.apply(this.extensions.inputExt, arguments);
    };

    MediaServerComponent.prototype.updateInputType = function updateInputType() {
        this._restrictedFeature();

        return this.extensions.inputExt.updateInputType.apply(this.extensions.inputExt, arguments);
    };

    MediaServerComponent.prototype.updateInputEnabled = function updateInputEnabled() {
        this._restrictedFeature();

        return this.extensions.inputExt.updateInputEnabled.apply(this.extensions.inputExt, arguments);
    };

    MediaServerComponent.prototype.updateInputVolume = function updateInputVolume() {
        this._restrictedFeature();

        return this.extensions.inputExt.updateInputVolume.apply(this.extensions.inputExt, arguments);
    }; // ----------------------------------------------------------------
    // Media Loaders
    // ----------------------------------------------------------------

    /**
     * Returns the root id to request content of a specific type.
     * @param contentType           e.g. playlists, service, ..
     * @param [mediaTypeDetails]    if sth is loaded from a service, it might have mediaTypeDetails
     * @returns {string}
     */


    MediaServerComponent.prototype.getRootIdForContent = function getRootIdForContent(contentType, mediaTypeDetails) {
        //TODO-woessto: what different kinds of start-ids do we have?
        return "0"; // "start"
    };

    MediaServerComponent.prototype.requestContent = function requestContent(contentType, identifier, packageSize, mediaTypeDetails) {
        var result = null;

        try {
            var loader = this._getLoaderForContentType(contentType);

            result = loader.requestContent.apply(loader, [identifier, packageSize, mediaTypeDetails]);
        } catch (ex) {
            console.error("An error occurred while requesting content for " + identifier + " " + contentType + " - " + JSON.stringify(mediaTypeDetails));
        }

        return result;
    };

    MediaServerComponent.prototype.invalidateContentCachesOf = function invalidateContentCachesOf(contentType, identifier, mediaTypeDetails, reason) {
        var loader = this._getLoaderForContentType(contentType);

        return loader.invalidateContentCachesOf.apply(loader, [identifier, reason]);
    };

    MediaServerComponent.prototype.prefetchContent = function prefetchContent(contentType, identifier, packageSize, mediaTypeDetails) {
        var loader = this._getLoaderForContentType(contentType);

        var args = [identifier, packageSize, mediaTypeDetails];
        loader.prefetchContent.apply(loader, args);
    };

    MediaServerComponent.prototype.stopRequestFor = function stopRequestFor(contentType, deferred, mediaTypeDetails) {
        var loader = this._getLoaderForContentType(contentType);

        loader && loader.stopRequestFor.apply(loader, [deferred, mediaTypeDetails]);
    };

    MediaServerComponent.prototype.registerForReloadEvent = function registerForReloadEvent(contentType, delegate) {
        var loader = this._getLoaderForContentType(contentType);

        return loader ? loader.registerForReloadEvent.apply(loader, [delegate]) : null;
    };

    MediaServerComponent.prototype.unregisterFromReloadEvent = function unregisterFromReloadEvent(contentType, regId) {
        var loader = this._getLoaderForContentType(contentType);

        if (!loader) {
            console.warn("Could not unregister form '" + contentType + "'-loader because it's no longer around!");
        } else {
            return loader.unregisterFromReloadEvent.apply(loader, [regId]);
        }
    };

    MediaServerComponent.prototype.registerForScanEvents = function registerForScanEvents() {
        return this.extensions.libraryExt.registerScanListener.apply(this.extensions.libraryExt, arguments);
    };

    MediaServerComponent.prototype.unregisterFromScanEvents = function unregisterFromScanEvents() {
        return this.extensions.libraryExt.removeScanListener.apply(this.extensions.libraryExt, arguments);
    };

    MediaServerComponent.prototype.getCurrentContent = function getCurrentContent(contentType, identifier, mediaTypeDetails) {
        var loader = this._getLoaderForContentType(contentType);

        return loader.getCurrentContent.apply(loader, [identifier, mediaTypeDetails]);
    };

    MediaServerComponent.prototype.updateContent = function updateContent(contentType, identifier, mediaTypeDetails, newContent, reason) {
        var loader = this._getLoaderForContentType(contentType);

        return loader.updateContent.apply(loader, [identifier, mediaTypeDetails, newContent, reason]);
    };
    /**
     * Decodes a specific attribute of an item (if it exists)
     * @param item  the item whos attribute is to be decoded (if it exists)
     * @param attr  the attribute of the item to decode (if it exists)
     * @private
     */


    MediaServerComponent.prototype._decodeItemAttr = function _decodeItemAttr(item, attr) {
        if (item.hasOwnProperty(attr)) {
            try {
                item[attr] = decodeURIComponent(item[attr]);
            } catch (exc) {// if we fail while decoding the name, just pass it along as is.
            }
        }
    };
    /**
     * Will decode all attributes of an item.
     * @param item the item to decode.
     * @returns {*} returns the item that has been decoded (for inline usage)
     */


    MediaServerComponent.prototype.decodeItem = function decodeItem(item) {
        MediaServerComp._decodeItemAttr(item, MediaEnum.Event.NAME);

        MediaServerComp._decodeItemAttr(item, MediaEnum.Event.TITLE);

        MediaServerComp._decodeItemAttr(item, MediaEnum.Event.ARTIST);

        MediaServerComp._decodeItemAttr(item, MediaEnum.Event.ALBUM);

        MediaServerComp._decodeItemAttr(item, MediaEnum.Event.STATION);

        MediaServerComp._decodeItemAttr(item, MediaEnum.Event.COVER);

        return item;
    };
    /**
     * Will open up something that allows the user to upload files to the music server. Platform-dependent.
     */


    MediaServerComponent.prototype.openFileUpload = function openFileUpload() {
        var targetIp = MediaServerComp.getServerIp(),
            target = "smb://Guest:@" + targetIp + "/AUDIO",
            canOpen = false;

        switch (PlatformComponent.getPlatformInfoObj().platform) {
            case PlatformType.Mac:
            case PlatformType.DeveloperInterface:
            case PlatformType.Webinterface:
                canOpen = true;
                break;

            default:
                break;
        }

        var content = {
            icon: Icon.PLUS,
            title: _("media.library.upload.title"),
            message: _("media.library.info", {
                target: target
            }),
            buttonCancel: true,
            buttonOk: canOpen ? _("media.library.upload.ok") : true
        };
        NavigationComp.showPopup(content).done(function () {
            if (canOpen) {
                NavigationComp.openWebsite(target);
            }
        });
    };

    MediaServerComponent.prototype._getLoaderForContentType = function _getLoaderForContentType(contentType) {
        var loader = null;

        switch (contentType) {
            case MediaEnum.MediaContentType.LIBRARY:
                this._restrictedFeature();

                loader = this.extensions.libraryExt;
                break;

            case MediaEnum.MediaContentType.FAVORITES:
                loader = this.extensions.favoritesExt;
                break;

            case MediaEnum.MediaContentType.ZONE_FAVORITES:
                loader = this.extensions.zoneFavoritesExt;
                break;

            case MediaEnum.MediaContentType.PLAYLISTS:
                this._restrictedFeature();

                loader = this.extensions.playlistsExt;
                break;

            case MediaEnum.MediaContentType.SERVICE:
                this._restrictedFeature();

                loader = this.servicesExt;
                break;

            case MediaEnum.MediaContentType.SEARCH:
                this._restrictedFeature();

                loader = this.extensions.searchDetailExt;
                break;

            case MediaEnum.MediaContentType.QUEUE:
                this._restrictedFeature();

                loader = this.extensions.queueLoaderExt;
                break;

            case MediaEnum.MediaContentType.LINEIN:
                this._restrictedFeature();

                loader = this.extensions.inputLoaderExt;
                break;

            default:
                throw "MediaServerComponent received an request for content with an unknown content type! " + contentType;
        }

        if (!loader) {
            console.warn("Could not get loader for '" + contentType + "', it's no longer around!");
        }

        return loader;
    }; // ----------------------------------------------------------------
    // MediaEditExtension
    // ----------------------------------------------------------------

    /**
     * Presents a Popup that will ask for a containres name.
     * @param title
     * @param [okButton]        default "Fertig"
     * @param [placeholder]
     * @param [currentName]
     * @returns {*}
     */


    MediaServerComponent.prototype.showNamePopup = function showNamePopup(title, okButton, placeholder, currentName) {
        var promise,
            content = {
                title: title,
                // _('media.playlist.save.name'),
                input: {
                    id: "name",
                    type: "text",
                    required: true,
                    // placeholder:  _("media.playlist.save.name"),
                    validationRegex: Regex.NAME
                },
                buttonOk: !!okButton ? okButton : _("finish"),
                buttonCancel: true
            };

        if (!!placeholder) {
            content.input.placeholder = placeholder;
        }

        if (!!currentName) {
            content.value = currentName;
        }

        promise = NavigationComp.showPopup(content, PopupType.INPUT);
        return promise.then(function (res) {
            return res.result;
        }.bind(this));
    };

    MediaServerComponent.prototype.createContainer = function createContainer(contentType, mediaTypeDetails, containerName) {
        if (!this.mediaEditExtension) {
            this.mediaEditExtension = this._getEditExtForContentType(contentType, mediaTypeDetails);
        }

        return this.mediaEditExtension.createContainer.apply(this.mediaEditExtension, [mediaTypeDetails, containerName]);
    };

    MediaServerComponent.prototype.deleteContainer = function deleteContainer(contentType, mediaTypeDetails, containerId) {
        if (!this.mediaEditExtension) {
            this.mediaEditExtension = this._getEditExtForContentType(contentType, mediaTypeDetails);
        }

        return this.mediaEditExtension.deleteContainer.apply(this.mediaEditExtension, [mediaTypeDetails, containerId]);
    };

    MediaServerComponent.prototype.startEditing = function startEditing(contentType, mediaTypeDetails, containerId, noDataNeeded) {
        if (this.mediaEditExtension && this.mediaEditExtension.isActive()) {
            throw new Error("cannot startEditing while the editing is still in charge!");
        } else if (!this.mediaEditExtension) {
            this.mediaEditExtension = this._getEditExtForContentType(contentType, mediaTypeDetails);
        }

        return this.mediaEditExtension.startEditing.apply(this.mediaEditExtension, [mediaTypeDetails, containerId, noDataNeeded]);
    };

    MediaServerComponent.prototype.stopEditing = function stopEditing(containerId) {
        if (!this.mediaEditExtension) {
            // we may receive a "stopEditing" due to destroy of the View (not by toggling edit mode with the button)
            return true;
        }

        var result = this.mediaEditExtension.stopEditing.apply(this.mediaEditExtension, arguments);
        this.mediaEditExtension.destroy.apply(this.mediaEditExtension);
        delete this.mediaEditExtension;
        return result;
    };

    MediaServerComponent.prototype.renameContainer = function renameContainer(contentType, mediaTypeDetails, containerId, containerName) {
        this._checkEditExtension();

        return this.mediaEditExtension.renameContainer.apply(this.mediaEditExtension, [mediaTypeDetails, containerId, containerName]);
    };

    MediaServerComponent.prototype.removeItem = function removeItem(idx) {
        this._checkEditExtension();

        return this.mediaEditExtension.removeItem.apply(this.mediaEditExtension, arguments);
    };

    MediaServerComponent.prototype.moveItem = function moveItem(oldIdx, newIdx) {
        this._checkEditExtension();

        return this.mediaEditExtension.moveItem.apply(this.mediaEditExtension, arguments);
    };

    MediaServerComponent.prototype.prepareForAdding = function prepareForAdding() {
        this._checkEditExtension();

        return this.mediaEditExtension.prepareForAdding.apply(this.mediaEditExtension, arguments);
    };

    MediaServerComponent.prototype.addItem = function addItem(item) {
        this._checkEditExtension();

        return this.mediaEditExtension.addItem.apply(this.mediaEditExtension, arguments);
    };

    MediaServerComponent.prototype.getAddedItemsCount = function getAddedItemsCount() {
        this._checkEditExtension();

        return this.mediaEditExtension.getAddedItemsCount.apply(this.mediaEditExtension, arguments);
    };

    MediaServerComponent.prototype.finishedAdding = function finishedAdding() {
        this._checkEditExtension();

        return this.mediaEditExtension.finishedAdding.apply(this.mediaEditExtension, arguments);
    };

    MediaServerComponent.prototype._getEditExtForContentType = function _getEditExtForContentType(contentType, mediaTypeDetails) {
        this._restrictedFeature();

        return this._getPlaylistEditExtForService(mediaTypeDetails[MediaEnum.Attr.SERVICE._][MediaEnum.Attr.SERVICE.CMD]);
    };
    /**
     * Chooses based on thes serviceCmd what kind of target we're trying to edit.
     * @param serviceCmd    e.g. spotify, googlemusic, local, lms
     * @returns {*}
     * @private
     */


    MediaServerComponent.prototype._getPlaylistEditExtForService = function _getPlaylistEditExtForService(serviceCmd) {
        var ext;

        switch (serviceCmd) {
            case MediaEnum.Service.SPOTIFY:
                ext = initExtension(Components.MediaServer.extensions.SpotifyPlaylistEditExtension, this);
                break;

            default:
                // e.g. local
                ext = initExtension(Components.MediaServer.extensions.PlaylistEditExtension, this);
                break;
        }

        return ext;
    };

    MediaServerComponent.prototype._checkEditExtension = function _checkEditExtension() {
        if (!this.mediaEditExtension || !this.mediaEditExtension.isActive()) {
            throw new Error("Cannot perform edit method while no mediaEditExtension is active!");
        }
    };

    MediaServerComponent.prototype.getActiveEditingTarget = function getActiveEditingTarget() {
        var activeEditingObject = null;

        if (this.mediaEditExtension && this.mediaEditExtension.isActive()) {
            activeEditingObject = this.mediaEditExtension.getActiveTarget.apply(this.mediaEditExtension, arguments);
        }

        return activeEditingObject;
    }; // ----------------------------------------------------------------
    // ZoneGroupExt
    // ----------------------------------------------------------------


    MediaServerComponent.prototype.getCurrentGroups = function getGroups() {
        this._restrictedFeature();

        return this.extensions.zoneGroupExt.getCurrentGroups.apply(this.extensions.zoneGroupExt, arguments);
    }; // ----------------------------------------------------------------
    // VoiceRecorder
    // ----------------------------------------------------------------


    MediaServerComponent.prototype.uploadAudioFile = function uploadAudioFile() {
        return this.extensions.voiceRecorderExt.uploadAudioFile.apply(this.extensions.voiceRecorderExt, arguments);
    };

    MediaServerComponent.prototype.playRecordedFile = function playRecordedFile() {
        return this.extensions.voiceRecorderExt.playRecordedFile.apply(this.extensions.voiceRecorderExt, arguments);
    };

    MediaServerComponent.prototype.deleteFile = function deleteFile() {
        return this.extensions.voiceRecorderExt.deleteFile.apply(this.extensions.voiceRecorderExt, arguments);
    };

    MediaServerComponent.prototype.initVoiceRecorderExtension = function initVoiceRecorderExtension() {
        // This extension doesn't need an active connection with the Music Server
        this.extensions.voiceRecorderExt = initExtension(Components.MediaServer.extensions.VoiceRecorderExt, this);
    };

    MediaServerComponent.prototype.destroyAllExtensions = function destroyAllExtensions() {
        this.extensions = {};
    }; // ----------------------------------------------------------------
    // MediaSearchExt
    // ----------------------------------------------------------------


    MediaServerComponent.prototype.searchFor = function searchFor() {
        return this.extensions.searchExt.searchFor.apply(this.extensions.searchExt, arguments);
    };

    MediaServerComponent.prototype.saveKeyword = function saveKeyword() {
        return this.extensions.searchExt.saveKeyword.apply(this.extensions.searchExt, arguments);
    };

    MediaServerComponent.prototype.deleteKeywords = function deleteKeywords() {
        return this.extensions.searchExt.deleteKeywords.apply(this.extensions.searchExt, arguments);
    };

    MediaServerComponent.prototype.loadLastUsedKeywords = function loadLastUsedKeywords() {
        return this.extensions.searchExt.loadLastUsedKeywords.apply(this.extensions.searchExt, arguments);
    };

    MediaServerComponent.prototype.browseItem = function browseItem() {
        return this.extensions.searchExt.browseItem.apply(this.extensions.searchExt, arguments);
    }; // ----------------------------------------------------------------
    // PopupExt
    // ----------------------------------------------------------------


    MediaServerComponent.prototype.showContent = function showContent() {
        return this.extensions.popupExt.showContent.apply(this.extensions.popupExt, arguments);
    }; // ----------------------------------------------------------------
    // PersistenceComp - Stub
    // ----------------------------------------------------------------

    /**
     * @see PersistenceComp.loadFile
     */


    MediaServerComponent.prototype.loadFile = function () {
        return PersistenceComponent.loadFile.apply(PersistenceComponent, arguments);
    };
    /**
     * @see PersistenceComp.saveFile
     */


    MediaServerComponent.prototype.saveFile = function () {
        return PersistenceComponent.saveFile.apply(PersistenceComponent, arguments);
    }; // ----------------------------------------------------------------
    // Private
    // ----------------------------------------------------------------

    /**
     * Helper method, is called whenever a method is only available locally. Should only occur during development, as
     * the release is to make sure the user cannot start anything only available locally while being connected externaly.
     * @private
     */


    MediaServerComponent.prototype._restrictedFeature = function _restrictedFeature() {
        if (this.isRestricted()) {
            throw {
                message: "This method is only available via direct communication!",
                name: "Restricted Feature",
                stack: "Look into the MediaServerComponent.."
            };
        }
    };
    /**
     * List of Events that are sent on this extensionChannel.
     * @type {{ConnEstablished: string, ConnClosed: string, NotReachable: string, ServerInfoReceived: string, FeatureCheckingReady: string, ReachModeChanged: string, ServerStateReceived: string, SendMessage: string, EventReceived: string, ResultReceived: string, MessageReceived: string, ResultErrorReceived: string, ShowPopup: string, ResetMediaApp: string, AvailableUpdateReceived: string, LibraryErrorReceived: string, InitialAudioScreenLoaded: string, ZoneChanged: string, TaskRecorderStart: string, TaskRecorderEnd: string, ServiceChanged: string, Start: string, Stop: string}}
     */


    MediaServerComponent.prototype.ECEvent = {
        ConnEstablished: "ConnEstablished",
        ConnClosed: "ConnClosed",
        NotReachable: "NotReachable",
        ServerInfoReceived: "ServerInfoReceived",
        // used to tell the component the info was received
        FeatureCheckingReady: "FeatureCheckingReady",
        // used by the components to tell the extensions, they might now access feature checking
        ReachModeChanged: "ReachModeChanged",
        // local or remote
        ServerStateReceived: "ServerStateReceived",
        SendMessage: "SendMessage",
        MessageSent: "MessageSent",
        EventReceived: "EventReceived",
        ResultReceived: "ResultReceived",
        MessageReceived: "MessageReceived",
        // unparsed text-message received from the socket,
        ResultErrorReceived: "ResultErrorReceived",
        // the result of a command came back with an error
        ShowPopup: "ShowPopup",
        ResetMediaApp: "ResetMediaApp",
        // when the mediaServer restarts or the Miniserver changes, invalidates all caches
        AvailableUpdateReceived: "AvailableUpdateReceived",
        // internal event sent out by the popupext (Music Server publishes updates by popup-events)
        LibraryErrorReceived: "LibraryErrorReceived",
        // when an popup event is received that indicates the library is still scanned or has discovered an invalid file.
        InitialAudioScreenLoaded: 'InitialAudioScreenLoaded',
        ZoneChanged: 'ZoneChanged',
        TaskRecorderStart: "TaskRecorderStart",
        TaskRecorderEnd: "TaskRecorderEnd",
        ServiceChanged: "ServiceChanged",
        // new, edited, removed
        Start: "Start",
        Stop: "Stop",
        VerifyConnectivity: "VerifyConnectivity" //TODO-woessto: https://www.wrike.com/open.htm?id=91947923 Temporary fix until "ConnClosed" or alike is dispatched properly on the component.

    };
    /**
     * Feature Checking of MediaServer, uses the Feature list in MediaEnum to
     * specify what features are supported on the current MediaServer and what
     * features are not.
     * @type {{_features: (MediaEnum.Features|{THIS_APP, AWESOME_FEATURE, DYNAMIC_INPUTS, LIBRARY_PLAY, PAGED_QUEUE, ADD_ZONEFAV_DIRECT, DISABLE_RADIOS, DISABLE_AIRPLAY, SEARCH_RADIO, GETMEDIAFOLDER_ERROR, REMOVE_GROUP_MASTER, ADD_EXTERNAL_ID, SEARCH_LIB_IMPROVED, SEARCH_SERVICES_IMPROVED, SEARCH_ALL, LIB_SCAN_HANDLING, AUDIO_ZONE_SOURCE, NEW_LOGIN_HANDLING}), update: MediaServerComponent.Feature.updateFeatures}}
     */

    MediaServerComponent.prototype.Feature = {
        _features: MediaEnum.Features,

        /**
         * Updates the feature list.
         * @param serverInfo    contains the firmware and the api version.
         */
        update: function updateFeatures(serverInfo) {
            var firmwareVersion = new ConfigVersion(serverInfo.firmwareVersion);
            var apiVersion = new ConfigVersion(serverInfo.apiVersion.join('.'));

            for (var featureKey in this._features) {
                if (this._features.hasOwnProperty(featureKey)) {
                    var apiOkay = true;
                    var firmwareOkay = true;

                    if (this._features[featureKey].hasOwnProperty("api")) {
                        apiOkay = apiVersion.greaterThanOrEqualTo(this._features[featureKey].api);
                    }

                    if (this._features[featureKey].hasOwnProperty("firmware")) {
                        firmwareOkay = firmwareVersion.greaterThanOrEqualTo(this._features[featureKey].firmware);
                    }

                    this[featureKey] = apiOkay && firmwareOkay;
                }
            }
        },

        /**
         * Returns a set where each feature is a key that has either true or false as value. Depending on if this
         * feature is supported or not.
         * @param firmware
         * @param api
         * @returns {{}}
         */
        getFeatureSetForVersion: function getFeatureSetForVersion(firmware, api) {
            var features = {},
                firmwareVersion = new ConfigVersion(firmware),
                apiVersion = new ConfigVersion(api.join('.'));

            for (var featureKey in this._features) {
                if (this._features.hasOwnProperty(featureKey)) {
                    var apiOkay = true;
                    var firmwareOkay = true;

                    if (this._features[featureKey].hasOwnProperty("api")) {
                        apiOkay = apiVersion.greaterThanOrEqualTo(this._features[featureKey].api);
                    }

                    if (this._features[featureKey].hasOwnProperty("firmware")) {
                        firmwareOkay = firmwareVersion.greaterThanOrEqualTo(this._features[featureKey].firmware);
                    }

                    features[featureKey] = apiOkay && firmwareOkay;
                }
            }

            return features;
        }
    };
    /**
     * Assigns colors to zone groups from the GROUP_COLORS array.
     * @param groups    the array of groups to assign colors to
     * @param colors    list of colors (hex-strings) that should be used for the zone groups.
     */

    MediaServerComponent.prototype.assignZoneGroupColors = function assignZoneGroupColors(groups, colors) {
        var i,
            colorList = colors ? colors : MediaEnum.GROUP_COLORS;

        for (i = 0; i < groups.length; i++) {
            var groupObj = groups[i];
            groupObj.color = colorList[i % colorList.length];
        }
    };
    /**
     * send the play command in correct format
     * @param item
     * @param idx    index of the item in array
     * @param config contains -->
     *      lastSelectedItem
     *      serviceIdentifier
     *      username
     *      mediaType
     */


    MediaServerComponent.prototype.sendPlayerCommandFromType = function sendPlayerCommandFromType(item, idx, config) {
        var cmdObj = {},
            cmd;

        if (config && (config.lastSelectedItem.id || config.lastSelectedItem.audiopath)) {
            switch (config.mediaType) {
                case MediaEnum.MediaType.LIBRARY:
                    cmd = MediaEnum.AudioCommands.LIBRARY.PLAY + item.id;
                    break;

                case MediaEnum.MediaType.FAVORITES:
                    cmd = MediaEnum.AudioCommands.FAVORITE.PLAY + item.id;
                    break;

                case MediaEnum.MediaType.PLAYLIST:
                    cmd = MediaEnum.AudioCommands.SEARCH.PLAY + item.audiopath;
                    break;

                case MediaEnum.MediaType.SERVICE:
                    cmd = MediaEnum.AudioCommands.SERVICE.PLAY + config.serviceIdentifier + '/' + config.username + '/' + item.id;
                    break;

                case MediaEnum.MediaType.INPUT:
                    cmd = MediaEnum.AudioCommands.LINEIN.PLAY + item.id;
                    break;
            }

            if (idx >= 0) {
                cmdObj.cmd = MediaServerComp.appendParentInfo(cmd, idx, config.lastSelectedItem);
            } else {
                cmdObj.cmd = cmd;
            }

            return MediaServerComp.sendAudioZoneCommand(MediaServerComp.getActiveZoneControl().details.playerid, cmdObj);
        } else if (item.contentType === MediaEnum.MediaContentType.ZONE_FAVORITES) {
            cmd = Commands.format(Commands.MEDIACLIENT.SOURCE, item.slot);
            return MediaServerComp.sendCommandToServer(this.activeZoneControl, cmd);
        } else {
            if (item.hasOwnProperty("cmd")) {
                return MediaServerComp.sendAudioZoneCommand(this.activeZoneControl.details[MediaEnum.Event.PLAYER_ID], {
                    cmd: item.cmd
                });
            } else if (item.type === MediaEnum.FileType.LOCAL_FILE && !item[MediaEnum.Event.AUDIO_PATH]) {
                // Fallback for audio file in main library directory
                return MediaServerComp.sendAudioZoneCommand(this.activeZoneControl.details[MediaEnum.Event.PLAYER_ID], {
                    cmd: MediaEnum.AudioCommands.LIBRARY.PLAY + item[MediaEnum.Event.ID]
                });
            } else {
                return MediaServerComp.playFileUrl(item, config);
            }
        }
    };

    MediaServerComponent.prototype.copyRoomFavsFrom = function copyRoomFavsFrom(srcCtrl, vc) {
        var def = Q.defer(),
            availableZones = Object.values(MediaServerComp.getAvailableZones()).filter(function (zone) {
                return zone.playerid !== srcCtrl.details.playerid;
            }.bind(this)),
            details = {
                options: availableZones.map(function (zone) {
                    return {
                        title: zone.name,
                        subtitle: zone.groupDetail,
                        clickable: true,
                        zone: zone
                    };
                }.bind(this)),
                title: _("favorites.title"),
                headerTitle: "",
                headerDescription: _("media.zone.fav.get-from.desc", {
                    zoneName: srcCtrl.name
                }),
                mode: GUI.SelectorScreenMode.CONFIRMED,
                deferred: def
            };
        vc.showState(ScreenState.SelectorScreen, null, details);
        def.promise.then(function (result) {
            return this.sendMediaServerCommand(Commands.format(MediaEnum.Commands.COPY_ZONE_FAVS, result[0].option.zone.playerid, srcCtrl.details.playerid));
        }.bind(this));
    };

    MediaServerComponent.prototype.copyRoomFavsTo = function copyRoomFavsTo(srcCtrl, vc) {
        var def = Q.defer(),
            availableZones = Object.values(MediaServerComp.getAvailableZones()).filter(function (zone) {
                return zone.playerid !== srcCtrl.details.playerid;
            }.bind(this)),
            details = {
                options: availableZones.map(function (zone) {
                    return {
                        title: zone.name,
                        subtitle: zone.groupDetail,
                        clickable: true,
                        zone: zone
                    };
                }.bind(this)),
                title: _("favorites.title"),
                headerTitle: "",
                headerDescription: _("media.zone.fav.send-to.desc", {
                    dstZoneName: this.activeZoneControl.name
                }),
                mode: GUI.SelectorScreenMode.CONFIRMED,
                radioMode: GUI.TableViewV2.Cells.CheckableCell.RadioMode.INACTIVE,
                deferred: def
            };
        vc.showState(ScreenState.SelectorScreen, null, details);
        def.promise.then(function (results) {
            return this.sendMediaServerCommand(Commands.format(MediaEnum.Commands.COPY_ZONE_FAVS, srcCtrl.details.playerid, results.map(function (result) {
                return result.option.zone.playerid;
            }).join(",")));
        }.bind(this));
    };

    MediaServerComponent.prototype.addItemToFavorites = function addItemToFavorites(item) {
        // get name for favorite
        var hasPath = item.hasOwnProperty(MediaEnum.Event.AUDIO_PATH) && item[MediaEnum.Event.AUDIO_PATH] !== "";
        var identifier;
        var cmd;

        if (hasPath) {
            cmd = MediaEnum.Commands.FAVORITES.ADD_PATH;
            identifier = item[MediaEnum.Event.AUDIO_PATH];
        } else if (MediaServerComp.Feature.ADD_EXTERNAL_ID && this.contentType === MediaEnum.MediaContentType.SERVICE) {
            // there are services (like googlemusic) that don't provide an audioPath.
            cmd = MediaEnum.Commands.FAVORITES.ADD_EXTERNAL_ID;
            cmd += this.__mediaTypeDetails.service[MediaEnum.Attr.SERVICE.UID] + '/';
            identifier = item[MediaEnum.Event.ID];
        } else {
            cmd = MediaEnum.Commands.FAVORITES.ADD_ID;
            identifier = item[MediaEnum.Event.ID];
        }

        var name = MediaServerComp.getNameForItem(item);
        cmd = cmd + encodeURIComponent(name) + "/" + identifier;
        return MediaServerComp.sendMediaServerCommand(cmd).done(function () {
            MediaServerComp.invalidateContentCachesOf(MediaEnum.MediaContentType.FAVORITES, 0);

            var txt = _("media.item.add-to-favorites.confirmation", {
                name: name
            });

            MediaServerComp.showConfirmationFeedback(txt);
        }.bind(this), function () {
            console.error(this.name + ": Favorite-Command failed " + cmd);
        }.bind(this));
    };

    MediaServerComponent.prototype.getMediaBrowserForContentType = function getMediaBrowserForContentType(contentType) {
        var browser;

        try {
            browser = Controls.AudioZoneControl["MediaBrowser" + contentType];
        } catch (e) {
            // Just use the base...
            browser = Controls.AudioZoneControl.MediaBrowserBase;
        }

        return browser;
    };
    /**
     * Minimum required version of the miniserver firmware to work with MediaServerComp.
     * @type {string}
     */


    MediaServerComponent.prototype.requiredConfigVersion = "6.4.4.15";
    /**
     * Minimum required API Version of the MediaServer to work with this MediaServerComp.
     * @type {number[]}
     */

    MediaServerComponent.prototype.supportedServerApiVersion = [2];
    /**
     * Attaches the service property for shortCuts items if needed
     * @param item
     */

    MediaServerComponent.prototype.getCellConfigWithServiceItem = function getCellConfigWithServiceItem(item) {
        var config = item.config;

        if (!MediaServerComp.Feature.V2_FIRMWARE) {
            if (item.contentType === MediaEnum.MediaContentType.SERVICE) {
                if (item.config.hasOwnProperty('lastSelectedItem') && !item.config.hasOwnProperty(MediaEnum.Attr.SERVICE._)) {
                    config[MediaEnum.Attr.SERVICE._] = item.config.lastSelectedItem;
                }
            }
        }

        return config;
    };

    Components.MediaServer = {
        Init: MediaServerComponent,
        extensions: {}
    };
    return Components;
}(window.Components || {});
