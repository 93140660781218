'use strict';

window.Components = function (Components) {
    class LegacySearchExt extends Components.MediaServer.extensions.SearchExtBase {
        constructor(component, extensionChannel) {
            super(...arguments);
            this._searchDefMap = {};
        }

        /**
         * Starts the search
         * returns {Promise}
         */
        searchFor(delegate, keyword, searchIdentifier) {
            var cmdParts = [MediaEnum.Commands.SEARCH.SELECTIVE, searchIdentifier, delegate.username, delegate.tag, encodeURIComponent(keyword), delegate.start, delegate.nitems],
                cmd = cmdParts.join("/");

            if (!this._searchDefMap.hasOwnProperty(cmd)) {
                this._searchDefMap[cmd] = Q.defer();
                this._searchDefMap[cmd].delegate = delegate;
                MediaServerComp.sendMediaServerCommand(cmd).done(function (result) {
                    this._searchDefMap[cmd].notify(this._processResult(result, this._searchDefMap[cmd].delegate.tag));

                    this._searchDefMap[cmd].resolve();

                    delete this._searchDefMap[cmd];
                }.bind(this), function (e) {
                    this._searchDefMap[cmd].reject(e);
                }.bind(this));
            }

            return this._searchDefMap[cmd].promise;
        }

        _processResult(result, searchIdentifier) {
            var update,
                resultObj = result.data[0],
                def = this._searchDefMap[result.command];

            if (def) {
                update = {
                    type: searchIdentifier,
                    tableContent: this._createSpecificTableContent(resultObj, resultObj.where, def.delegate)
                };
            }

            return update;
        }

        _createSpecificTableContent(result, type, delegate) {
            var sectionRightButtonTitle;

            if (result.results[0].totalitems > result.results[0].items.length) {
                sectionRightButtonTitle = _('media.global-search-button.show-more');
            }

            return [{
                type: type,
                sectionRightButtonTitle: sectionRightButtonTitle,
                rows: result.results[0].items.map(function (item) {
                    return this.createAudioResultCell(item, type, delegate);
                }.bind(this))
            }];
        }

        _getIdentifierFromType(type) {
            return type;
        }

        _getUsernameForType(type) {
            return this._searchDefMap[Object.keys(this._searchDefMap)[0]].delegate.username;
        }

        _getMediaBrowserFromType(type) {
            var ctor;

            switch (type) {
                case MediaEnum.SearchLoc.LIBRARY:
                case MediaEnum.SearchLoc.LMS:
                    ctor = Controls.AudioZoneControl.MediaBrowserLibrary;
                    break;

                case MediaEnum.SearchLoc.SPOTIFY:
                case MediaEnum.SearchLoc.RADIO:
                    ctor = Controls.AudioZoneControl.MediaBrowserService;
                    break;

                default:
                    ctor = Controls.AudioZoneControl.MediaBrowserBase;
                    break;
            }

            return ctor;
        }

        _getContentTypeForType(type) {
            var contentType;

            switch (type) {
                case MediaEnum.SearchLoc.LIBRARY:
                case MediaEnum.SearchLoc.LMS:
                    contentType = MediaEnum.MediaContentType.LIBRARY;
                    break;

                case MediaEnum.SearchLoc.SPOTIFY:
                case MediaEnum.SearchLoc.RADIO:
                    contentType = MediaEnum.MediaContentType.SERVICE;
            }

            return contentType;
        }

    }

    Components.MediaServer.extensions.LegacySearchExt = LegacySearchExt;
    return Components;
}(window.Components || {});
