'use strict';

window.Components = function (Components) {
    class InputExt extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.inputs = []; // static input for music-Servers with API 1.0

            this.staticInputs = [{
                id: "1",
                name: _("media.section.line-in") + " " + 1,
                description: _("media.section.line-in") + " " + 1,
                enabled: true,
                cmd: 'linein',
                type: 0,
                isStatic: true
            }];
            this.promises = {};
            this.registerExtensionEv(this.component.ECEvent.FeatureCheckingReady, this._handleFeatureCheckingReady.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ConnClosed, this._handleConnectionClosed.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ResultReceived, function (evId, result) {
                this._handleResult(result);
            }.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ResultErrorReceived, function (evId, error) {
                this._handleResultError(error);
            }.bind(this));
        }

        getInputs() {
            Debug.Media.InputExt && console.log(this.name, "getInputs");
            var result = {};

            if (this.currentInputsDeferred != null) {
                Debug.Media.InputExt && console.log("     return deferred");
                return this.currentInputsDeferred;
            }

            this.currentInputsDeferred = Q.defer();

            if (this.inputs && this.featureCheckingReady) {
                Debug.Media.InputExt && console.log("     lineIn are cached && feature-checking is ready");
                result.data = this.inputs;
            } // everytime someone requests the lineIn, load them!


            this._requestInputs();

            result.promise = this.currentInputsDeferred.promise;
            return result;
        }

        updateInputEnabled(input) {
            return this._getInput(input.id).then(function (storedInput) {
                if (storedInput.enabled === input.enabled) {
                    // nothing changed
                    return null;
                } else {
                    storedInput.enabled = input.enabled;
                }

                var cmd = MediaEnum.Commands.INPUT.SET.CMD + input.id + MediaEnum.Commands.INPUT.SET.ENABLED + input.enabled;
                return this._sendCmd(cmd);
            }.bind(this));
        }

        /**
         * Checks and updates the input volume level. Only returns a promise if final
         * @param input     the updated input object
         * @param final     whether or not this setting is final
         * @returns {*}     either a promise (final) or nothing
         */
        updateInputVolume(input, final) {
            return this._getInput(input.id).then(function (storedInput) {
                if (storedInput.inputvolume === input.inputvolume) {
                    // nothing changed
                    return null;
                } else if (final) {
                    storedInput.inputvolume = input.inputvolume;
                }

                var volCmd = final ? MediaEnum.Commands.INPUT.SET.INPUT_VOLUME_FINAL : MediaEnum.Commands.INPUT.SET.INPUT_VOLUME;
                var cmd = MediaEnum.Commands.INPUT.SET.CMD + input.id + volCmd + input.inputvolume;

                var promise = this._sendCmd(cmd);

                if (!final) {
                    promise = null;
                }

                return promise;
            }.bind(this));
        }

        updateInputName(input) {
            return this._getInput(input.id).then(function (storedInput) {
                if (storedInput.name === input.name) {
                    // nothing changed
                    return null;
                } else {
                    storedInput.name = input.name;
                }

                var cmd = MediaEnum.Commands.INPUT.SET.CMD + input.id + MediaEnum.Commands.INPUT.SET.NAME + input.name;
                return this._sendCmd(cmd);
            }.bind(this));
        }

        updateInputType(input) {
            return this._getInput(input.id).then(function (storedInput) {
                if (storedInput.icontype === input.icontype) {
                    // nothing changed
                    return null;
                } else {
                    storedInput.icontype = input.icontype;
                }

                var cmd = MediaEnum.Commands.INPUT.SET.CMD + input.id + MediaEnum.Commands.INPUT.SET.TYPE + input.icontype;
                return this._sendCmd(cmd);
            }.bind(this));
        }

        _sendCmd(cmd) {
            if (this.promises.hasOwnProperty(cmd)) {
                throw "Previous operation not finished yet!";
            }

            this.promises[cmd] = Q.defer();
            this.channel.emit(this.component.ECEvent.SendMessage, {
                cmd: cmd
            });
            return this.promises[cmd].promise;
        }

        // -------------------------------------------------------------------------
        // PRIVATE
        // -------------------------------------------------------------------------
        _requestInputs() {
            Debug.Media.InputExt && console.log(this.name, "_requestInputs");

            if (!this.featureCheckingReady) {
                Debug.Media.InputExt && console.log("          feature-checking not ready yet. don't rq lineIn");
            } else if (MediaServerComp.Feature.DYNAMIC_INPUTS) {
                this.channel.emit(this.component.ECEvent.SendMessage, {
                    cmd: MediaEnum.Commands.INPUT.GET,
                    auto: true
                });
            } else {
                Debug.Media.InputExt && console.log("          no dynamic lineIn, return static one");
                setTimeout(function () {
                    if (this.currentInputsDeferred) {
                        Debug.Media.InputExt && console.log("         return static input - " + JSON.stringify(this.staticInputs));
                        this.currentInputsDeferred.resolve(this.staticInputs);
                        this.currentInputsDeferred = null;
                    } else {
                        Debug.Media.InputExt && console.log("          no deferred, don't set static input");
                    }
                }.bind(this), 200);
            }
        }

        // -------------------------------------------------------------------------
        // Handle Extension Channel Events
        _handleResult(result) {
            if (!result.hasOwnProperty("command")) {// nothing to do
            } else if (result.command === MediaEnum.Commands.INPUT.GET) {
                // get services result received
                Debug.Media.InputExt && console.log(this.name, "result received: " + JSON.stringify(result)); // Check if the icontype is correct and add the default type if it is missing or invalid

                result.data.forEach(function (input) {
                    if (!MediaEnum.LineInMap[input.icontype]) {
                        input.icontype = 0; // Default Type
                    }
                }.bind(this));
                this.inputs = result.data;

                if (this.currentInputsDeferred) {
                    this.currentInputsDeferred.resolve(this.inputs);
                    this.currentInputsDeferred = null;
                }
            } else if (this.promises.hasOwnProperty(result.command)) {
                if (this.promises[result.command]) {
                    this.promises[result.command].resolve();
                    delete this.promises[result.command];
                }
            }
        }

        _handleResultError(error) {
            if (!error.hasOwnProperty("command")) {// Nothing to do.
            } else if (error.command === MediaEnum.Commands.INPUT.GET) {
                // get services result received
                Debug.Media.InputExt && console.log(this.name, "error received: " + JSON.stringify(result));
                this.services = null;

                if (this.currentInputsDeferred) {
                    this.currentInputsDeferred.reject();
                    this.currentInputsDeferred = null;
                }
            } else if (this.promises.hasOwnProperty(error.command)) {
                if (this.promises[error.command]) {
                    this.promises[error.command].reject();
                    delete this.promises[error.command];
                }
            }
        }

        _handleFeatureCheckingReady() {
            Debug.Media.InputExt && console.log(this.name, "_handleFeatureCheckingReady");
            this.featureCheckingReady = true;

            if (this.currentInputsDeferred) {
                this._requestInputs();
            }
        }

        _handleConnectionClosed() {
            Debug.Media.InputExt && console.log(this.name, "_handleConnectionClosed");
            this.inputs = null;
            this.featureCheckingReady = false;
        }

        /**
         * Gets a specific input according to the provided ID
         * @note This function will also load the inputs if missing
         * @param id
         * @returns {*}
         * @private
         */
        _getInput(id) {
            var result;

            if (this.inputs === null) {
                result = this.getInputs().promise.then(function () {
                    return this._getInput(id);
                }.bind(this));
            } else {
                result = Q(this.inputs.find(function (obj) {
                    return obj.id === id;
                }));
            }

            return result;
        }

    }

    Components.MediaServer.extensions.InputExt = InputExt;
    return Components;
}(window.Components || {});
