'use strict';

window.Components = function (Components) {
    class ZoneFavoriteExt extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.currentZoneId = 0;
            this.registerExtensionEv(this.component.ECEvent.ZoneChanged, this._handleZoneChanged.bind(this));
        }

        /**
         * Called when an item is asked to be deleted. Returns whether or not the item
         * has to be removed from the data set right away or not.
         * @param item  the item to remove
         * @param row   the row where it was
         * @param details   the details of the calling screen.
         * @param viewCtrl  the viewController to present the screen state in.
         * @returns {*} whether or not to remove it from the views data set.
         */
        deleteZoneFavorite(item, row, details, viewCtrl) {
            var cmd = MediaEnum.AudioCommands.ZONE_FAV.DELETE + item[MediaEnum.Event.SLOT];
            Debug.Media.ZoneFavoriteExt && console.log(this.name + ": deleteZoneFavorite via " + cmd);
            var result = Q.all([true]);

            if (row === 0) {
                var popup = {
                    title: _("media.favorites.delete-default-favorite.title"),
                    message: _("media.favorites.delete-default-favorite.text"),
                    buttonOk: _("media.favorites.replace"),
                    buttonCancel: true,
                    icon: Icon.INFO
                };
                NavigationComp.showPopup(popup).done(function () {
                    this.selectNewZoneFavorite(1, details, viewCtrl);
                }.bind(this));
                return;
            } else {
                result = MediaServerComp.sendMediaServerCommand('audio/' + this.control.details.playerid + "/" + cmd);
            }

            return result;
        }

        /**
         * Will present the addItemScreen and also take care of handling when an item was selected for being
         * saved as favorite.
         * @param slot  what slot to save the item to.
         * @param details   the details of the calling screen.
         * @param viewCtrl  the viewController to present the screen state in.
         * @private
         */
        selectNewZoneFavorite(slot, details, viewCtrl) {
            Debug.Media.ZoneFavoriteExt && console.log(this.name + ": selectNewZoneFavorite: " + slot); // create a deferred that will resolve once an item was added. It will inform all UIs between this
            // view and the one where the item was selected on it.

            var addModeDeferred = Q.defer(); // like the one that the editableMediaContentScreen provides

            var newDetails = cloneObject(details),
                mediaScreen = Controls.AudioZoneControl.ScreenState.ADD_FAVORITES,
                addFn;
            newDetails.control = details.control;
            newDetails.targetMediaScreenState = Controls.AudioZoneControl.ScreenState.ADD_MEDIA; // prepare the save-favorite chain

            addFn = function (cbArgument) {
                // an item was selected, handle it (will open up a name popup and then send the save cmd)
                var prms = this._handleFavoriteSelected(cbArgument, slot); // now handle the result of the save command. It's important to return a promise, since e.g.
                // the cell that triggered it needs to know about it.


                return prms.then(function (item) {
                    // inform the UI that the item was successfully added
                    addModeDeferred.notify(item); // end the add mode, one zone-favorite per slot is enough.

                    addModeDeferred.resolve(item);
                }.bind(this), function (err) {
                    console.error(this.name + ": something went wrong, either while no name was agreed upon or saving the favorite failed"); // if there was no name agreed upon, don't stop everything.

                    if (err !== "cancel") {
                        addModeDeferred.reject();
                    }

                    throw err;
                }.bind(this));
            }.bind(this); // Prepare the details for the addItemScreen


            newDetails.addTargetName = _("media.pick-zone-fav.title", {
                pos: slot,
                room: this.control.name
            });
            newDetails.addFn = addFn; // fn to call when an item is to be added

            newDetails.addPromise = addModeDeferred.promise;
            viewCtrl.showState(mediaScreen, null, newDetails);
            return addModeDeferred.promise;
        }

        /**
         * Called as soon as an item has been selected to be added as zoneFavorite
         * @param cbArgument    the arg containing all info on the selected item
         * @param slot          what slot the new item is for
         * @private
         */
        _handleFavoriteSelected(cbArgument, slot) {
            // first of all, acquire a name for this new favorite.
            var namePromise = this._requestNameForZoneFavorite(cbArgument, slot); // as soon as we've agreed upon a name, save it via _handleAddItem


            return namePromise.then(function (resultObj) {
                return this._handleAddItem(slot, cbArgument.item, cbArgument.contentType, resultObj.result, cbArgument.mediaTypeDetails ? cbArgument.mediaTypeDetails : null);
            }.bind(this));
        }

        /**
         * Will present a popup asking the user to either confirm or modify the suggested name for this zone favorite.
         * @param cbArgument    the arg-obj containing the mediaTypeDetails, contentType and item of the candidate
         * @param slot          where to store the new zone favorite to.
         * @returns {*}         a promise that will either fail or succeed.
         * @private
         */
        _requestNameForZoneFavorite(cbArgument, slot) {
            var item = cbArgument.item,
                name = MediaServerComp.getNameForItem(item),
                content;
            content = {
                title: _('media.favorites.new-name.title', {
                    slot: slot
                }),
                input: {
                    id: "name",
                    required: true,
                    placeholder: _("media.favorites.new-name.placeholder"),
                    value: name,
                    validationRegex: Regex.TEXT,
                    // the music server is far more tolerant to special characters than the miniserver.
                    filterRegex: Regex.TEXT_FILTER
                },
                buttonOk: _("save"),
                buttonCancel: true,
                icon: Icon.INFO,
                color: window.Styles.colors.green
            };
            return NavigationComp.showPopup(content, PopupType.INPUT);
        }

        /**
         * Launches an save zoneFavorite cmd for the details provided. It'll either resolve with the item that
         * was added or it'll reject. If it resolves it will also take care of updating the views data.
         * @param slot          where to save the favorite to
         * @param item          what item to save as new favorite
         * @param contentType   the contentType where the item was taken from (service, playlist, ..)
         * @param name          the name for the newly created favorite
         * @param mediaTypeDetails      the mediatypedetails, e.g. when adding a service item or a playlist.
         * @returns {*}         the promise that'll resolve or reject.
         * @private
         */
        _handleAddItem(slot, item, contentType, name, mediaTypeDetails) {
            Debug.Media.ZoneFavoriteExt && console.log(this.name + ": _handleAddItem - " + slot + ": " + JSON.stringify(item));
            var def = Q.defer(),
                playerid = this.control.details.playerid,
                cmd = MediaServerComp.getZoneFavoriteSaveCommand(slot, playerid, item, name, contentType, mediaTypeDetails); // check if a command was found for this item

            if (cmd !== null) {
                MediaServerComp.sendAddZoneFavoriteCmd(cmd, item, name, slot, this.control).done(function () {
                    def.resolve(item);
                }.bind(this), function () {
                    def.reject();
                });
            } else {
                console.error(this.name + ": Could not find a save zoneFavorite command! won#t save it");
                setTimeout(function () {
                    def.reject();
                }, 0);
            }

            return def.promise;
        }

        // ---------------------------------------------------------------
        //            Handling events
        // ---------------------------------------------------------------

        /**
         * Called whenever the zoneChanged event is fired.
         * @param evId          unused
         * @param zoneControl   either null if the zone view was left, or the active zone.
         * @private
         */
        _handleZoneChanged(evId, zoneControl) {
            var playerid = zoneControl ? zoneControl.details.playerid : -1;
            Debug.Media.ZoneFavoriteExt && console.log(this.name + ": _handleZoneChanged: " + playerid);
            this.currentZoneId = playerid;
            this.control = zoneControl;
        }

    }

    Components.MediaServer.extensions.ZoneFavoriteExt = ZoneFavoriteExt;
    return Components;
}(window.Components || {});
