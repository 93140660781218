'use strict';

window.Components = function (Components) {
    class MediaEditBase extends Components.Extension {
        constructor() {
            super(...arguments);
            this.mediaTypeDetails = null;
            this.containerId = null;
            this.content = null; // the content obj (incl. totalitems and items)

            this.modCntr = 0; // modification counter.

            this.itemsAddedCntr = 0; // counts how many individual items have been added so far.

            this.commands = []; // stores the deferreds and their cmds. don't use a map, the order of the cmds matters.

            this.registerExtensionEv(this.component.ECEvent.EventReceived, this._handleEventReceived.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ResultReceived, this._handleResultReceived.bind(this));
            this.registerExtensionEv(this.component.ECEvent.ResultErrorReceived, this._handleResultErrorReceived.bind(this));
        }

        // -------------------------------------------------------
        //            Public Methods
        // -------------------------------------------------------
        // --------------- Container Commands ---------------------

        /**
         * Creates a new container (e.g. a playlist) and returns it.
         * @param mediaTypeDetails
         * @param containerName
         * @result {*} a promise that resolves with an ID once the command has responded.
         */
        createContainer(mediaTypeDetails, containerName) {
            Debug.Media.Editor && console.log(this.name + ": createContainer: " + mediaTypeDetails + " - " + containerName); // set the mediaTypeDetails (needed later to check if the response is handled) but don't start the edit mode.

            this._setMediaTypeDetails(mediaTypeDetails); // if the containerName doesn't meet the music servers requirements, it'll replace unsupported characters.


            return this._sendCreateCmd(containerName).then(function (res) {
                Debug.Media.Editor && console.log(this.name + ": createContainer returned " + JSON.stringify(res));
                var newCntr = cloneObject(res);
                newCntr[MediaEnum.Event.FILE_TYPE] = MediaEnum.FileType.PLAYLIST_EDITABLE; //  should suit all subclasses.

                newCntr[MediaEnum.Event.ID] = res[this.getContainerIdAttribute()];
                newCntr.contentType = this.getContentType();

                this._syncRoot(this._addToRootContent(newCntr), MediaEnum.Attr.Container.ActionType.CREATE);

                return newCntr;
            }.bind(this));
        }

        /**
         * Renames the container that is currently being edited. The promise might resolve with a different
         * name than we passed in, as the desired name might already be taken.
         * @param mediaTypeDetails
         * @param containerId       the id of the container to rename
         * @param newName           the desired new name fo the container
         * @returns {*|deferred.promise|{then, catch, finally}}
         */
        renameContainer(mediaTypeDetails, containerId, newName) {
            Debug.Media.Editor && console.log(this.name + ": renameContainer: " + mediaTypeDetails + " - " + newName); // set the mediaTypeDetails (needed later to check if the response is handled) but don't start the edit mode.

            this._setMediaTypeDetails(mediaTypeDetails); // set up the command


            var cmd = containerId + "/" + newName,
                confirmedName; // if the newName doesn't meet the music servers requirements, it'll replace unsupported characters.

            return this._sendCommand(this.getRenameContainerCommand(), cmd).then(function (result) {
                confirmedName = result[MediaEnum.Attr.Container.NAME];
                console.log(this.name + ": renameContainer returned " + JSON.stringify(result));

                this._syncRoot(this._renameItemInRootContent(containerId, confirmedName), MediaEnum.Attr.Container.ActionType.RENAME);

                return confirmedName;
            }.bind(this));
        }

        /**
         * Deletes an existing container (e.g. a playlist)
         * @param mediaTypeDetails
         * @param containerId
         * @result {*} a promise that resolves once the command has responded.
         */
        deleteContainer(mediaTypeDetails, containerId) {
            Debug.Media.Editor && console.log(this.name + ": deleteContainer: " + mediaTypeDetails + " - " + containerId); // set the mediaTypeDetails (needed later to check if the response is handled) but don't start the edit mode.

            this._setMediaTypeDetails(mediaTypeDetails); // if the containerName doesn't meet the music servers requirements, it'll replace unsupported characters.


            return this._sendCommand(this.getDeleteContainerCommand(), containerId).then(function (result) {
                // update the content --> {"playlistid":67569,"cmd":"local","user":"nouser","action":"delete"}
                this._syncRoot(this._removeFromRootContent(containerId), MediaEnum.Attr.Container.ActionType.DELETE);

                return result;
            }.bind(this));
        }

        // --------------- Content Editing Handling ---------------------

        /**
         * returns if this editExtension has been started or not.
         * @returns {boolean}
         */
        isActive() {
            return this.containerId !== null;
        }

        /**
         * Returns an object containing the containerId and the mediaTypeDetails of the container that is
         * currently being edited.
         * @returns {{}}
         */
        getActiveTarget() {
            var trgt = {};
            trgt.containerId = this.containerId;
            trgt.mediaTypeDetails = this.mediaTypeDetails;
            return trgt;
        }

        /**
         * Returns the ID of the root container (the one that contains all containers)
         * @returns {*} the id of the root container (e.g. 0 for the library)
         */
        getRootContainerId() {
            return 0;
        }

        /**
         * Will prepare the extension for editing a specific containerId. after this call, as soon as the promise
         * notifies, editing this specific container is possible. It resolves once it ends and rejects if it cannot
         * be started for some reason.
         * @param mediaTypeDetails  describes what type of content is edited. { service: {*} }
         * @param containerId       the id of the container who's content is edited.
         * @param [noDownload]      if this optional flag is set, no data will be downloaded for the container.
         * @returns {*|deferred.promise|{then, catch, finally}}
         */
        startEditing(mediaTypeDetails, containerId, noDownload) {
            Debug.Media.Editor && console.log(this.name + ": startEditing: " + containerId + (noDownload ? "- NO DOWNLOAD" : ""));

            if (this.isActive()) {
                throw new Error("Cannot startEditing, editing of " + this.containerId + " is still active!");
            }

            if (!containerId) {
                throw new Error("Cannot startEditing if no containerId is provided!");
            }

            var cntntPromise, cntnt, startPromise;
            this.editModeDeferred = Q.defer(); // If noDownload is set, it means that items that may be added during this edit mode have not been
            // published to the lists using deferreds, meaning that we need to tell views to reload their data
            // when we're done.

            this.shouldDispatchEditEnd = !!noDownload;
            this.mediaTypeDetails = mediaTypeDetails;
            this.containerId = containerId;
            this.modCntr = 0; // acquire list of items

            cntnt = this._getContent(noDownload); // if the data returned is already fully loaded (e.g. when loading an empty local playlist)

            if (cntnt.data && cntnt.data.items.length === cntnt.data.totalitems) {
                Debug.Media.Editor && console.log("    contentOld of " + containerId + " fully loaded already.");
                cntntPromise = true;
            } else {
                Debug.Media.Editor && console.log("    data not yet loaded. " + (noDownload ? "Don't download" : "Downloading content of " + containerId));
                cntntPromise = cntnt.promise;
            }

           cntntPromise = Q(cntntPromise).then(function () {
                Debug.Media.Editor && console.log("The cntntResultPromise has resolved!"); // re-request the content as a whole now to avoid having to stitch the intermediary results together.

                if (this.isActive()) {
                    // don't acquire the content if the editing is no longer active.
                    try {
                        this.content = MediaServerComp.getCurrentContent(this.getContentType(), this.containerId, this.mediaTypeDetails);
                    } catch (ex) {
                        if (!noDownload) {
                            throw ex; // if the data would have been needed, rethrow the exception!
                        } // if the data wasn't really needed, it's okay.

                    }
                }
            }.bind(this)); // inform others that we start editing this container - someone too might be editing it right now

            startPromise = this._sendUpdateCmd(MediaEnum.Commands.Container.Update.START).then(this._processStartResult.bind(this)); // wait for both the start request to respond and the content to fully load.

            Q.all([cntntPromise, startPromise]).done(function (succ) {
                Debug.Media.Editor && console.log("Both the start & contentOld-promise resolved, notify editDeferred.");
                this.editModeDeferred.notify(); // started
            }.bind(this), function (err) {
                this.editModeDeferred.reject(err); // not started
            }.bind(this));
            return this.editModeDeferred.promise;
        }

        /**
         * Will reset the editExtension and inform the Music Server that now, the editing is finished.
         * The Music Server will inform other UIs via a event that the container has changed (this may did
         * already happen before due to timeouts on the server side).
         * @param containerId
         */
        stopEditing(containerId) {
            Debug.Media.Editor && console.log(this.name + ": stopEditing: " + containerId);

            if (!this.isActive() || containerId !== this.containerId) {
                Debug.Media.Editor && console.log(" editing already stopped or different container active. cntrId active (" + this.containerId + ")");
                return;
            }

            if (this.modCntr > 0) {
                // when the edit mode wasn't started from the containers current list view, the list could be
                // active in the background and won't know that data has changed. That is why a reload event
                // needs to be dispatched.
                if (this.shouldDispatchEditEnd) {
                    this._syncData(MediaEnum.ReloadCause.CONTENT_UPDATED);
                } // we only send the finish if we actually had changes


                this._sendUpdateCmd(MediaEnum.Commands.Container.Update.FINISH);
            } else {
                // we had no changes in the data, don't dispatch an reload event!
                this._sendUpdateCmd(MediaEnum.Commands.Container.Update.FINISH_NO_CHANGES);
            }

            this._endEditMode(MediaEnum.EditEndCause.STOPPED); // no need for syncing anything as it has been synced as we've edited the data.

        }

        // --------------- Content Editing Commands ---------------------

        /**
         * Will remove the item at the given index from the container. The internal dataset will be updated
         * right away, if the command succeeds the mediaEditExtension is also updated - if it fails, the edit
         * extension will reset it's internal dataset.
         * @param idx       the position of the item to remove from the cntr
         * @returns {*}     a promise that will resolve or reject depending on the success.
         */
        removeItem(idx) {
            this._requireEditMode("removeItem");

            this._requireContent();

            var removedItems, removedItem, resultPromise, rmArg;
            removedItems = this.content.items.splice(idx, 1);
            removedItem = removedItems[0];

            if (removedItem) {
                this.modCntr++;
                this.content.totalitems--;
                rmArg = this.getRemoveItemArgument(idx, removedItem);

                var cmdPromise = this._sendUpdateCmd(MediaEnum.Commands.Container.Update.REMOVE + rmArg);

                resultPromise = cmdPromise.then(function (result) {
                    this._syncData(MediaEnum.ReloadCause.WCHANGE);

                    return result;
                }.bind(this), function (err) {
                    this._editFailed();

                    return err;
                }.bind(this));
            }

            return resultPromise;
        }

        /**
         * Will move the item from the given index to another position in the container. The internal dataset
         * will be updated right away, if the command succeeds the mediaEditExtension is also updated - if it
         * fails, the editExtension will reset it's internal dataset.
         * @param oldIdx    the position of the item we want to move
         * @param newIdx    where it should be moved to
         * @returns {*}     a promise that will resolve or reject depending on the success.
         */
        moveItem(oldIdx, newIdx) {
            Debug.Media.Editor && console.log(this.name + ": moveItem: " + oldIdx + " -> " + newIdx);

            this._requireEditMode("moveItem");

            this._requireContent();

            this.modCntr++;
            var moved = this.content.items.splice(oldIdx, 1)[0];
            this.content.items.splice(newIdx, 0, moved);

            var cmdPromise = this._sendUpdateCmd(MediaEnum.Commands.Container.Update.MOVE + oldIdx + "," + newIdx);

            return cmdPromise.then(function (result) {
                this._syncData(MediaEnum.ReloadCause.CHANGED);

                return result;
            }.bind(this), function (err) {
                this._editFailed();

                return err;
            }.bind(this));
        }

        /**
         * Will send an add command to the media server and include it's response in the local dataset. The item
         * will be processed and appended to the cntr and also synced with the loaderExtension. If it fails, no
         * further action is required but rejecting the promise.
         * @param item                      the item to add to the cntr. May be browsable and consist of multiple sub-items.
         * @param [sourceMediaDetails]      needed to tell the MS if the item to add comes from the library or GM (if audioPath is missing)
         * @returns {*}         a promise that will return the result of the add cmd
         */
        addItem(item, sourceMediaDetails) {
            Debug.Media.Editor && console.log(this.name + ": addItem: " + JSON.stringify(item));

            this._requireEditMode("addItem");

            var def = Q.defer(),
                args = this._getAddArguments(item, sourceMediaDetails),
                promise,
                cmd = this.getUpdateCommand(); // the music server would filter that!


            if (item.id === this.containerId) {
                console.error("Cannot add an item to itself!");
                promise = def.promise;
                setTimeout(function () {
                    def.reject({
                        item: item,
                        cause: "Can't add the container itself!"
                    });
                }, 2);
                return promise;
            }

            if (args) {
                if (MediaServerComp.isFileTypeBrowsable(item[MediaEnum.Event.FILE_TYPE])) {
                    args = MediaEnum.Commands.Container.Update.ADD_BROWSABLE + args;
                } else {
                    args = MediaEnum.Commands.Container.Update.ADD_ITEM + args;
                }

                promise = this._sendCommand(cmd, this.containerId + "/" + args);
            } else {
                console.error("No audioPath: " + JSON.stringify(item));
                promise = def.promise;
                setTimeout(function () {
                    def.reject({
                        item: item,
                        cause: "AudioPath information missing!"
                    });
                }, 0);
            } // register for the result, if it succeeds, the item needs to be passed on to the loader.


            promise.done(function (result) {
                this._handleAddItemResponse(item, result[MediaEnum.Attr.Container.ITEMS]);
            }.bind(this));
            return promise;
        }

        /**
         * Used to perpare the addItem/addBrowsable arguments. if a path is provided the path is used as single
         * arguemnt. But if an ID is used, the Music Server needs to know if the item to add comes from the library
         * or from e.g. Google Music.
         * @param item
         * @param [sourceMediaDetails]      if an ID is used and this is missing, it is assumed that the source is the library.
         * @returns {*}
         * @private
         */
        _getAddArguments(item, sourceMediaDetails) {
            var path = item[MediaEnum.Event.AUDIO_PATH],
                id = item[MediaEnum.Event.ID],
                // id is used as fallback for library / googleMusic
                args = path;

            if (!path) {
                args = id; // if it's an ID the Music Server needs to know whether the item is form the Library or from GM

                var service = MediaEnum.Target.LIBRARY; // if sourceMediaDetails are provided. it's probably the library

                if (!!sourceMediaDetails && !!sourceMediaDetails.service) {
                    service = sourceMediaDetails.service[MediaEnum.Attr.SERVICE.CMD];
                }

                args = service + ":" + args;
            }

            return args;
        }

        /**
         * Will store an deferred that is used to notify the UI on new items that where added. "notify" is used
         * to publish an array of items that have been added.
         * @returns {*} a promise used to notify the UI
         */
        prepareForAdding() {
            if (!this.addItemsDeferred) {
                this.addItemsDeferred = Q.defer();
            }

            return this.addItemsDeferred.promise;
        }

        /**
         * Will return how many items have been added in the current "add session"
         * @returns {*|number}
         */
        getAddedItemsCount() {
            return this.itemsAddedCntr;
        }

        /**
         * Will resolve the addItemsDeferred, so all registered views know that we're done and no new
         * items will arrive.
         */
        finishedAdding() {
            this.addItemsDeferred && this.addItemsDeferred.resolve();
            this.addItemsDeferred = null;
            this.itemsAddedCntr = 0;
        }

        // -------------------------------------------------------
        //            Methods used by subclasses
        // -------------------------------------------------------

        /**
         * Returns the MediaContentType of these containers. e.g. MediaEnum.MediaContentType.PLAYLISTS
         * @returns {string}
         */
        getContentType() {
            throw new Error("getContentType needs to be implemented by the subclass!");
        }

        /**
         * Returns the update command used for containers (e.g. audio/cfg/playlist/update)
         * @returns {string}
         */
        getUpdateCommand() {
            throw new Error("getUpdateCommand needs to be implemented by the subclass!");
        }

        /**
         * Returns the command used to create a new container (e.g. audio/cfg/playlist/create)
         * @returns {string}
         */
        getCreateContainerCommand() {
            throw new Error("getCreateContainerCommand needs to be implemented by the subclass!");
        }

        /**
         * Returns the command used to delete a new container (e.g. audio/cfg/playlist/delete)
         * @returns {string}
         */
        getDeleteContainerCommand() {
            throw new Error("getDeleteContainerCommand needs to be implemented by the subclass!");
        }

        /**
         * Returns the command used to rename an existing container (e.g. audio/cfg/playlist/rename)
         * @returns {string}
         */
        getRenameContainerCommand() {
            throw new Error("getRenameContainerCommand needs to be implemented by the subclass!");
        }

        /**
         * Returns the name of the attribute identifying the container. e.g. "playlistid"
         * @returns {string}
         */
        getContainerIdAttribute() {
            throw new Error("getContainerIdAttribute needs to be implemented by the subclass!");
        }

        /**
         * Returns the name of id of the event that is sent out when a container changes e.g. "playlistchanged_event"
         * @returns {string}
         */
        getChangedEventId() {
            throw new Error("getChangedEventId needs to be implemented by the subclass!");
        }

        /**
         * Returns the remove argument for this item. Needs to be subclassable since e.g. spotify requires the
         * items ID instead of the index.
         * @param idx       what position was the item to be removed
         * @param item      the item object that is to be removed
         * @returns {string}    the argument needed for the update command that removes an item.
         */
        getRemoveItemArgument(idx, item) {
            return idx;
        }

        // -------------------------------------------------------
        //            Handling Events
        // -------------------------------------------------------

        /**
         * Processes "events" that where received by the app. E.g. an event might notify the extension that
         * another UI also started editing the very same container that this extension is currently editing.
         * @param evId      not important
         * @param event     the event data - check if it is important for this extension
         * @private
         */
        _handleEventReceived(evId, event) {
            try {
                if (this._doesHandleEvent(event)) {
                    this._respondToEvent(event[this.getChangedEventId()][0]);
                }
            } catch (exc) {
                console.error(this.name + " could not process the event " + JSON.stringify(event));
                console.error(exc);
            }
        }

        /**
         * Processes "results" that where received by the app. Check if this result is from one of the commands
         * this extensions did send out.
         * @param evId      not important
         * @param result    the result data - check if it is important for this extension
         * @private
         */
        _handleResultReceived(evId, result) {
            try {
                if (this._doesHandleResponse(result)) {
                    var resultObjects = result.data;

                    for (var i = 0; i < resultObjects.length; i++) {
                        this._processResult(resultObjects[i], result.command);
                    }
                }
            } catch (exc) {
                console.error(this.name + " could not process the result of '" + result.oldCommand + "! ");
                console.error(exc);
            }
        }

        /**
         * Processes "errors" that where received by the app. Check if this error concerns one of the commands
         * this extensions did send.
         * @param evId     not important
         * @param error    the error data - check if it is important for this extension
         * @private
         */
        _handleResultErrorReceived(evId, error) {
            try {
                if (this._doesHandleResponse(error)) {
                    var reasonObjects = error.reason;

                    for (var i = 0; i < reasonObjects.length; i++) {
                        this._processRequestError(reasonObjects[i], error.command);
                    }
                }
            } catch (exc) {
                console.error(this.name + " could not process the result of '" + error.oldCommand + "! ");
                console.error(exc);
            }
        }

        // --------------------------------------------------------------------------------
        //           PRIVATE
        // --------------------------------------------------------------------------------

        /**
         * Here we check if a response concerns one of the commands this extension did send earlier.
         * @param response      the response received (error or result)
         * @returns {boolean}
         * @private
         */
        _doesHandleResponse(response) {
            Debug.Media.Editor && console.log(this.name + ": _doesHandleResponse: " + JSON.stringify(response));
            var doHandle = false;

            if (response.hasOwnProperty(MediaEnum.Comm.CMD)) {
                doHandle = this._isInQueue(response.command);
            }

            Debug.Media.Editor && console.log("         handled: " + doHandle);
            return doHandle;
        }

        /**
         * An result to an command we did send earlier was received. Determine the corresponding deferred and
         * check if the result contains any infos on potential errors.
         * @param data
         * @param cmd
         * @private
         */
        _processResult(data, cmd) {
            Debug.Media.Editor && console.log(this.name + ": _processResult of " + cmd);

            var def = this._dequeueCmd(cmd);

            if (def) {
                if (data.hasOwnProperty(MediaEnum.Attr.Container.ERROR)) {
                    def.reject(data.error);
                } else {
                    def.resolve(data);
                }
            } else {
                console.error("Received a result that could not be linked to our sent cmds! " + cmd);
            }
        }

        /**
         * This method is used to deal with errors that occurred during the current media request. After this method
         * the current request has already been rejected. after this method the next request from the queue will be
         * processed.
         * @param error         the error that has occurred
         * @param cmd           the cmd that failed
         * @returns {boolean}   whether or not the error has already been processed
         * @private
         */
        _processRequestError(error, cmd) {
            var def = this._dequeueCmd(cmd);

            def && def.reject(error);
            return def !== null;
        }

        /**
         * Checks if the editExtension is affected by this event. E.g.
         * @param event
         * @returns {boolean}
         * @private
         */
        _doesHandleEvent(event) {
            Debug.Media.Editor && console.log(this.name + ": _doesHandleEvent: " + JSON.stringify(event));
            var doHandle = false,
                evData,
                cntrId,
                eventTarget,
                evId = this.getChangedEventId();

            if (event.hasOwnProperty(evId)) {
                evData = event[evId][0];
                cntrId = evData[this.getContainerIdAttribute()];
                eventTarget = this._readTargetUid(evData);
                doHandle = this._getCurrentTargetId() === eventTarget;
                doHandle &= this.containerId === cntrId;
            }

            Debug.Media.Editor && console.log("        doHandle: " + doHandle);
            return doHandle;
        }

        _respondToEvent(evData) {
            var action = evData[MediaEnum.Attr.Container.ACTION],
                editEndCause = MediaEnum.EditEndCause.UNKNOWN;

            switch (action) {
                case MediaEnum.Attr.Container.ActionType.START:
                case MediaEnum.Attr.Container.ActionType.UPDATE:
                    editEndCause = MediaEnum.EditEndCause.CONFLICT;
                    break;

                case MediaEnum.Attr.Container.ActionType.RENAME:
                    //TODO-woessto: don't end the edit mode, maybe just update the name!
                    editEndCause = null;
                    break;

                case MediaEnum.Attr.Container.ActionType.DELETE:
                    editEndCause = MediaEnum.EditEndCause.DELETED;
                    break;

                default:
                    break;
            } // There are case (renaming) where editing should not be ended.


            if (editEndCause) {
                this._endEditMode(editEndCause);
            }
        }

        /**
         * This method processes the result received from the start command. If the action contained is anything
         * else than "START", it means the editing could not be started.
         * @param result    the result received.
         * @returns {*}     a promise that resolves if it succeeded and resolves if it failed.
         * @private
         */
        _processStartResult(result) {
            var validationDef = Q.defer(),
                action = result[MediaEnum.Attr.Container.ACTION];

            if (action !== MediaEnum.Attr.Container.ActionType.START) {
                console.error("Edit Mode not started, action: " + action); // reload the data - someone else might have changed it

                MediaServerComp.invalidateContentCachesOf(this.getContentType(), this.containerId, this.mediaTypeDetails, MediaEnum.ReloadCause.CONTENT_UPDATED);
                result.cause = MediaEnum.EditEndCause.MODIFIED; // reject the edit mode start promise!

                setTimeout(validationDef.reject.bind(validationDef, result), 0);
            } else {
                // all okay, we can start editing.
                setTimeout(validationDef.resolve.bind(validationDef, result), 0);
            }

            return validationDef.promise;
        }

        // --------------------------------------------------------------------------------

        /**
         * Acquires the current content from the mediaLoader.
         * @param noDownload        if this flag is set, no download will be started, only data cached is returned.
         * @returns {*|{promise}}
         * @private
         */
        _getContent(noDownload) {
            var result, cache, cacheDef;

            if (noDownload) {
                try {
                    cache = MediaServerComp.getCurrentContent(this.getContentType(), this.containerId, this.mediaTypeDetails); // there is data. respond via promise.

                    cacheDef = Q.defer();
                    result = {
                        data: cache,
                        promise: cacheDef.promise
                    };
                    cacheDef.resolve(cache);
                } catch (ex) {
                    // no data stored, don't download.
                    result = {
                        data: null,
                        promise: Q.all([true])
                    };
                }
            } else {
                result = MediaServerComp.requestContent(this.getContentType(), this.containerId, MediaEnum.DEFAULT_RQ_SIZE, this.mediaTypeDetails);
            }

            return result;
        }

        /**
         * Throws an exception if the edit mode is not active.
         * @param operation
         * @private
         */
        _requireEditMode(operation) {
            if (!this.isActive()) {
                console.error(this.name + ": _requireEditMode failed! Cannot " + operation);
                throw new Error("Edit mode not active! Cannot " + operation);
            }
        }

        _requireContent() {
            if (!this.content) {
                console.error(this.name + ": _requireContent failed! Make sure the editMode started with data download!");
                throw new Error("Content not available!");
            }
        }

        /**
         * This is called when the addItem message has responded. It will update the data in the loader.
         * @param source        the item that was sent to the mediaServer to add
         * @param [items]       optionally provided if the item added contained more than one items
         * @private
         */
        _handleAddItemResponse(source, items) {
            var newItems = items; // adding browsable items results in an item array being returned

            if (!items) {
                // if a single item (e.g. a track) was added, there is no items array, the source is the new item.
                newItems = [source];
            }

            Debug.Media.Editor && console.log(this.name + ": _handleAddItemResponse: added " + JSON.stringify(source) + " (" + newItems.length + " items total)"); // decode new items

            newItems.forEach(function (item) {
                MediaServerComp.decodeItem(item);
            });
            this.itemsAddedCntr += newItems.length;
            this.modCntr++; // when adding items while not explicitly starting the edit mode (and therefore viewing the
            // current container), the content is not needed, not loaded and therefore not available.

            if (this.content) {
                this.content.items = this.content.items.concat(newItems);
                this.content.totalitems = this.content.items.length;
            } //notify the UI that there is new data to show! (don't resolve, there might be more items to come)


            if (this.addItemsDeferred) {
                this.addItemsDeferred.notify(newItems);
            }

            this._syncData();
        }

        /**
         * Sends an update command with the given arguments for the current container id to the music server.
         * The promise returned will resolve according to the commands result.
         * @param args     e.g. "move:0:8", "finish", "start"
         * @returns {*|deferred.promise|{then, catch, finally}}
         * @private
         */
        _sendUpdateCmd(args) {
            var cmd = this.containerId + "/" + args;
            return this._sendCommand(this.getUpdateCommand(), cmd);
        }

        /**
         * Will send the create container command to the music server.
         * @param containerName     the name of the new container
         * @returns {Promise}
         * @private
         */
        _sendCreateCmd(containerName) {
            return this._sendCommand(this.getCreateContainerCommand(), encodeURIComponent(containerName));
        }

        /**
         * Builds up the full command including the target service UID (e.g. "library/nouser"), sends it to
         * the music server and on the response it'll resolve or reject the promise returned.
         * @param command   e.g. "audio/cfg/playlist/create", "audio/cfg/playlist/update"
         * @param args      e.g. "New Playlists Name", "1234/move:0:8"
         * @returns {deferred.promise|{then, catch, finally}}   the promise.
         * @private
         */
        _sendCommand(command, args) {
            var fullCmd = command + "/" + this._getCurrentTargetId(),
                deferred = Q.defer();

            if (args) {
                fullCmd = fullCmd + "/" + args;
            }

            Debug.Media.Editor && console.log(this.name + ": _sendCommand: " + fullCmd);

            this._enqueueCmd(fullCmd, deferred);

            this.channel.emit(this.component.ECEvent.SendMessage, {
                cmd: fullCmd
            });
            return deferred.promise;
        }

        /**
         * Returns the target id of the container that is being edited. E.g. "library/nouser"
         * @returns {*}
         * @private
         */
        _getCurrentTargetId() {
            return this._readTargetUid(this.mediaTypeDetails[MediaEnum.Attr.SERVICE._]);
        }

        /**
         * Returns the target UID (target/user, e.g. local/nouser) of the mediaTypeDetails provided
         * @param serviceObj    a service object that contains either 'cmd' and optionally a 'user' or 'uid'
         * @returns {*}
         * @private
         */
        _readTargetUid(serviceObj) {
            var targetid = serviceObj[MediaEnum.Attr.SERVICE.UID],
                cmd = serviceObj[MediaEnum.Attr.SERVICE.CMD],
                user = serviceObj[MediaEnum.Attr.SERVICE.USER];

            if (!targetid) {
                targetid = cmd + "/" + (user ? user : MediaEnum.NOUSER);
            }

            return targetid;
        }

        /**
         * Will inform the mediaLoader that the data has changed and it'll update it's data locally, this way
         * reloads of large lists aren't needed as long as the update commands work as expected.
         * @param [cause]   if a cause is provided in an syncData, affected lists on the UI will reload their contentOld.
         *                  But this is really only necessary in very limited situations.
         * @private
         */
        _syncData(cause) {
            // a reload (with reason) is not needed, the UI is updated properly without it due to the promise results.
            // there might be no data available (adding items without viewing the container).
            if (this.content) {
                MediaServerComp.updateContent(this.getContentType(), this.containerId, this.mediaTypeDetails, this.content, cause);
            }
        }

        /**
         * When an edit command failed (such as move or remove), the edit extensions dataset has already been modified,
         *
         * Will reset the edit extensions dataset to the one that the loader currently has.
         * @private
         */
        _editFailed() {
            // only download the content if it was loaded before editing did fail!
            if (this.content) {
                this.content = MediaServerComp.getCurrentContent(this.getContentType(), this.containerId, this.mediaTypeDetails);
            } //The UI will know that it needs to reload due to the failed promise.

        }

        /**
         * Resets all data in this extension so that the edit mode is no longer active, also informs the deferred
         * @param cause why the edit mode was ended.
         * @private
         */
        _endEditMode(cause) {
            Debug.Media.Editor && console.log(this.name + ": _endEditMode: " + cause);
            this.modCntr = 0;
            this.mediaTypeDetails = null;
            this.containerId = null;
            this.content = null; // tell the addItemsDeferred too that the editing is stopped.

            if (this.addItemsDeferred) {
                var addDef = this.addItemsDeferred;
                this.addItemsDeferred = null;
                addDef.resolve(cause);
            } // resolve even if the UI did end the edit mode by itself, maybe some other parts of the app are
            // listening to the edit mode deferred, they need to know too.
            // RESET the attribute before resolving. Avoid loops. (end - informs UI - UI stops edit mode - ..)


            var editDef = this.editModeDeferred;
            this.editModeDeferred = null;
            editDef.resolve(cause);
            editDef = null;
        }

        /**
         * Puts a command and its deferred into our commands queue. Using a queue is mandatory since there can
         * be multiple commands after another that appear to be equal (delete:1, delete:1, delete:1, move:1,4)
         * and the order in which they are executed is important. A set would treat equal cmds as one.
         * @param cmd   the command that was sent
         * @param def   the commands deferred
         * @private
         */
        _enqueueCmd(cmd, def) {
            this.commands.push({
                cmd: cmd,
                def: def
            });
        }

        /**
         * Removes the first cmd/def obj form the queue, verifies if the command matches and returns its
         * deferred. if the command does not match, it will return null - which means something went wrong.
         * @param cmd   the command we need the deferred for.
         * @private
         */
        _dequeueCmd(cmd) {
            var result = null;

            if (this.commands.length > 0 && this.commands[0].cmd === cmd) {
                result = this.commands[0].def;
                this.commands.splice(0, 1);
            } // if the result could not be dequeued, it may be that some command has not yet responded, but commands
            // sent after that did respond.


            if (!result) {
                var i;

                for (i = 1; i < this.commands.length; i++) {
                    if (this.commands[i].cmd === cmd) {
                        console.warn("The command '" + this.commands[0].cmd + "' did not respond yet, but another cmd did.");
                        result = this.commands[i].def;
                        this.commands.splice(i, 1);
                    }
                }
            }

            return result;
        }

        /**
         * Checks the queue for the cmd provided. If contained it'll return true. Does not mind the position
         * of the command inside the queue.
         * @param cmd           the cmd we are looking for.
         * @returns {boolean}
         * @private
         */
        _isInQueue(cmd) {
            return this.commands.some(function (obj) {
                return obj.cmd === cmd;
            });
        }

        /**
         * Will copy the mediaTypeDetails provided and ensure that the services cmd & uid are store directly in
         * this.mediaTypeDetails - instead of this.mediaTypeDetails.service
         * @param mediaTypeDetails  the source object.
         * @private
         */
        _setMediaTypeDetails(mediaTypeDetails) {
            var newMediaTypeDetails = cloneObject(mediaTypeDetails);
            newMediaTypeDetails[MediaEnum.Attr.SERVICE.CMD] = newMediaTypeDetails[MediaEnum.Attr.SERVICE._][MediaEnum.Attr.SERVICE.CMD];
            newMediaTypeDetails[MediaEnum.Attr.SERVICE.UID] = newMediaTypeDetails[MediaEnum.Attr.SERVICE._][MediaEnum.Attr.SERVICE.UID];
            this.mediaTypeDetails = newMediaTypeDetails;
        }

        // ---------------------------------------------------------------------------------
        // -----------------------   Root Container Handling   -----------------------------

        /**
         * like syncdata, only for the root container.
         * @param newRootContent
         * @param [reason]  if a reason is provided the lists are being reloaded.
         * @private
         */
        _syncRoot(newRootContent, reason) {
            var cntntType = this.getContentType(),
                rootId = this.getRootContainerId();

            if (newRootContent) {
                MediaServerComp.updateContent(cntntType, rootId, this.mediaTypeDetails, newRootContent, reason);
            } else {
                MediaServerComp.invalidateContentCachesOf(cntntType, rootId, this.mediaTypeDetails, reason);
            }
        }

        /**
         * finds an item in an array that has the provided itemId, returns its position in the array
         * @param items     where to look for the item
         * @param itemId    the id of the item to look for
         * @returns {number}    the position/index of the item.
         * @private
         */
        _indexOfItem(items, itemId) {
            var idx = -1;

            for (var i = 0; i < items.length; i++) {
                if (items[i][MediaEnum.Event.ID] === itemId) {
                    idx = i;
                    break;
                }
            }

            return idx;
        }

        /**
         * Acquires the root content and removes the item from it. Returns the updated contentOld
         * @param containerId
         * @returns {*}
         * @private
         */
        _removeFromRootContent(containerId) {
            var rootContent = this._getRootContent(),
                updatedContent = null;

            if (!rootContent) {
                return;
            }

            var idx = this._indexOfItem(rootContent.items, containerId);

            if (idx >= 0) {
                updatedContent = cloneObject(rootContent);
                updatedContent.items.splice(idx, 1);
                updatedContent.totalitems--;
            }

            return updatedContent;
        }

        /**
         * Gets the root contentOld, looks up the modified item and updates it's name.
         * @param itemId
         * @param newName
         * @returns {*} the updated contentOld.
         * @private
         */
        _renameItemInRootContent(itemId, newName) {
            var rootContent = this._getRootContent(),
                updatedContent = null,
                idx;

            if (!rootContent) {
                return;
            }

            idx = this._indexOfItem(rootContent.items, this.containerId);

            if (idx >= 0) {
                updatedContent = cloneObject(rootContent);
                updatedContent.items[idx][MediaEnum.Event.NAME] = newName;
            }

            return updatedContent;
        }

        /**
         * Gets the root contentOld, pushes the new item onto it and returns an updated copy.
         * @param newItem
         * @returns {*} the updated contentOld.
         * @private
         */
        _addToRootContent(newItem) {
            var rootContent = this._getRootContent();

            if (!rootContent) {
                return;
            }

            rootContent = cloneObject(rootContent);
            rootContent.items.push(newItem);
            rootContent.totalitems++;
            return rootContent;
        }

        /**
         * Acquires the root content via the MediaLoader. e.g. the list of all containers. Null if not fully
         * loaded!
         * @returns {*} the root elements contentOld
         * @private
         */
        _getRootContent() {
            var cntnt = MediaServerComp.getCurrentContent(this.getContentType(), this.getRootContainerId(), this.mediaTypeDetails);

            if (cntnt.totalitems !== cntnt.items.length) {
                cntnt = null; // not fully loaded yet!
            }

            return cntnt;
        }

    }

    /**
     * This component is designed to handle modifications of a container that was loaded via a mediaLoaderExtension and
     * supports a modification API such as playlists do. Using this extenion the user may
     *  -   create a new container
     *  -   rename an existing container
     *  -   add new items to a container
     *  -   remove items from a container
     *  -   move items within a container
     *
     *  This extension will take care of keeping a valid dataset representing the container being edited and it will
     *  continuously update the accompanying loaderExtension with modified data that has already been confirmed by
     *  the MediaServer.
     */
    Components.MediaServer.extensions.MediaEditBase = MediaEditBase;
    return Components;
}(window.Components || {});
