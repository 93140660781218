'use strict';
/**
 * An AudioZoneHandler takes care of handling all data that affects an audioZone, regardless
 * wether the source of the data is the MediaServer or the Miniserver.
 */

window.Components = function (Components) {
    class AudioZoneHandler {
        constructor(zone, callback) {
            Object.assign(this, EventHandler.Mixin);
            this.zone = zone;
            var normalizedName = zone.name;
            var length = 8;

            if (normalizedName.length > length) {
                normalizedName = normalizedName.substring(0, length);
            } else {
                for (var i = normalizedName.length; i < length; i++) {
                    normalizedName += " ";
                }
            }

            this.name = "AudioZoneHandler (" + normalizedName + ")";
            this.callback = callback;
        }

        destroy() {// nothing to do
        }

        // ---------------------------------------------------------------------------------
        //                MediaServer State Change
        // ---------------------------------------------------------------------------------
        cacheQueue(queueData) {
            Debug.Media.AudioZoneHandler && debugLog(this.name, "cacheQueue: " + JSON.stringify(queueData));
        }

        cacheAudioState(stateData) {
            Debug.Media.AudioZoneHandler && debugLog(this.name, "cacheAudioState: " + JSON.stringify(stateData));

            if (!this.audioData) {
                this.audioData = {};
            }

            updateFields(this.audioData, stateData);
        }

        // ---------------------------------------------------------------------------------
        //                Accessing States
        // ---------------------------------------------------------------------------------
        getAudioState() {
            Debug.Media.AudioZoneHandler && debugLog(this.name, "getAudioState");
            return this.audioData;
        }

    }

    Components.MediaServer.extensions.AudioZone.AudioZoneHandler = AudioZoneHandler; // EventHandler Mixin

    return Components;
}(window.Components || {});
