'use strict';

window.Components = function (Components) {
    class InputLoader extends Components.MediaServer.extensions.MediaLoaderBase {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        /**
         * Requests the favorites for the active zone.
         * @result Returns a promise that dispatches the results
         */
        requestContent() {
            var result = {};
            this.deferred = Q.defer();

            if (this.inputs) {
                setTimeout(function () {
                    if (this.deferred) {
                        this._dispatchResult(this._getResultToDispatch(this.inputs));
                    }
                }.bind(this), 1);
            } else {
                this.channel.extensions.inputExt.getInputs().promise.done(function (inputs) {
                    this.inputs = inputs;

                    this._dispatchResult(this._getResultToDispatch(this.inputs));
                }.bind(this));
            }

            result.promise = this.deferred.promise;
            return result;
        }

        /**
         * Called whenever the cache needs to be invalidated (e.g. when the favorites where edited). Will inform
         * all registered listeners to reload their data.
         * @param id            the id of the element who's cache is to be invalidated.
         * @param reason  why where the contens invalidated? used for the reloadEvent
         * @param remove  should the view reload the content or should it remove itself from the screen.
         */
        invalidateContentCachesOf(id, reason, remove) {
            Debug.Media.MiniserverLoader && console.log(this.name, "invalidateContentCachesOf: " + id + " - " + reason);
            this.inputs = null;

            this._dispatchReloadEvent(reason, remove);
        }

        /**
         * Stump - not necessary in this implementation
         */
        prefetchContent() {
        }

        /**
         * Stump - not necessary in this implementation
         */
        stopRequestFor() {
        }

        /**
         * Stump - not necessary in this implementation
         */
        rejectAndDeleteAllRequests() {
        }

        /**
         * Stump - not necessary in this implementation
         */
        getAcquireCommand() {
        }

        getAcquireCommandArguments(request) {
            return null;
        }

        _doesHandleEvent(event) {
            return event.hasOwnProperty(MediaEnum.EventIdentifier.INPUT_CHANGED);
        }

        _processEvent(event) {
            this.invalidateContentCachesOf(0, MediaEnum.ReloadCause.CONTENT_UPDATED);
        }

        _getResultToDispatch(inputs) {
            return {
                items: inputs,
                totalitems: inputs.length,
                isFinished: true
            };
        }

        _dispatchResult(result) {
            result = cloneObject(result);
            Debug.Media.MiniserverLoader && console.log(this.name, "_dispatchResult: " + JSON.stringify(result));
            console.info(this.name, "Dispatching new ZoneFavs");
            this.deferred.resolve(this.prepareResult(result, true));
        }

    }

    Components.MediaServer.extensions.InputLoader = InputLoader;
    return Components;
}(window.Components || {});
