window.Components = function (Components) {
    var socketOpenCalls = 0;
    var socketCloseCalls = 0;

    class MediaSocket extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
        }

        // ------------------------------------------------------------------------
        // Public Methods
        // ------------------------------------------------------------------------
        connect(mediaServer) {
            Debug.Media.Socket && console.log("MediaSocketExt connect: " + mediaServer.name + " @ " + mediaServer.host);
            this.shouldBeConnected = true;
            this.mediaServer = mediaServer;

            this._connectSocket();
        }

        disconnect() {
            Debug.Media.Socket && console.log("MediaSocketExt disconnect.");
            this.shouldBeConnected = false;

            if (this.reconnectTimer) {
                Debug.Media.Socket && console.log("     a reconnect timer is active, stop it");
                clearTimeout(this.reconnectTimer);
                this.reconnectTimer = null;
            }

            this._closeSocket();

            this.channel.emit(this.component.ECEvent.ConnClosed);
        }

        sendCommand(cmdObj) {
            var command = cmdObj.cmd;
            Debug.Media.Comm && debugLog(this.name + ": Sending command '" + JSON.stringify(command) + "'");

            if (this.isOpen) {
                // emit a messageSent event, this way e.g. the MessageParser is able to prepare for incoming responses.
                this.channel.emit(this.component.ECEvent.MessageSent, cmdObj);
                this.socket.send(command);
            } else {
                console.error('Socket not open yet, command "' + command + '" not sent.');
            }
        }

        // ------------------------------------------------------------------------
        // Socket Handling
        // ------------------------------------------------------------------------
        _closeSocket() {
            if (!this.socket) {
                return;
            }

            socketCloseCalls++;
            Debug.Media.Socket && console.log(this.name + ": _closeSocket. (O:" + socketOpenCalls + "/C:" + socketCloseCalls + ")");
            Debug.Media.Comm && console.log(this.name + ": _closeSocket. (O:" + socketOpenCalls + "/C:" + socketCloseCalls + ")");

            if (this.reconnectTimer) {
                clearTimeout(this.reconnectTimer);
                this.reconnectTimer = null;
            }

            this.socket.onopen = null;
            this.socket.onclose = null;
            this.socket.onerror = null;
            this.socket.onmessage = null;
            this.socket.close();
            this.socket = null;
        }

        _connectSocket() {
            if (this.socket) {
                Debug.Media.Socket && console.error("Attempted to open a connection, whilst another socket is still around!");

                this._closeSocket();
            }

            try {
                var protocol = 'remotecontrol';
                socketOpenCalls++;
                Debug.Media.Socket && console.log(this.name + ": _connectSocket. (O:" + socketOpenCalls + "/C:" + socketCloseCalls + ") - " + this.mediaServer.host);
                Debug.Media.Comm && console.log(this.name + ": _connectSocket. (O:" + socketOpenCalls + "/C:" + socketCloseCalls + ") - " + this.mediaServer.host);
                this.socket = new WebSocket(this._getWebSocketHost(), protocol);
                this.socket.onopen = this._onOpen.bind(this);
                this.socket.onclose = this._onClose.bind(this);
                this.socket.onerror = this._onError.bind(this);
                this.socket.onmessage = this._onMessage.bind(this);
            } catch (exc) {
                console.error("Error while opening the socket!", exc);
                this.channel.emit(this.component.ECEvent.ConnClosed);
                this.channel.emit(this.component.ECEvent.NotReachable);
            }
        }

        _getWebSocketHost() {
            if (Feature.MUSIC_SERVER_PROXY && (PlatformComponent.isWebInterface() || PlatformComponent.isDeveloperInterface()) && location.protocol === "https:") {
                return "wss://" + ActiveMSComponent.getMiniserverConnectionUrl().replace("http://", "").replace("https://", "") + "/proxy/" + this.mediaServer.uuidAction + "/";
            } else {
                return "ws://" + this.mediaServer.host;
            }
        }

        // ------------------------------------------------------------------------
        // Socket Callbacks
        // ------------------------------------------------------------------------
        _onOpen() {
            Debug.Media.Socket && console.log("MediaSocketExt onOpen");
        }

        _onClose(arg) {
            Debug.Media.Socket && console.log("MediaSocketExt onClose");
            this.isOpen = false;
            this.channel.emit(this.component.ECEvent.ConnClosed);

            this._closeSocket();

            if (!this.shouldBeConnected) {
                return;
            }

            if (arg.wasClean) {
                // closed regularily - open again
                this._connectSocket();
            } else {
                Debug.Media.Socket && console.error("Socket was not clean when closed! " + arg.reason + ". Trying again later"); // sth is wrong with the server, try again in a few secs.

                this.reconnectTimer = setTimeout(function () {
                    if (!this.shouldBeConnected) {
                        Debug.Media.Comm && console.error("Reconnect: Timer fired, but there shouldn't be a connection now. so don't reconnect");
                    } else {
                        Debug.Media.Comm && console.error("Reconnect: Try again, socket wasn't clean when closed!");

                        this._connectSocket();
                    }

                    this.reconnectTimer = null;
                }.bind(this), 5000);
            }
        }

        _onError(err) {
            this.isOpen = false;
            Debug.Media.Socket && console.log("MediaSocketExt onError " + err.message);

            this._closeSocket(); // after closeSocket the "onClose" event won't arrive, so respond now


            this.reconnectTimer = setTimeout(function () {
                Debug.Media.Socket && console.error("OnError: Socket closed, try again!");

                this._connectSocket();
            }.bind(this), 2000);
        }

        _onMessage(msg) {
            Debug.Media.CommDetailed && debugLog(this.name + ": Received: " + JSON.stringify(msg.data));

            if (!Debug.Media.CommDetailed && Debug.Media.Comm) {
                var text = JSON.stringify(msg.data);
                var short = text.substring(0, 40) + " ... " + text.substring(text.length - 60, text.length - 1);
                console.info(this.name + ": Received: " + short);
            }

            try {
                if (msg.type === "message") {
                    // Wait for the Websocket greeting before we can send commands! (this.isOpen)
                    if (msg.data.indexOf(MediaEnum.Comm.ROOT_MSG) === 0) {
                        this.isOpen = true;
                        this.channel.emit(this.component.ECEvent.ConnEstablished);
                    }

                    this.channel.emit(this.component.ECEvent.MessageReceived, msg.data);
                }
            } catch (exc) {
                console.error(exc.stack);
                console.error("Message could not be parsed! ", msg.data);
            }
        }

        // ------------------------------------------------------------------------
        // Private Methods
        // ------------------------------------------------------------------------
        _dispatchEvent(eventIdentifier, payload) {
            // for each entry in the eventData-Array, dispatch an event
            for (var i = 0; i < payload.length; i++) {
                var eventData = payload[i];
                var obj = {};
                obj[eventIdentifier] = eventData;
                this.channel.emit(this.component.ECEvent.EventReceived, obj);
            }
        }

    }

    Components.MediaServer.extensions.MediaSocket = MediaSocket;
    return Components;
}(window.Components || {});
